import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

enum TypeByUser {
  auditor = 1,
  employee,
  individual,
  contractor = 5,
}

/**
 * Детали заявки на доступ v2
 */
@Component({
  selector: 'app-resource-details-second',
  templateUrl: './resource-details-second.component.html',
  styleUrls: [
    './resource-details-second.component.scss',
    '../request-details.component.scss',
  ],
})
export class ResourceDetailsSecondComponent implements OnChanges {
  @Input() request;
  readonly typeByUser = TypeByUser;

  tableLoaded = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.request && this.request.userResources) {
      this.simplifyResources();
    }
  }

  /**
   * Приводит данные к удобному для отображения в виде таблицы формату
   */
  simplifyResources(): void {
    this.request.userResources.forEach(userResource => {
      if (!userResource.items) {
        userResource.items = [];
      }

      userResource.resources.forEach(resource => {
        if (resource.accessTypes) {
          resource.accessTypes.forEach(accessType => {
            accessType.accessRestrictionAnalytics.forEach((analytic, i) => {
              const item = {
                analytic,
                approveInfo: analytic.approveInfo,
                accessType: !i ? accessType : null,
                resource: !i ? resource : null,
              };

              userResource.items.push(item);
            });
          });
        } else if (resource.approveInfo) {
          const item = {
            resource,
            approveInfo: resource.approveInfo,
          };

          userResource.items.push(item);
        }
      });
    });

    this.tableLoaded = true;
  }
}
