import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {ApiResponseWithEmptyMeta, RequestApproverGroupDto} from '../interfaces';
import {SERVICE_DESK_API_URL} from '../tokens';

@Injectable({providedIn: 'root'})
export class RequestApproverService {
  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /** Получение согласующих для конкретной заявки по id */
  getByRequestId(requestId: number) {
    return this.http.get<ApiResponseWithEmptyMeta<RequestApproverGroupDto[]>>(
      `${this.apiUrl}/v1/RequestApprover/${requestId}`,
    );
  }
}
