import {NgModule} from '@angular/core';

import {
  ServicedeskRequestCardModule,
  ServicedeskRequestListFilterModule,
  ServicedeskRequestListModule,
  ServicedeskRequestLoaderModule,
  ServicedeskRequestViewModule,
} from './components';

@NgModule({
  imports: [
    ServicedeskRequestCardModule,
    ServicedeskRequestListModule,
    ServicedeskRequestListFilterModule,
    ServicedeskRequestViewModule,
    ServicedeskRequestLoaderModule,
  ],
  exports: [
    ServicedeskRequestCardModule,
    ServicedeskRequestListModule,
    ServicedeskRequestListFilterModule,
    ServicedeskRequestViewModule,
    ServicedeskRequestLoaderModule,
  ],
})
export class ServicedeskKitModule {}
