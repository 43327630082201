<div
  *ngIf="request.manager"
  class="memo__content"
>
  <div class="title">Руководитель</div>
  <div class="grid grid--quarters grid--gutters">
    <div class="grid__item">
      <app-employee-card [employee]="request.manager"></app-employee-card>
    </div>
  </div>
</div>
<ng-container *ngIf="request.userResources.length; else noresourses">
  <div
    *ngFor="let userResource of request.userResources"
    class="memo__content"
  >
    <div class="separator"></div>
    <div
      *ngIf="userResource.employee"
      class="memo__content"
    >
      <div class="title">Сотруднику</div>
      <div class="grid grid--quarters grid--gutters">
        <div class="grid__item">
          <app-employee-card
            [employee]="userResource.employee"
          ></app-employee-card>
        </div>
      </div>
    </div>
    <div
      *ngIf="userResource.auditor"
      class="memo__content"
    >
      <div class="title">
        {{
          request.typeByUserId === typeByUser.auditor
            ? 'Аудитору'
            : 'Подрядчику'
        }}
      </div>
      <div class="grid grid--quarters grid--gutters">
        <div class="grid__item">
          <app-pik-user
            [user]="{fullName: userResource.auditor}"
          ></app-pik-user>
        </div>
      </div>

      <div
        *ngIf="request.contractorDateOfBirth"
        class="auditor-info"
      >
        <div>
          <div class="sub-title">Дата рождения</div>
          <div>
            {{ request.contractorDateOfBirth | date: 'dd.MM.yyyy' }}
          </div>
        </div>

        <div *ngIf="request.contractorEmail">
          <div class="sub-title">Email</div>
          <div>
            {{ request.contractorEmail }}
          </div>
        </div>

        <div *ngIf="request.contractorCompanyName">
          <div class="sub-title">Наименование организации</div>
          <div>
            {{ request.contractorCompanyName }}
          </div>
        </div>

        <div
          *ngIf="
            request.typeByUserId != typeByUser.auditor &&
            (request.contractorNda !== null ||
              request.contractorNda !== undefined)
          "
        >
          <div class="sub-title">NDA подписан</div>
          <div>
            {{ request.contractorNda ? 'Да' : 'Нет' }}
          </div>
        </div>

        <div *ngIf="request.contractorStartDate">
          <div class="sub-title">Дата выхода</div>
          <div>
            {{ request.contractorStartDate | date: 'dd.MM.yyyy' }}
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="userResource.individual?.fullName"
      class="memo__content"
    >
      <div class="title">Физическому лицу</div>
      <div class="grid grid--quarters grid--gutters">
        <div class="grid__item">
          <app-pik-user
            guidSource="guid1C"
            [user]="userResource.individual"
          ></app-pik-user>
        </div>
      </div>
    </div>
    <div class="memo__content">
      <div
        *ngIf="request.commentForAuditors"
        class="memo__comment memo__comment--auditor"
      >
        <h2 class="memo__comment-title">Комментарий</h2>
        <p class="memo__comment-body">{{ request.commentForAuditors }}</p>
      </div>

      <div
        *ngIf="tableLoaded"
        class="memo__table memo-table"
      >
        <div class="memo-table__row memo-table__row--head">
          <div>Ресурс/Шаблон роли доступа</div>
          <div>Объект доступа</div>
          <div>Значение доступа</div>
          <div>Статус/Согласующие</div>
        </div>
        <div
          *ngFor="let item of userResource.items"
          class="memo-table__row"
        >
          <div [class.item--hiden]="!item.resource">
            <ng-container *ngIf="item.resource">
              <div class="item item--bold">{{ item.resource.name }}</div>
              <div class="item item--bold">
                {{ item.resource.accessGroupProfileName }}
              </div>
              <div
                *ngIf="item.resource.comment"
                class="item item--gray"
              >
                {{ item.resource.comment }}
              </div>
            </ng-container>
          </div>
          <div [class.item--hiden]="!item.accessType">
            <div
              *ngIf="item.accessType"
              class="item item--bold"
            >
              {{ item.accessType?.name }}
            </div>
          </div>
          <div [class.item--hiden]="!item.analytic">
            <div
              *ngIf="item.analytic"
              class="item item--bold"
            >
              {{ item.analytic?.name }}
            </div>
          </div>
          <div [class.item--hiden]="!item.approveInfo">
            <ng-container *ngIf="item.approveInfo">
              <div class="memo__status">
                <div class="memo__status-name">
                  {{ item.approveInfo.status.name }}
                </div>
                <div
                  class="memo__status-indicator"
                  [ngClass]="
                    'status-indicator status-indicator--' +
                    item.approveInfo.status.code
                  "
                ></div>
              </div>
              <ng-container *ngIf="!item.approveInfo.executor">
                <ng-container
                  *ngFor="let approver of item.approveInfo.approvers"
                >
                  <div class="item item--approver">
                    {{ approver.surName }} {{ approver.name }}
                  </div>
                </ng-container>
              </ng-container>

              <div
                *ngIf="item.approveInfo.executor"
                class="item item--approver"
              >
                {{ item.approveInfo.executor.surName }}
                {{ item.approveInfo.executor.name }}
              </div>

              <div
                *ngIf="item.approveInfo.executorComment"
                class="item item--comment"
              >
                {{ item.approveInfo.executorComment }}
              </div>

              <div
                *ngIf="
                  !item.approveInfo.executor &&
                  !item.approveInfo.approvers.length
                "
                class="item item--approver"
              >
                Автоматически
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noresourses>
  <div class="memo__content">
    <div class="separator"></div>

    <div class="title">
      {{
        request.typeByUserId === typeByUser.auditor ? 'Аудитору' : 'Подрядчику'
      }}
    </div>
    <div class="memo__content">
      <div
        *ngIf="request.contractorDateOfBirth"
        class="auditor-info"
      >
        <div>
          <div class="sub-title">Дата рождения</div>
          <div>
            {{ request.contractorDateOfBirth | date: 'dd.MM.yyyy' }}
          </div>
        </div>

        <div *ngIf="request.contractorEmail">
          <div class="sub-title">Email</div>
          <div>
            {{ request.contractorEmail }}
          </div>
        </div>

        <div *ngIf="request.contractorCompanyName">
          <div class="sub-title">Наименование организации</div>
          <div>
            {{ request.contractorCompanyName }}
          </div>
        </div>

        <div
          *ngIf="
            request.typeByUserId != typeByUser.auditor &&
            (request.contractorNda !== null ||
              request.contractorNda !== undefined)
          "
        >
          <div class="sub-title">NDA подписан</div>
          <div>
            {{ request.contractorNda ? 'Да' : 'Нет' }}
          </div>
        </div>

        <div *ngIf="request.contractorStartDate">
          <div class="sub-title">Дата выхода</div>
          <div>
            {{ request.contractorStartDate | date: 'dd.MM.yyyy' }}
          </div>
        </div>
      </div>
      <div
        *ngIf="request.commentForAuditors"
        class="memo__comment memo__comment--auditor"
      >
        <h2 class="memo__comment-title">Комментарий</h2>
        <p class="memo__comment-body">{{ request.commentForAuditors }}</p>
      </div>
    </div>
  </div>
</ng-template>
