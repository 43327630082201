import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TuiDestroyService} from '@pik-taiga-ui/cdk';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  finalize,
  forkJoin,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {map, shareReplay, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {
  AttributeDto,
  CURRENT_EMPLOYEE,
  EmployeeDto,
  IndividualInfoDto,
  IndividualInfoService,
} from 'src/app/home-api';

import {EXTRA_ATTRIBUTES_LIST, EXTRA_ATTRIBUTES_VALUES_LIST} from './tokens';

interface ExtraFieldsFromValue {
  mobile: string;
  telegram: string;
  timezone: string;
  city: string;
  mobileIsHidden: boolean;
  telegramIsHidden: boolean;
  timezoneIsHidden: boolean;
  cityIsHidden: boolean;
}

/**
 * @todo обработка ошибки сохранения
 * @todo придумать что-то на бэке, чтобы исключить необходимость загружать все attributes
 */
@Component({
  selector: 'employee-extra',
  templateUrl: './extra.template.html',
  styleUrls: ['./extra.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class EmployeeExtraComponent {
  private readonly updatedAttributes$ = new Subject<readonly IndividualInfoDto[]>();
  private attributesValues: {[attributeCode: string]: IndividualInfoDto} = {};
  private readonly attributesIds$ = this.attributes$.pipe(
    map(attributes => attributes.reduce((acc, {id, code}) => ({...acc, [code]: id}), {})),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly timezones = [
    'UTC+2 (МСК-1)',
    'UTC+3 (МСК)',
    'UTC+4 (МСК+1)',
    'UTC+5 (МСК+2)',
    'UTC+6 (МСК+3)',
    'UTC+7 (МСК+4)',
    'UTC+8 (МСК+5)',
    'UTC+9 (МСК+6)',
    'UTC+10 (МСК+7)',
    'UTC+11 (МСК+8)',
    'UTC+12 (МСК+9)',
  ];

  readonly editingForm$ = new BehaviorSubject<FormGroup | null>(null);
  readonly isSaving$ = new BehaviorSubject(false);

  readonly attributesValues$ = merge(
    this.updatedAttributes$,
    this.allAttributesValues$,
  ).pipe(
    filter(values => values !== null),
    map(values => {
      const keyValueObj: {[attributeCode: string]: string} = {};

      this.attributesValues = {};

      values.forEach(value => {
        keyValueObj[value.attributeCode] = value.value;
        this.attributesValues[value.attributeCode] = value;
      });

      return keyValueObj;
    }),
    startWith(null),
  );

  constructor(
    @Inject(CURRENT_EMPLOYEE) private readonly currentEmployee$: Observable<EmployeeDto>,
    @Inject(IndividualInfoService)
    private readonly individualInfoService: IndividualInfoService,
    @Inject(EXTRA_ATTRIBUTES_LIST)
    private readonly attributes$: Observable<ReadonlyArray<AttributeDto>>,
    @Inject(EXTRA_ATTRIBUTES_VALUES_LIST)
    private readonly allAttributesValues$: Observable<ReadonlyArray<IndividualInfoDto>>,
    @Inject(TuiDestroyService)
    private readonly destroy$: TuiDestroyService,
  ) {}

  startEdit() {
    const {mobile, telegram, timezone, city} = this.attributesValues;

    const form = new FormGroup({
      mobile: new FormControl(mobile?.value ? `+7${mobile?.value}` : null, [
        Validators.minLength(12),
      ]),
      telegram: new FormControl(telegram?.value, [
        Validators.minLength(5),
        Validators.pattern('[a-zA-Z0-9_]*'),
      ]),
      timezone: new FormControl(timezone?.value),
      city: new FormControl(city?.value),

      mobileIsHidden: new FormControl(!!mobile?.isHidden),
      telegramIsHidden: new FormControl(!!telegram?.isHidden),
      timezoneIsHidden: new FormControl(!!timezone?.isHidden),
      cityIsHidden: new FormControl(!!city?.isHidden),
    });

    this.editingForm$.next(form);
  }

  cancelEdit() {
    this.editingForm$.next(null);
  }

  toogleVisibility(filedName: string) {
    const form = this.editingForm$.value;

    form.controls[filedName].setValue(!form.controls[filedName].value);
  }

  save() {
    const form: ExtraFieldsFromValue = this.editingForm$.value.value;

    const requests: Observable<IndividualInfoDto>[] = [
      'telegram',
      'timezone',
      'city',
    ].reduce((observables, fieldName) => {
      if (form[fieldName] || (!form[fieldName] && this.attributesValues[fieldName])) {
        return [
          ...observables,
          this.createRequestFor(
            fieldName,
            form[fieldName] || null,
            form[`${fieldName}IsHidden`],
          ),
        ];
      }

      return observables;
    }, []);

    if (form.mobile || (!form.mobile && this.attributesValues.mobile)) {
      requests.push(
        this.createRequestFor(
          'mobile',
          form.mobile.replace('+7', '') || null,
          form.mobileIsHidden,
        ),
      );
    }

    this.isSaving$.next(true);

    forkJoin(requests)
      .pipe(
        finalize(() => this.isSaving$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe(updatedFields => {
        updatedFields.forEach(field => {
          if (field.value === null) {
            delete this.attributesValues[field.attributeCode];
          } else if (this.attributesValues[field.attributeCode]) {
            this.attributesValues[field.attributeCode].value = field.value;
            this.attributesValues[field.attributeCode].isHidden = field.isHidden;
          } else {
            this.attributesValues[field.attributeCode] = field;
          }
        });

        this.updatedAttributes$.next(Object.values(this.attributesValues));
        this.editingForm$.next(null);
      });
  }

  private createRequestFor(
    fieldName: string,
    fieldValue: string | boolean,
    fieldHidden: boolean,
  ) {
    /** Значение ещё не было создано */
    if (!this.attributesValues[fieldName]) {
      return combineLatest([
        this.attributesIds$,
        this.currentEmployee$.pipe(map(({individualId}) => individualId)),
      ]).pipe(
        switchMap(([ids, individualId]) =>
          this.individualInfoService.create({
            individualId,
            attributeId: ids[fieldName],
            value: fieldValue.toString(),
            isHidden: fieldHidden,
          }),
        ),
      );
    }

    const {value, isHidden, id} = this.attributesValues[fieldName];

    /** Значение ещё не изменилось */
    if (value === fieldValue && fieldHidden === isHidden) {
      return of(this.attributesValues[fieldName]);
    }

    /** Значение удалено */
    if (fieldValue === null) {
      return this.individualInfoService
        .delete(id)
        .pipe(map(_ => ({...this.attributesValues[fieldName], value: null})));
    }

    return this.currentEmployee$.pipe(
      map(({individualId}) => individualId),
      switchMap(individualId => {
        return this.individualInfoService.updateById(id, {
          individualId,
          value: fieldValue.toString(),
          isHidden: fieldHidden,
        });
      }),
    );
  }
}
