import {RequestApproverGroupDto} from '@app/libs/servicedesk-api';

import {Approver, ApproverGroup} from '../interfaces';
import {STATUS_COLOR} from './status-color';

export function approverGroupAdapter(rawGroup: RequestApproverGroupDto): ApproverGroup {
  const approvers: Approver[] = [];
  let statusCode: string, statusName: string;

  if (rawGroup.approvers && rawGroup.approvers.length > 0) {
    rawGroup.approvers.forEach(approver => {
      approvers.push({
        ...approver,
        statusColorCode: STATUS_COLOR[approver.statusCode] || 'default',
      });

      if (!!statusCode && approver.statusCode !== statusCode) {
        console.warn(
          new Error(
            `В одном или нескольких согласущих не совпадают статусы (${statusCode} !== ${approver.statusCode})`,
          ),
        );
      }

      if (!statusCode || statusCode === 'New') {
        statusCode = approver.statusCode;
        statusName = approver.statusName;
      }
    });
  }

  return {
    code: rawGroup.code,
    name: rawGroup.name,
    description: rawGroup.description,

    statusCode,
    statusName,
    statusColorCode: STATUS_COLOR[statusCode] || 'default',

    approvers,
  };
}
