import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {IndividualInfoDto} from '../interfaces';
import {HOME_API_URL} from '../tokens';

/**
 * @todo Дописать все доступные методы и интерфейсы
 */
@Injectable({providedIn: 'root'})
export class IndividualInfoService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Получить значения всех дополнительных полей индивидуала по его id
   *
   * @todo Не отправлять пустой объект, при отсутсвии параметров
   */
  getByIndividualId(individualId: number, showHidden?: boolean) {
    return this.http.get<readonly IndividualInfoDto[]>(
      `${this.apiUrl}/v1/IndividualInfo/${individualId}`,
      {params: showHidden === undefined ? {} : {showHidden}},
    );
  }

  updateById(
    individualInfoId: number,
    body: {
      /** Если не указать, то бэк ответит 403-ей */
      individualId: number;
      /** Если не указать ничего не произойдет */
      value?: string;
      /** Если не указать, то бэк затрёт текущее значение */
      isHidden?: boolean;
    },
  ) {
    return this.http.put<IndividualInfoDto>(
      `${this.apiUrl}/v1/IndividualInfo/${individualInfoId}`,
      body,
    );
  }

  create(body: {
    /** Если не указать, то бэк ответит 403-ей */
    individualId: number;
    attributeId: number;
    value: string;
    /** По-умолчанию false */
    isHidden?: boolean;
  }) {
    return this.http.post<IndividualInfoDto>(`${this.apiUrl}/v1/IndividualInfo/`, body);
  }

  delete(individualInfoId: number) {
    return this.http.delete<boolean>(
      `${this.apiUrl}/v1/IndividualInfo/${individualInfoId}`,
    );
  }
}
