import {InjectionToken} from '@angular/core';
import {Observable, of} from 'rxjs';

import {DynamicRequest} from '../dynamic-request/interfaces/dynamic-request.interface';

export const MANAGER_VACATION_REQUESTS = new InjectionToken<Observable<DynamicRequest[]>>(
  'Стрим с массивом заявок на планирование отпуска для согласования руководителем',
  {
    factory: () => of(null),
  },
);
