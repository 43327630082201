import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  getScrollParent,
  isNativeFocused,
  setNativeFocused,
  tuiDefaultProp,
} from '@pik-taiga-ui/cdk';
import {isEditingKey, TuiValueContentContext} from '@pik-taiga-ui/core';
import {asyncScheduler, fromEvent, interval, of} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  throttleTime,
} from 'rxjs/operators';

const SCROLL_OFFSET = 25;
@Component({
  selector: 'custom-list',
  templateUrl: './custom-list.template.html',
  styleUrls: ['./custom-list.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomListComponent<T> {
  @HostBinding('class.custom-list') class = true;
  @Input()
  @tuiDefaultProp()
  items: ReadonlyArray<T & {name: string}> | null = [];

  @Input()
  loading: boolean = false;

  @Input()
  hasMore: boolean = false;

  @Input()
  type: 'checkboxes' | 'list' = 'list';

  @Input()
  itemContent: TemplateRef<any> | null = null;

  @Output()
  searchChange: EventEmitter<T> = new EventEmitter();

  @Output()
  getNext: EventEmitter<void> = new EventEmitter();

  @Output()
  selectAll: EventEmitter<T[] | null> = new EventEmitter();

  scrollable = false;
  constructor(private readonly myElement: ElementRef) {}

  getContext(
    $implicit: T,
    {nativeElement}: ElementRef<HTMLElement>,
  ): TuiValueContentContext<T> {
    return {$implicit, active: isNativeFocused(nativeElement)};
  }

  ngAfterViewInit() {
    interval(1000)
      .pipe(
        take(1),
        switchMap(() => {
          const scrollBar = getScrollParent(this.myElement.nativeElement); // getScrollParent - deprecated в v.2.59

          this.scrollable = !!scrollBar;

          if (scrollBar) {
            return fromEvent<Event>(scrollBar, 'scroll').pipe(
              throttleTime(375, asyncScheduler, {trailing: true}),
              distinctUntilChanged(),
              map(
                ({target}) =>
                  (<HTMLElement>target).scrollHeight - (<HTMLElement>target).scrollTop <
                  (<HTMLElement>target).clientHeight + SCROLL_OFFSET,
              ),
              filter(reached => !!reached),
            );
          }

          return of(null);
        }),
        tap(() => {
          this.hasMore && this.getNext.emit();
        }),
      )
      .subscribe();
  }

  onKeyDown(key: string, element: HTMLElement | null): void {
    if (element && isEditingKey(key)) {
      setNativeFocused(element, true, true);
    }
  }

  nameIdentity(index: number, item: T & {name: string}) {
    return item.name;
  }
}
