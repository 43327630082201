import {Inject, Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {CoreModule} from '@core/core.module';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IS_MANAGER} from 'src/app/home-api/tokens/is-manager';

@Injectable({
  providedIn: CoreModule,
})
export class IsManagerGuard implements CanActivate {
  constructor(
    @Inject(IS_MANAGER)
    private readonly isManager$: Observable<boolean>,
    private readonly router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.isManager$.pipe(
      map(isManager => {
        return isManager || this.router.createUrlTree(['/']);
      }),
    );
  }
}
