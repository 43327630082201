import {BreakpointObserver} from '@angular/cdk/layout';
import {DOCUMENT} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {environment} from '@env/environment';
import {
  GoogleAnalyticsService,
  PikAuthService,
  PikEnvironment,
  PikLocatorService,
} from 'pik-header';
import {Observable, combineLatest, filter} from 'rxjs';
import {delay, map, startWith, switchMap} from 'rxjs/operators';

import {DynamicRequest} from './dynamic-request/interfaces/dynamic-request.interface';
import {EmployeeService, ManagerService} from './home-api';
import {IS_MANAGER} from './home-api/tokens/is-manager';
import {MANAGER_VACATION_REQUESTS} from './tokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.template.html',
  styleUrls: ['./app.styles.scss'],
})
export class AppComponent {
  readonly isAuthenticated$ = this.authService.isAuthenticated$;
  readonly linkToHome = this.locatorService.urls.homeUrl;
  readonly isMobile = this.breakpointObserver.isMatched('(max-width: 768px)');

  readonly hasPermission$ = this.managerService.hasPermission$;
  readonly isProd = environment.pikEnvironment === PikEnvironment.prod;

  readonly canApproveVacationRequests$: Observable<boolean> = combineLatest([
    this.managerVacationRequest$,
    this.isManager$,
  ]).pipe(map(([requests, isManager]) => !!((requests && requests.length) || isManager)));

  constructor(
    @Inject(EmployeeService) private readonly employeeService: EmployeeService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(IS_MANAGER) private readonly isManager$: Observable<boolean>,
    @Inject(MANAGER_VACATION_REQUESTS)
    private readonly managerVacationRequest$: Observable<DynamicRequest[]>,
    @Inject(BreakpointObserver)
    private readonly breakpointObserver: BreakpointObserver,
    @Inject(PikAuthService)
    private readonly authService: PikAuthService,
    @Inject(GoogleAnalyticsService)
    private readonly gaService: GoogleAnalyticsService,
    @Inject(PikLocatorService)
    private readonly locatorService: PikLocatorService,
    private readonly managerService: ManagerService,
  ) {
    this.authService.authCallbackIfRequired();
    this.gaService.initialize();

    this.isAuthenticated$
      .pipe(
        filter(isAuthenticated => !!isAuthenticated),
        switchMap(_ => this.employeeService.current$),
      )
      .subscribe(employee => {
        if ((<any>this.document.defaultView).ym) {
          (<any>this.document.defaultView).ym(98028911, 'userParams', {
            UserId: employee.login,
            isOutstaffer: employee.isOutstaffer,
            position: employee.position,
            company: {name: employee.company, id: employee.companyId},
            unit: {name: employee.unitName, id: employee.unitId},
            employmentTotalDays: employee.employmentTotalDays,
          });
        }
      });
  }
}
