<div class="title">
  <div class="title__heading">{{ title }}</div>
</div>
<div class="actions">
  <button
    tuiButton
    size="m"
    appearance="flat"
    (click)="cancel()"
  >
    Нет
  </button>
  <button
    tuiButton
    size="m"
    (click)="ok()"
  >
    Да
  </button>
</div>
