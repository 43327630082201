import {ChangeDetectionStrategy, Component, Inject, Injector} from '@angular/core';
import {TuiDestroyService} from '@pik-taiga-ui/cdk';
import {TuiDialogService} from '@pik-taiga-ui/core';
import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';
import {takeUntil} from 'rxjs/operators';

import {SubstitutionsService} from './substitutions.service';
import {SubstitutionsWidgetComponent} from './widget.component';

@Component({
  selector: 'substitutions-opener',
  templateUrl: './opener.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class SubstitutionsOpenerComponent {
  readonly substitutionsCount$ = this.substitutionsService.allSubstituionsCount$;

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(TuiDestroyService) private readonly destroy$: TuiDestroyService,
    private readonly substitutionsService: SubstitutionsService,
  ) {}

  showWidget() {
    this.dialogService
      .open(new PolymorpheusComponent(SubstitutionsWidgetComponent, this.injector))
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}
