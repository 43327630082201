import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {HOME_URL} from '@core/tokens';
import {humanizeDateRange} from '@utils';
import {BehaviorSubject, map} from 'rxjs';
import {shareReplay, startWith, tap} from 'rxjs/operators';
import {
  EventType,
  FollowedIndividualDto,
  FollowedIndividualEvent,
  SubscriptionService,
} from 'src/app/home-api';

interface ExtraFollowedIndividualEvent extends FollowedIndividualEvent {
  humanizedString: string;
}

interface FollowedIndividual extends FollowedIndividualDto {
  events: readonly ExtraFollowedIndividualEvent[];
}

const EVENT_TYPE_NAMES = new Map<EventType, string | string[]>([
  ['errand', 'В командировке'],
  ['vacation', 'В отпуске'],
  ['vacationv2', 'В отпуске'],
  ['vacationv3', 'В отпуске'],
  ['sickLeave', 'На больничном'],
  ['remoteWork', ['Работает удалённо', 'Будет работать удалённо']],
  ['birthday', 'День рождения'],
  ['daysOff', 'В отгуле'],
  [null, 'Отсутствует'],
]);

/**
 * Компонент отображения сотрудников добавленных текущим пользователем
 * в "избранное".
 *
 * @todo необходимо переработать подписи статусов
 */
@Component({
  selector: 'employee-favorites',
  templateUrl: './favorites.template.html',
  styleUrls: ['./favorites.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeFavoritesComponent {
  readonly homeUrl = this.homeUrlFromToken;
  readonly extraSubsExist$ = new BehaviorSubject(false);
  readonly showAll$ = new BehaviorSubject(false);
  readonly subsCount$ = new BehaviorSubject<number>(null);

  readonly topSubs$ = this.subscriptionService.getAll({limit: 4}).pipe(
    tap(res => {
      this.subsCount$.next(res.meta.totalCount - 1);
      this.extraSubsExist$.next(res.meta.totalCount > 4);
    }),
    map(res => {
      return {
        ...res,
        data: res.data ? res.data.map(this.convertSubscrption) : [],
      };
    }),
    startWith(null),
  );

  readonly extraSubs$ = this.subscriptionService.getAll({offset: 4, limit: 1000}).pipe(
    map(res => {
      return {
        ...res,
        data: res.data ? res.data.map(this.convertSubscrption) : [],
      };
    }),
    startWith(null),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  constructor(
    @Inject(SubscriptionService)
    private readonly subscriptionService: SubscriptionService,
    @Inject(HOME_URL) private readonly homeUrlFromToken: string,
  ) {}

  toogleAll() {
    this.showAll$.next(!this.showAll$.value);
  }

  private convertSubscrption(subscription: FollowedIndividualDto): FollowedIndividual {
    return {
      ...subscription,
      events: subscription.events.map(event => {
        const startDate = new Date(event.startDate);
        let prefix = EVENT_TYPE_NAMES.get(event.type);

        if (startDate && startDate.valueOf() > Date.now()) {
          prefix = Array.isArray(prefix)
            ? prefix[1]
            : `Будет ${prefix[0].toLowerCase()}${prefix.substring(1)}`;
        } else {
          prefix = Array.isArray(prefix) ? prefix[0] : prefix;
        }

        return {
          ...event,
          humanizedString: `${prefix} ${humanizeDateRange(
            event.startDate,
            event.endDate,
          )}`,
        };
      }),
    };
  }
}
