import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TuiDestroyService, TuiIdentityMatcher} from '@pik-taiga-ui/cdk';
import {TuiNotification, TuiNotificationsService} from '@pik-taiga-ui/core';
import {
  catchError,
  combineLatest,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  AttributeDto,
  CURRENT_EMPLOYEE,
  EmployeeDto,
  IndividualInfoDto,
  IndividualInfoService,
} from 'src/app/home-api';

import {
  EXTRA_COVID_ATTRIBUTES_LIST,
  EXTRA_COVID_ATTRIBUTES_VALUES_LIST,
} from '../extra-covid';

type RadioVariant = {name: string; description?: string};

@Component({
  selector: 'employee-extra-vaccine',
  templateUrl: './extra-vaccine.template.html',
  styleUrls: ['./extra-vaccine.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class ExtraVaccineComponent {
  private readonly savingInProgress$ = new Subject<boolean>();
  private readonly changesAfterSave$ = new Subject<IndividualInfoDto[]>();
  private readonly attributesAndValues$ = combineLatest([
    this.attributes$.pipe(
      map(attributes => {
        return attributes?.filter(({code}) => this.fieldNames.includes(code));
      }),
      startWith(null),
    ),
    this.attributesValues$.pipe(
      map(attributes =>
        attributes?.filter(({attributeCode}) => this.fieldNames.includes(attributeCode)),
      ),
      startWith(null),
    ),
  ]).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly fieldNames = ['fluStatus'];

  readonly loading$ = merge(
    this.attributesAndValues$.pipe(
      map(([fields, values]) => fields === null || values === null),
    ),
    this.savingInProgress$,
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly values$ = merge(
    this.changesAfterSave$,
    this.attributesAndValues$.pipe(
      map(([_, values]) => values),
      filter(values => values !== undefined),
    ),
  ).pipe(
    tap(values => {
      if (values && values.length > 0) {
        const formValuesObject = {
          fluStatus: null,
        };

        values.forEach(entry => {
          formValuesObject[entry.attributeCode] = {name: entry.value};

          if (entry.value) {
            this.form.controls[entry.attributeCode].disable();
          }
        });

        this.form.patchValue(formValuesObject);
      }
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly fields$ = this.attributesAndValues$.pipe(
    map(([fields, _]) => fields),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly formNotFinished$ = combineLatest([this.loading$, this.values$]).pipe(
    map(
      ([loading, values]) =>
        !loading && (!values || values.length < this.fieldNames.length),
    ),

    startWith(false),
  );

  readonly fluStatusVariants: RadioVariant[] = [
    {name: 'Планирую вакцинироваться'},
    {name: 'Уже вакцинировался'},
    {name: 'Не планирую вакцинироваться'},
  ];

  readonly form = new FormGroup({
    fluStatus: new FormControl(null, Validators.required),
  });

  constructor(
    @Inject(CURRENT_EMPLOYEE) private readonly currentEmployee$: Observable<EmployeeDto>,
    @Inject(EXTRA_COVID_ATTRIBUTES_LIST)
    private readonly attributes$: Observable<ReadonlyArray<AttributeDto>>,
    @Inject(EXTRA_COVID_ATTRIBUTES_VALUES_LIST)
    private readonly attributesValues$: Observable<ReadonlyArray<IndividualInfoDto>>,
    @Inject(IndividualInfoService)
    private readonly individualInfoService: IndividualInfoService,
    @Inject(TuiDestroyService)
    private readonly destroy$: TuiDestroyService,
    @Inject(TuiNotificationsService)
    private readonly tuiNotificationsService: TuiNotificationsService,
  ) {}

  readonly radioIdentityMatcher: TuiIdentityMatcher<RadioVariant> = (item1, item2) => {
    return item1.name === item2.name;
  };

  saveValues() {
    this.savingInProgress$.next(true);

    combineLatest([
      this.currentEmployee$.pipe(map(({individualId}) => individualId)),
      this.values$,
      this.fields$.pipe(
        map(fields =>
          fields.reduce((acc, field) => {
            return {...acc, [field.code]: field};
          }, {} as {[key: string]: AttributeDto}),
        ),
      ),
    ])
      .pipe(
        switchMap(([individualId, existValues, fields]) => {
          return forkJoin(
            this.fieldNames.map(fieldName => {
              if (!this.form.controls[fieldName].disabled) {
                const value = {
                  individualId,
                  attributeId: fields[fieldName].id,
                  value: this.form.value[fieldName]?.name,
                };

                const request =
                  existValues[fieldName] === undefined
                    ? this.individualInfoService.create(value)
                    : this.individualInfoService.updateById(individualId, value);

                return request.pipe(
                  map(response => ({success: true, value: response})),
                  catchError(() => {
                    return of({success: false, value});
                  }),
                );
              }

              return EMPTY;
            }),
          );
        }),
        finalize(() => {
          this.savingInProgress$.next(false);
        }),
        switchMap(responses => {
          const successedFields = responses
            .filter(entry => entry.success)
            .map(entry => entry.value as IndividualInfoDto);

          this.changesAfterSave$.next(successedFields);
          this.savingInProgress$.next(false);

          if (successedFields.length === this.fieldNames.length) {
            return this.tuiNotificationsService.show('Спасибо, мы всё получили :)', {
              status: TuiNotification.Success,
            });
          }

          return this.tuiNotificationsService.show(
            'Не все ответы удалось сохранить. Попробуйте ещё раз чуть позже',
            {
              status: TuiNotification.Error,
            },
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
