import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
} from '@pik-taiga-ui/core';
import {
  TuiInputDateModule,
  TuiSelectModule,
  TuiSelectOptionModule,
  TuiTextAreaModule,
} from '@pik-taiga-ui/kit';

import {EmployeeWorkingStatusComponent} from './working-status.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    TuiLetModule,
    TuiButtonModule,
    TuiLabelModule,
    TuiSelectModule,
    TuiInputDateModule,
    TuiTextAreaModule,
    TuiDataListModule,
    TuiSelectOptionModule,
    TuiTextfieldControllerModule,
  ],
  exports: [EmployeeWorkingStatusComponent],
  declarations: [EmployeeWorkingStatusComponent],
  providers: [],
})
export class EmployeeWorkingStatusModule {}
