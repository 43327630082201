import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {ApiListResponse, ApiResponse, WorkingStatusDto} from '../interfaces';
import {HOME_API_URL} from '../tokens';

interface UpdateBody {
  /**
   * Статусы зашиты намертво.
   * Использовать можно только существующие
   *
   * Посмотреть полный перечень можно только через БД
   *
   * Чуствительны к регистру
   *
   * В PUT-методе не изменяет статус уже существующей записи, но без валидного значения (любого)
   * будет 500-ая
   */
  workingStatusCode:
    | 'SickLeave'
    | 'RemoteWork'
    | 'Errand'
    | 'Vacation'
    | 'Vacationv2'
    | 'DaysOff'
    | 'Birthday';
  /**
   * ISO-Дата начала
   *
   * По-умолчанию сегодняшняя дата
   */
  dateStart?: string | null;
  dateEnd?: string | null;
  comment?: string | null;
}

type CreateBody = UpdateBody;

@Injectable({providedIn: 'root'})
export class WorkingStatusService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getAll() {
    return this.http.get<ApiListResponse<readonly WorkingStatusDto[] | null>>(
      `${this.apiUrl}/v1/workingStatus`,
    );
  }

  getNearest() {
    return this.http.get<ApiResponse<WorkingStatusDto | null>>(
      `${this.apiUrl}/v1/workingStatus`,
      {params: {nearestStatus: true}},
    );
  }

  getByIndividualGuid(individualGuid: string) {
    return this.http.get<ApiResponse<WorkingStatusDto | null>>(
      `${this.apiUrl}/v1/workingStatus/user/${individualGuid}`,
    );
  }

  /**
   * Создание записи о новом статусе текущего пользователя.
   * Проверки на существование записей в определённые даты на бэке нет.
   *
   * Все проверки следует делать на фронте
   */
  create(body: CreateBody) {
    return this.http.post<ApiResponse<WorkingStatusDto | null>>(
      `${this.apiUrl}/v1/workingStatus`,
      body,
    );
  }

  updateById(id: number, body: UpdateBody) {
    return this.http.put<ApiResponse<WorkingStatusDto>>(
      `${this.apiUrl}/v1/workingStatus/${id}`,
      body,
    );
  }

  deleteById(id: number) {
    return this.http.delete<ApiResponse<boolean>>(
      `${this.apiUrl}/v1/workingStatus/${id}`,
    );
  }
}
