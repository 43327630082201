import {RequestApproverGroupDto} from '@app/libs/servicedesk-api';

import {ApproverGroups, ApproverGroupsStep} from '../interfaces';
import {approverGroupAdapter} from './approver-group.adapter';

export function approverGroupsAdapter(
  rawGroups: RequestApproverGroupDto[],
  requestTypeCode?: string,
): ApproverGroups {
  const groups: ApproverGroups = [[]];
  const steps: ApproverGroupsStep[] = [];

  switch (requestTypeCode) {
    default:
      // По дефолту каждая группа - отдельный шаг,
      // Если статус не "Новая", то считаем что шаг уже в процессе
      steps.push(
        ...rawGroups.map((group, index) => {
          const parsedGroup = approverGroupAdapter(group);

          return {
            available: parsedGroup.statusCode !== 'New',
            order: index,
            groups: [parsedGroup],
          };
        }),
      );
      break;
  }

  steps.forEach(step => {
    if (step.available) {
      groups[0].push(step);
    } else if (!groups[1]) {
      groups[1] = [step];
    } else {
      groups[1].push(step);
    }
  });

  return groups;
}
