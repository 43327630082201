import {InjectionToken} from '@angular/core';
import {Observable, of} from 'rxjs';

/**
 * Необходим для получения id типа заявки, зная только `typeName`
 */
export const HOLIDAYS_AND_WEEKENDS = new InjectionToken<
  Observable<{[year: number]: string[]}>
>('Стрим с календарем праздников и выходных', {
  factory: () => of([]),
});
