import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  pluck,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';

import {
  ApiGETListRequestParams,
  ApiListResponse,
  HttpAnyParams,
  JobClassifierPositionDto,
} from '../interfaces';
import {HOME_API_URL} from '../tokens';

interface JobClassifierPositionFilterParams {
  name?: string;
}

type JobClassifierPositionGetParams = ApiGETListRequestParams &
  JobClassifierPositionFilterParams &
  HttpAnyParams;

@Injectable({providedIn: 'root'})
export class JobClassifierPositionService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getEntries(rawParams: JobClassifierPositionGetParams) {
    const params = new HttpParams({fromObject: rawParams});

    return this.http.get<ApiListResponse<readonly JobClassifierPositionDto[] | null>>(
      `${this.apiUrl}/v1/JobClassifierPosition`,
      {params},
    );
  }

  autocomplete(searchSubject$: Subject<string>) {
    return searchSubject$.pipe(
      debounceTime(400),
      map(value => (typeof value === 'string' ? value.trim() : '')),
      distinctUntilChanged(),
      switchMap((searchQuery: string) =>
        this.getEntries({name: searchQuery, limit: 20}).pipe(
          pluck('data'),
          startWith(null),
        ),
      ),
      startWith([]),
    );
  }
}
