<tui-badged-content
  *tuiLet="substitutionsCount$ | async as count"
  [colorTop]="count ? 'var(--tui-info-fill)' : null"
  [contentTop]="count"
>
  <button
    tuiIconButton
    type="button"
    icon="tuiIconUsers"
    size="m"
    appearance="outline"
    (click)="showWidget()"
  ></button>
</tui-badged-content>
