import {HttpErrorResponse} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {RequestService} from '@app/libs/servicedesk-api';

import {BehaviorSubject, asyncScheduler, of} from 'rxjs';
import {
  catchError,
  filter,
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';

import {convertFullRequestDto} from '../../adapters';
import {SDRequest} from '../../interfaces';
import {OPENED_REQUEST} from '../request-list/tokens';
import {SDFullAccessRequest, SDFullRequest} from '../request-view';
import {TuiScrollbarComponent} from '@pik-taiga-ui/core';

type SDRequestResponse = {
  request: SDFullRequest | SDFullAccessRequest | null;
  error?: string;
};

/**
 * Компонент-прослойка для выдергивания id-заявки из роута,
 * подгрузки первичных данных и отображения ошибок в случае необходимости
 */
@Component({
  selector: 'sd-request-loader',
  templateUrl: './request-loader.template.html',
  styleUrls: ['./request-loader.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicedeskRequestLoaderComponent {
  @ViewChild(TuiScrollbarComponent, {read: ElementRef})
  private readonly scrollBar?: ElementRef<HTMLElement>;

  private readonly defaultTitle = this.title.getTitle();

  private readonly _request$ = this.route.params.pipe(
    map(params => (params.requestId ? parseInt(params.requestId) : null)),

    filter(requestId => requestId !== null),
    switchMap(requestId =>
      this.requestService.getFullById(requestId).pipe(
        map(res => {
          if (res === null) {
            return null;
          }

          if (res.data) {
            return <SDRequestResponse>{
              request: convertFullRequestDto(res.data),
            };
          }

          return <SDRequestResponse>{
            request: null,
            error: 'Сервер вернул пустой ответ',
          };
        }),
        tap(res => {
          if (res && res.request) {
            this.title.setTitle(`${this.defaultTitle} | ${res.request.requestId}`);
          }
        }),
        catchError((httpError: unknown) => {
          const [error, status] =
            httpError instanceof HttpErrorResponse
              ? [
                  `[${httpError.status} ${httpError.statusText}] [RequestId: ${requestId}] StackTrace: ${httpError.error?.error}`,
                  httpError.status,
                ]
              : ['Произошла непредвиденная ошибка', null];

          return of(<SDRequestResponse>{
            request: null,
            error,
            status,
          });
        }),
        startWith(null),
      ),
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly loading$ = this._request$.pipe(
    throttleTime(175, asyncScheduler, {trailing: true}),
    map(res => res === null),
  );

  readonly request$ = this._request$.pipe(
    map(res => res?.request),
    scan((prev, newResolve) => {
      this.scrollBar && this.scrollBar.nativeElement.scrollTo(0, 0);

      return newResolve || prev;
    }, <SDFullRequest>null),
  );

  readonly error$ = this._request$.pipe(filter(res => !!res?.error));

  infoExpanded = false;

  constructor(
    @Inject(RequestService)
    private readonly requestService: RequestService,
    @Inject(ActivatedRoute)
    private readonly route: ActivatedRoute,
    @Inject(OPENED_REQUEST)
    private readonly openedRequest$: BehaviorSubject<SDRequest | null>,
    @Inject(Title)
    private readonly title: Title,
  ) {}

  ngOnDestroy() {
    this.title.setTitle(this.defaultTitle);
  }
}
