import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiLoaderModule,
  TuiPrimitiveTextfieldModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@pik-taiga-ui/core';
import {TuiInputModule, TuiMultiSelectModule} from '@pik-taiga-ui/kit';

import {CustomListComponent} from './custom-list.component';

@NgModule({
  declarations: [CustomListComponent],
  exports: [CustomListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TuiScrollbarModule,
    TuiLoaderModule,
    TuiButtonModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    TuiDataListModule,
    TuiMultiSelectModule,
    TuiPrimitiveTextfieldModule,
  ],
})
export class CustomListModule {}
