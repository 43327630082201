import {Component, Inject} from '@angular/core';
import {TUI_IS_MOBILE_RES} from '@pik-taiga-ui/core';

import {Observable} from 'rxjs';

@Component({
  selector: 'request-wrapper',
  templateUrl: './request-wrapper.template.html',
  styleUrls: ['./request-wrapper.styles.scss'],
})
export class RequestWrapperComponent {
  constructor(
    @Inject(TUI_IS_MOBILE_RES)
    readonly isMobile$: Observable<boolean>,
  ) {}
}
