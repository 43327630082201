// Пример строки, которой отвечает бэк в случае ошибки при отправке заявки в контур. Обратить внимание на `\n` и экранирование
// `Не удалось создать заявку на выпуск НЭП FormId=.\nStatusCode=BadRequest, ReasonPhrase=Bad Request, Result={"statusCode":400,"traceId":"6a73f13078c85c732d1f2a40d91da90f","error":{"code":"BadRequest","message":"Request has invalid input.","details":[{"code":"InvalidFormat","message":"Inn has invalid format.","target":"Inn"}]}}`

export interface KonturErrorResponse {
  error: KonturError;
  statusCode: number;
  traceId: string;
}

export interface KonturError {
  code: string;
  message: string;
  target?: string;
  details?: KonturError[];
}

export interface SignatureErrorData {
  innerMessage: string;
  externalMessage: KonturErrorResponse;
}

/**
 * Эту штуку планируем типизировать на бэке.
 *
 * https://developer.kontur.ru/doc/kcr.api/method?type=post&path=%2Fv1%2Fissues%2F%7BissueId%7D%2Fvalidate
 */
export function getJSONFromKonturError(error: SignatureErrorData): string {
  const errorResponse = error.externalMessage;
  const details = errorResponse.error.details
    ? errorResponse.error.details.map(errorDetails => errorDetails.message).join(', ')
    : errorResponse.error.message;

  return errorResponse
    ? `${error.innerMessage} ${errorResponse.statusCode} ${errorResponse.error.code}: ${details}`
    : 'Произошла непредвиденная ошибка';
}
