<div class="header">
  <div class="header__info">
    <div class="request-number">{{ request.id | requestNumber }}</div>
    <div class="request-date">
      {{ request.created | date: datePipeFormat }}
    </div>
  </div>
  <div class="header__status">
    <tui-tag
      size="l"
      class="status-tag"
      [status]="requestStatusColor"
      [value]="request.statusName"
    ></tui-tag>
  </div>
</div>
<div class="body">
  <div class="title">{{ request.title }}</div>
  <div class="props">
    <div
      *ngIf="showAuthor"
      class="props__item"
    >
      Автор: {{ request.authorFullName || '&mdash;' }}
    </div>
    <div
      *ngIf="showEmployee"
      class="props__item"
    >
      Сотрудник: {{ request.employeeFullName || '&mdash;' }}
    </div>
  </div>
</div>
