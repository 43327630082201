import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {TuiDialogContext} from '@pik-taiga-ui/core';

import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm.template.html',
  styleUrls: ['./confirm.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  readonly title = this.context?.data?.question || 'Вы уверены?';

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean, {question: string}>,
  ) {}

  ok() {
    this.context.completeWith(true);
  }

  cancel() {
    this.context.completeWith(false);
  }
}
