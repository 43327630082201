import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {TuiButtonModule, TuiHintModule} from '@pik-taiga-ui/core';

import {EmployeeIpPhoneComponent} from './ip-phone.component';
import {PasswordPipeModule} from './pipes';

@NgModule({
  imports: [
    CommonModule,
    TuiLetModule,
    PasswordPipeModule,
    TuiButtonModule,
    ClipboardModule,
    TuiHintModule,
  ],
  exports: [EmployeeIpPhoneComponent],
  declarations: [EmployeeIpPhoneComponent],
  providers: [],
})
export class EmployeeIpPhoneModule {}
