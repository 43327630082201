import {InjectionToken, Provider} from '@angular/core';
import {AvailableDaysService} from '@app/libs/servicedesk-api';
import {Observable, of} from 'rxjs';
import {pluck, shareReplay, startWith} from 'rxjs/operators';

export interface EmployeeVacationInfo {
  byCompanyInfo: Array<{vacationDays: number; position: string; company: string}>;
  maxVacationDays: number;
}

export const PLANNED_DAYS = new InjectionToken<Observable<EmployeeVacationInfo>>('', {
  factory: () => of(null),
});

export const USER_PROVIDERS: Provider[] = [
  {
    provide: PLANNED_DAYS,
    useFactory: plannedDaysFactory,
    deps: [AvailableDaysService],
  },
];

function plannedDaysFactory(
  infoService: AvailableDaysService,
): Observable<number | null> {
  const year: number = new Date().getFullYear() + 1;

  return infoService
    .byYear(year)
    .pipe(
      pluck('data'),
      pluck('count'),
      startWith(null),
      shareReplay({bufferSize: 1, refCount: false}),
    );
}
