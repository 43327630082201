import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {TuiLinkModule} from '@pik-taiga-ui/core';

import {EmployeeBudgetComponent} from './budget.component';

@NgModule({
  imports: [CommonModule, TuiLetModule, TuiLinkModule],
  exports: [EmployeeBudgetComponent],
  declarations: [EmployeeBudgetComponent],
  providers: [],
})
export class EmployeeBudgetModule {}
