import {Field} from '@app/core/interfaces/field';
import {SDFullRequest} from '../components';

export function getRequestCopyUrl(request: SDFullRequest): any {
  const queryParams: any = {};

  switch (request.typeCode) {
    case 'infoaccessv2':
      {
        const accessRequest = request as any;

        [
          'commentForAuditors',
          'contractorCompanyName',
          'contractorDateOfBirth',
          'contractorEmail',
          'contractorNda',
          'contractorStartDate',
          'typeByUserId',
        ].forEach(key => {
          if (request[key]) {
            queryParams[key] = request[key] || '';
          }
        });

        queryParams['managerId1C'] = accessRequest.manager?.individualGuid1C || null;

        if (Array.isArray(accessRequest.userResources)) {
          queryParams.individuals = [];
          queryParams.employees = [];

          const resources = new Set();

          accessRequest.userResources.forEach(source => {
            if (source.individual) {
              queryParams['individuals'].push(source.individual.guid1C);
            } else if (source.employee) {
              queryParams['employees'].push(
                source.employee.employeeGuid1C || source.employee.guid1C,
              );
            }

            if (source.resources && Array.isArray(source.resources)) {
              source.resources.forEach(resource => {
                const accessTypes = resource.accessTypes
                  ? resource.accessTypes.map(accessType => {
                      return {
                        id: accessType.id,
                        accessRestrictionAnalytics: accessType.accessRestrictionAnalytics.map(
                          ({id, name, ref1c}) => {
                            return {id, name, ref1c};
                          },
                        ),
                      };
                    })
                  : null;

                resources.add(
                  JSON.stringify({
                    resourceId: resource.id,
                    profileId: resource.accessGroupProfileId,
                    accessTypes,
                  }),
                );
              });

              queryParams.resources = [...resources];
            }
          });
        }
      }

      return queryParams;
    case 'errandv2':
      if (request.fields && Array.isArray(request.fields)) {
        const keysForFill = [
          'whoPays',
          'country',
          'city',
          'errandDescription',
          'companyGuid1c',
          'errandMissionGuid1C',
          'errandMissionOther',
          'manager',
          'employeeInErrand',
        ];

        request.fields.forEach((field: Field) => {
          if (keysForFill.includes(field.code)) {
            queryParams[field.code] = field.value || '';
          }
        });
      }

      return queryParams;
    case 'recruitmentv2':
      request.fields.forEach((field: Field) => {
        if (field.value) {
          switch (field.code) {
            case 'responsibleGuid':
            case 'interviewerGuid':
              {
                try {
                  const guids = JSON.parse(field.value);

                  if (Array.isArray(guids)) {
                    if (!queryParams[field.code]) {
                      queryParams[field.code] = guids;
                    } else {
                      queryParams[field.code] = [...queryParams[field.code], ...guids];
                    }
                  }
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.log(e);

                  return;
                }
              }

              break;

            default:
              if (!queryParams[field.code]) {
                queryParams[field.code] = field.value;
              } else {
                if (Array.isArray(queryParams[field.code])) {
                  queryParams[field.code].push(field.value);
                } else {
                  queryParams[field.code] = [queryParams[field.code], field.value];
                }
              }

              break;
          }
        }
      });

      return queryParams;
    case 'vacationv2':
      {
        const keysForFill = [
          'vacationType',
          'startDate',
          'endDate',
          'approvers',
          'substitutional',
          'isSubstitutionalApprover',
        ];

        request.fields.forEach((field: Field) => {
          if (keysForFill.includes(field.code)) {
            if (!queryParams[field.code]) {
              queryParams[field.code] = field.value;
            } else {
              if (Array.isArray(queryParams[field.code])) {
                queryParams[field.code].push(field.value);
              } else {
                queryParams[field.code] = [queryParams[field.code], field.value];
              }
            }
          }
        });
      }

      return queryParams;
    case 'promotion':
      request.fields.forEach((field: Field) => {
        if (field.value) {
          if (!queryParams[field.code]) {
            queryParams[field.code] = field.value;
          } else {
            if (Array.isArray(queryParams[field.code])) {
              queryParams[field.code].push(field.value);
            } else {
              queryParams[field.code] = [queryParams[field.code], field.value];
            }
          }
        }
      });

      return queryParams;

    default:
      return queryParams;
  }
}
