import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {PikLocatorService} from 'pik-header';
import {Observable} from 'rxjs';
import {map, combineLatest, pluck, startWith} from 'rxjs';
import {EmployeeDto} from 'src/app/home-api';
import {CURRENT_EMPLOYEES} from 'src/app/home-api';

import {BLOCKED_REQUESTS} from '../../tokens';

@Component({
  selector: 'employee-sd-requests',
  templateUrl: './sd-requests.template.html',
  styleUrls: ['./sd-requests.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeSDRequestsComponent {
  private readonly daysoffCount$ = this.currentEmployees$.pipe(
    map(employees => {
      return employees.reduce((acc, cur) => acc + cur.daysOff, 0);
    }),
  );

  readonly vacationDaysCount$ = this.currentEmployees$.pipe(
    map(employees => {
      return employees.reduce(
        (acc, cur) => (acc > cur.vacationDays ? acc : cur.vacationDays),
        0,
      );
    }),
  );

  readonly comfortSDJiraLink = 'https://jira.pik.ru/servicedesk/customer/portal/79';
  readonly serviceDeskURL = this.pikLocator.urls.serviceDeskUrl;
  readonly blockedRequests$ = this.blockedInstances$;
  readonly isDaysOffAvailable$ = combineLatest([
    this.daysoffCount$,
    this.blockedRequests$,
  ]).pipe(
    map(
      ([daysCount, blockedByRules]) =>
        daysCount > 0 && blockedByRules.daysoff === undefined,
    ),
  );

  readonly isVacationAvailable$ = combineLatest([
    this.vacationDaysCount$,
    this.blockedRequests$,
  ]).pipe(
    map(
      ([daysCount, blockedByRules]) =>
        daysCount > 0 && blockedByRules.vacationv2 === undefined,
    ),
  );

  readonly jiraLinkAvailable$ = this.blockedInstances$.pipe(
    map(blockedInstances => blockedInstances['comfort-sd'] !== true),
    startWith(false),
  );

  constructor(
    private readonly pikLocator: PikLocatorService,
    @Inject(CURRENT_EMPLOYEES)
    private readonly currentEmployees$: Observable<EmployeeDto[]>,
    @Inject(BLOCKED_REQUESTS)
    private readonly blockedInstances$: Observable<{
      [sdRequestCode: string]: true | undefined;
    }>,
  ) {}
}
