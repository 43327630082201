import {Component, Inject} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  AsyncValidatorFn,
} from '@angular/forms';
import {
  IndividualInfoDto,
  CURRENT_EMPLOYEE,
  EmployeeDto,
  IndividualInfoService,
  AttributeDto,
  ApiListResponse,
} from '@app/home-api';
import {AboutService} from '@app/home-api/services/about.service';
import {
  TuiContextWithImplicit,
  TuiDestroyService,
  TuiIdentityMatcher,
  TuiStringHandler,
  TuiValidationError,
} from '@pik-taiga-ui/cdk';
import {
  Subject,
  map,
  shareReplay,
  BehaviorSubject,
  merge,
  filter,
  startWith,
  Observable,
  forkJoin,
  finalize,
  takeUntil,
  combineLatest,
  switchMap,
  of,
  debounceTime,
  distinctUntilChanged,
  pluck,
  scan,
  delay,
} from 'rxjs';
import {
  parseAttributeValue,
  stringifyAttributeValue,
} from '../adapters/attribute-value.adapter';
import {EXTRA_ATTRIBUTES_LIST, EXTRA_ATTRIBUTES_VALUES_LIST} from '../extra';

const SCROLL_OFFSET = 20;

interface ExtraFieldsFromValue {
  aboutMeIsHidden: boolean;
  skillsIsHidden: boolean;
  hobbiesIsHidden: boolean;
}

@Component({
  selector: 'hobbies',
  templateUrl: './hobbies.component.html',
  styleUrls: ['./hobbies.component.scss'],
})
export class HobbiesComponent {
  private readonly hints = {
    hobbies: 'Ваши хобби и увлечения  (до 5 шт.)',
    skills: 'Ваши знания, навыки и сильные стороны (до 5 шт.)',
    aboutMe: 'Ваша специальность, род деятельности и проекты',
  };

  private readonly updatedAttributes$ = new Subject<readonly IndividualInfoDto[]>();
  private attributesValues: {[attributeCode: string]: IndividualInfoDto} = {};
  private readonly attributesIds$ = this.attributes$.pipe(
    map(attributes => attributes.reduce((acc, {id, code}) => ({...acc, [code]: id}), {})),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly autocomleteMap: Map<string, any> = new Map();

  readonly editingForm$ = new BehaviorSubject<FormGroup | null>(null);
  readonly isSaving$ = new BehaviorSubject(false);

  readonly attributesValues$ = merge(
    this.updatedAttributes$,
    this.allAttributesValues$,
  ).pipe(
    filter(values => values !== null),
    map((values: any) => {
      const keyValueObj: {[attributeCode: string]: string} = {};

      this.attributesValues = {};

      values.forEach(value => {
        value.value = parseAttributeValue(value);
        keyValueObj[value.attributeCode] = value.value;
        this.attributesValues[value.attributeCode] = value;
      });

      const currenHobbies = keyValueObj.hobbies && JSON.parse(JSON.stringify(keyValueObj.hobbies));

      if(currenHobbies) {
        const currentPersonHobbies = currenHobbies.map(item => item.name);
        localStorage.setItem('currentPersonHobbies', currentPersonHobbies);
      } else {
        localStorage.setItem('currentPersonHobbies', '');
      }

      return keyValueObj;
    }),
    startWith(null),
  );

  constructor(
    @Inject(CURRENT_EMPLOYEE) private readonly currentEmployee$: Observable<EmployeeDto>,
    @Inject(IndividualInfoService)
    private readonly individualInfoService: IndividualInfoService,
    @Inject(AboutService)
    private readonly aboutService: AboutService,
    @Inject(EXTRA_ATTRIBUTES_LIST)
    private readonly attributes$: Observable<ReadonlyArray<AttributeDto>>,
    @Inject(EXTRA_ATTRIBUTES_VALUES_LIST)
    private readonly allAttributesValues$: Observable<ReadonlyArray<IndividualInfoDto>>,
    @Inject(TuiDestroyService)
    private readonly destroy$: TuiDestroyService,
  ) {}

  readonly itemContent: TuiStringHandler<TuiContextWithImplicit<any>> = ({$implicit}) => {
    return $implicit.name;
  };

  readonly stringifyByName = (item: any) => item?.name || '';

  startEdit() {
    const {skills, hobbies, aboutMe} = this.attributesValues;

    const form = new FormGroup({
      skills: new FormControl(skills?.value),
      hobbies: new FormControl(hobbies?.value),
      aboutMe: new FormControl(aboutMe?.value?.toString(), [Validators.maxLength(500)]),

      skillsIsHidden: new FormControl(!!skills?.isHidden),
      hobbiesIsHidden: new FormControl(!!hobbies?.isHidden),
      aboutMeIsHidden: new FormControl(!!aboutMe?.isHidden),
    });

    form.controls.skills.addAsyncValidators(maxLengthArrayValidator(5));
    form.controls.hobbies.addAsyncValidators(maxLengthArrayValidator(5));
    form.controls.skills.markAllAsTouched();
    form.controls.hobbies.markAllAsTouched();

    this.autocomleteMap
      .set(
        'skills',
        this.autocompleteSet(
          'skills',
          this.aboutService.getAll.bind(this.aboutService, 'skills'),
          form.get('skills') as FormControl,
        ),
      )
      .set(
        'hobbies',
        this.autocompleteSet(
          'hobbies',
          this.aboutService.getAll.bind(this.aboutService, 'hobbies'),
          form.get('hobbies') as FormControl,
        ),
      );

    this.editingForm$.next(form);
  }

  cancelEdit() {
    this.editingForm$.next(null);
  }

  toogleVisibility(filedName: string) {
    const form = this.editingForm$.value;

    form.controls[filedName].setValue(!form.controls[filedName].value);
  }

  save() {
    const form: ExtraFieldsFromValue = this.editingForm$.value.value;

    const requests: Observable<IndividualInfoDto>[] = [
      'skills',
      'hobbies',
      'aboutMe',
    ].reduce((observables, fieldName) => {
      if (form[fieldName] || (!form[fieldName] && this.attributesValues[fieldName])) {
        return [
          ...observables,
          this.createRequestFor(
            fieldName,
            form[fieldName] || null,
            form[`${fieldName}IsHidden`],
          ),
        ];
      }

      return observables;
    }, []);

    this.isSaving$.next(true);

    forkJoin(requests)
      .pipe(
        finalize(() => this.isSaving$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe(updatedFields => {
        updatedFields.forEach(field => {
          if (field.value === null) {
            delete this.attributesValues[field.attributeCode];
          } else if (this.attributesValues[field.attributeCode]) {
            this.attributesValues[field.attributeCode].value = field.value;
            this.attributesValues[field.attributeCode].isHidden = field.isHidden;
          } else {
            this.attributesValues[field.attributeCode] = field;
          }
        });

        this.updatedAttributes$.next(Object.values(this.attributesValues));
        this.editingForm$.next(null);
      });
  }

  private createRequestFor(
    fieldName: string,
    fieldValue: string | boolean,
    fieldHidden: boolean,
  ) {
    /** Значение ещё не было создано */

    if (!this.attributesValues[fieldName]) {
      return combineLatest([
        this.attributesIds$,
        this.currentEmployee$.pipe(map(({individualId}) => individualId)),
      ]).pipe(
        switchMap(([ids, individualId]) => {
          return this.individualInfoService.create({
            individualId,
            attributeId: ids[fieldName],
            value: stringifyAttributeValue(fieldName, fieldValue),
            isHidden: fieldHidden,
          });
        }),
      );
    }

    const {value, isHidden, id} = this.attributesValues[fieldName];

    /** Значение ещё не изменилось */
    if (value === fieldValue && fieldHidden === isHidden) {
      return of(this.attributesValues[fieldName]);
    }

    /** Значение удалено */
    if (fieldValue === null) {
      return this.individualInfoService
        .delete(id)
        .pipe(map(_ => ({...this.attributesValues[fieldName], value: null})));
    }

    return this.currentEmployee$.pipe(
      map(({individualId}) => individualId),
      switchMap(individualId => {
        return this.individualInfoService.updateById(id, {
          individualId,
          value: stringifyAttributeValue(fieldName, fieldValue),
          isHidden: fieldHidden,
        });
      }),
    );
  }
  onSearchValueChange = (searchQuery: string, searchSubject: BehaviorSubject<string>) =>
    searchQuery !== null && searchSubject.next(searchQuery);
  autocompleteMatcherById: TuiIdentityMatcher<unknown & {id: number}> = (item1, item2) =>
    item1.id === item2.id;

  private autocompleteSet<T>(
    key: string,
    searchPipe: (params) => Observable<ApiListResponse<readonly T[]>>,
    control: FormControl,
  ) {
    const searchSubject$ = new Subject<string>();

    const nextSubject$ = new Subject<boolean>();

    const getByText$ = searchSubject$.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(name =>
        searchPipe({name}).pipe(delay(200), pluck('data'), startWith(null)),
      ),
      map(items =>
        items
          ? [...items].sort((a, b) =>
              a['name'].toLowerCase() > b['name'].toLowerCase() ? 1 : -1,
            )
          : null,
      ),
      map(items => ({
        items,
        loading: !items?.length,
        hasMore: (items?.length || 0) >= SCROLL_OFFSET,
      })),
    );

    const getByOffset$ = nextSubject$.pipe(
      scan((offset, action) => {
        return action ? (offset += SCROLL_OFFSET) : 0;
      }, 0),
      switchMap(offset => {
        return searchPipe({offset}).pipe(delay(200), pluck('data'), startWith(null));
      }),
      map(items =>
        items
          ? [...items].sort((a, b) =>
              a['name'].toLowerCase() > b['name'].toLowerCase() ? 1 : -1,
            )
          : null,
      ),
      scan(
        (state, items) => {
          if (!state) {
            state.items = new Map();
            state.loading = true;
          }
          if (items) {
            items.forEach(item => state.items.set(item['id'], item));
            state.loading = false;
            state.hasMore = items.length >= SCROLL_OFFSET;
          } else {
            state.loading = true;
          }

          return state;
        },
        {items: new Map(), loading: true, hasMore: false},
      ),

      map(state => {
        const items = [...state.items.values()];
        return {...state, items: items.length ? items : null};
      }),
      startWith({items: null, loading: true, hasMore: false}),
    );

    const searchObservable$ = merge(getByText$, getByOffset$);

    const hint = this.hints[key];

    return {nextSubject$, searchSubject$, searchObservable$, control, hint};
  }
}

export function maxLengthArrayValidator(max: number): AsyncValidatorFn {
  return (c: AbstractControl) => {
    return (c.value?.length || 0) <= max
      ? of(null)
      : of({
          error: new TuiValidationError(`Можно указать не более ${max} значений`),
        });
  };
}
