import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiGroupModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
} from '@pik-taiga-ui/core';
import {
  TuiAvatarModule,
  TuiBadgedContentModule,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiInputDateModule,
  TuiSelectModule,
} from '@pik-taiga-ui/kit';

import {SubstitutionsOpenerComponent} from './opener.component';
import {SubstitutionsWidgetComponent} from './widget.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiButtonModule,
    TuiSelectModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiAvatarModule,
    TuiBadgedContentModule,
    TuiComboBoxModule,
    TuiDataListWrapperModule,
    TuiTextfieldControllerModule,
    TuiInputDateModule,
    TuiGroupModule,
  ],
  exports: [SubstitutionsWidgetComponent, SubstitutionsOpenerComponent],
  declarations: [SubstitutionsWidgetComponent, SubstitutionsOpenerComponent],
})
export class SubstitutionsModule {}
