import {CommonModule, registerLocaleData} from '@angular/common';
import ru from '@angular/common/locales/ru';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';

import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsModule} from '@ngxs/store';
import {NgxsResetPluginModule} from 'ngxs-reset-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsDispatchPluginModule} from '@ngxs-labs/dispatch-decorator';

import {environment} from '@env/environment';
import {TuiTableModule} from '@pik-taiga-ui/addon-table';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {
  TuiDialogModule,
  TuiDropdownModule,
  TuiLoaderModule,
  TuiNotificationsModule,
  TuiRootModule,
  TuiScrollbarModule,
} from '@pik-taiga-ui/core';
import {TuiPaginationModule, TuiTabsModule} from '@pik-taiga-ui/kit';
import {PikAnalyticsModule, PikErrorPagesModule, PikHeaderModule} from 'pik-header';

import {AppComponent} from './app.component';
import {APP_PROVIDERS} from './app.providers';
import {AppRoutingModule} from './app.routing';
import {AppMaterialModule} from './app-material.module';
import {ErrorComponent} from './components/snacks/error/error.component';
import {CoreModule} from '@core/core.module';
import {EmployeeCardModule} from './employee-card/employee-card.module';
import {MANAGER_VACATION_PROVIDER} from './providers';
import {CREATE_REQUESTS_PROVIDERS} from './providers/holydays.provider';
import {USER_PROVIDERS} from './providers/user.provider';
import {SharedModule} from '@shared/shared.module';
import {SubstitutionsModule} from './substitutions';
import {ServicedeskKitModule} from './servicedesk-kit';
import {TuiSheetModule} from '@pik-taiga-ui/addon-mobile';
import {REQUESTS_SOURCE} from './libs/servicedesk-api';

registerLocaleData(ru);

@NgModule({
  declarations: [AppComponent, ErrorComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppMaterialModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    CoreModule,
    SharedModule,
    AppRoutingModule,
    PikHeaderModule.forRoot(),
    PikErrorPagesModule,
    BrowserAnimationsModule,
    PikAnalyticsModule.forRoot(environment.googleAnalytics),
    TuiRootModule,
    TuiLetModule,
    TuiTabsModule,
    TuiScrollbarModule,
    TuiDropdownModule,
    TuiTableModule,
    TuiNotificationsModule,
    SubstitutionsModule,
    TuiDialogModule,
    TuiSheetModule,
    TuiPaginationModule,
    EmployeeCardModule,
    ServicedeskKitModule,
    TuiLoaderModule,
  ],
  providers: [
    APP_PROVIDERS,
    CREATE_REQUESTS_PROVIDERS,
    USER_PROVIDERS,
    MANAGER_VACATION_PROVIDER,
    {
      provide: REQUESTS_SOURCE,
      useValue: ['lk', 'common'],
    },
    // TODO: убрать после фикса багов нового ЛК
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => {
    //     return () => {
    //         window.location.href = environment.redesignUrl + '/employees/employee'
    //     }
    //   },
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
