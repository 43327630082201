<div class="alert"></div>
<div class="header">
  <div class="header__title">Документы</div>
</div>
<div class="content">
  <div *tuiLet="blockedRequests$ | async as blockedRequests" class="documents">
    <a
      *ngIf="blockedRequests && !blockedRequests.twondflv2"
      target="_blank"
      class="documents__entry document"
      [href]="this.serviceDeskURL + '/?new=twondflv2'"
    >
      <div class="document__header">
        <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
        <div class="document__title">Справка 2-НДФЛ</div>
      </div>
    </a>

    <a
      *ngIf="blockedRequests && !blockedRequests.employmentdocumentv2"
      target="_blank"
      class="documents__entry document"
      [href]="serviceDeskURL + '/?new=employmentdocumentv2'"
    >
      <div class="document__header">
        <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
        <div class="document__title">Справка с&nbsp;места работы</div>
      </div>
    </a>

    <a
      *ngIf="blockedRequests && !blockedRequests.employmenthistorycopyv2"
      target="_blank"
      class="documents__entry document"
      [href]="serviceDeskURL + '/?new=employmenthistorycopyv2'"
    >
      <div class="document__header">
        <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
        <div class="document__title">Копия трудовой книжки</div>
      </div>
    </a>

    <a
      *ngIf="blockedRequests && !blockedRequests.employmenthistorycopyv2"
      target="_blank"
      href="https://info.pik.ru/wiki/spaces/HRTECH/pages/24372"
      class="documents__entry document _blue-bg"
    >
      <div class="document__header">
        <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
        <div class="document__title">
          Перейти на электронную трудовую книжку
        </div>
      </div>
    </a>

    <div
      *ngIf="paysheetAvailable$ | async"
      class="documents__entry documents__entry--full-width"
    >
      <tui-loader
        *tuiLet="availablePeriod$ | async as availablePeriod"
        [showLoader]="paymentSheetLoading$ | async"
        [overlay]="true"
      >
        <ng-container *ngIf="!availablePeriod?.disabled; else unavailableLabel">
          <div
            class="document"
            (click)="
              startDownload(
                lastSelectedMonth || availablePeriod.lastPaymentDate.month
              )
            "
          >
            <div class="document__header">
              <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
              <div class="document__title">
                Расчётный лист за
                <tui-hosted-dropdown
                  [canOpen]="true"
                  [content]="calendarDropdown"
                  [(open)]="calendarOpen"
                >
                  <button
                    *ngIf="availablePeriod"
                    tuiLink
                    icon="tuiIconChevronDown"
                    [pseudo]="true"
                    (click)="toggleCalendar($event)"
                  >
                    {{
                      lastSelectedMonth
                        ? lastSelectedMonth
                            .toLocalNativeDate()
                            .toLocaleDateString('ru-RU', {month: 'long'})
                        : availablePeriod.lastPaymentDate.monthTitle
                    }}
                  </button>
                </tui-hosted-dropdown>
              </div>
            </div>

            <div
              *ngIf="availablePeriod && !availablePeriod.disabled"
              class="document__description"
            >
              Заказать расчетный лист за&nbsp;{{
                availablePeriod.currentPaymentMonth.monthTitle
              }}
              можно будет с&nbsp;{{
                availablePeriod.nextCalcDateAvailable.dateTitle
              }}
            </div>
          </div>
        </ng-container>

        <ng-template #unavailableLabel>
          <div class="document _disabled">
            <div class="document__header">
              <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
              Расчётный лист недоступен до
              {{ availablePeriod.firstPaymentDate.dateTitle }}
              <tui-tooltip
                content="Недостаточный стаж для получения РЛ"
                direction="right"
              ></tui-tooltip>
            </div>
          </div>
        </ng-template>

        <ng-template #calendarDropdown>
          <tui-calendar-month
            tuiPreventDefault="mousedown"
            [min]="availablePeriod.firstPaymentDate.month"
            [max]="availablePeriod.lastPaymentDate.month"
            (monthClick)="startDownload($event)"
          ></tui-calendar-month>
        </ng-template>
      </tui-loader>
    </div>
  </div>
</div>
<div class="footer"></div>
