import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {PikAuthService} from 'pik-header';
import {Observable} from 'rxjs';

@Injectable()
export class ResolveApiInterceptor implements HttpInterceptor {
  constructor(private readonly $auth: PikAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const resolvedReq = req.clone({
      url: resolveApi(req.url),
    });

    return next.handle(resolvedReq);
  }
}

function resolveApi(url: string): string {
  const toResolve = url.match(/\{(\w+)\}/g);
  let resolvedUrl = url;

  if (Array.isArray(toResolve)) {
    toResolve.forEach(match => {
      // eslint-disable-next-line no-useless-escape
      const propertyName = match.replace(/[\{, \}]/g, '');

      resolvedUrl = resolvedUrl.replace(match, environment[propertyName]);
    });
  }

  return resolvedUrl;
}
