import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TuiDay, TuiDestroyService, TuiIdentityMatcher} from '@pik-taiga-ui/cdk';
import {PikLocatorService} from 'pik-header';
import {BehaviorSubject, Observable} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

import {UserDto, UserService} from '../home-api';
import {IS_MANAGER} from '../home-api/tokens/is-manager';
import {SubstitutionsService} from './substitutions.service';

@Component({
  selector: 'substitutions-widget',
  templateUrl: './widget.template.html',
  styleUrls: ['./widget.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class SubstitutionsWidgetComponent {
  readonly userSearch$ = new BehaviorSubject<string>('');
  readonly users$ = this.userService.autocompleteUnique(this.userSearch$);

  readonly today = TuiDay.fromLocalNativeDate(new Date());
  readonly newSubstitutionForm = new FormGroup({
    substitutive: new FormControl(null, [Validators.required]),
    dateStart: new FormControl(this.today, [Validators.required]),
    dateEnd: new FormControl(null),
  });

  readonly photoApi = this.locatorService.urls.photoApi;

  readonly substitutions$ = this.substitutionService.allSubstituionsData$;

  constructor(
    private readonly substitutionService: SubstitutionsService,
    private readonly locatorService: PikLocatorService,
    private readonly userService: UserService,
    @Inject(IS_MANAGER) private readonly isManager$: Observable<boolean>,
    @Inject(TuiDestroyService) private readonly destroy$: TuiDestroyService,
  ) {}

  autocompleteMatcherById: TuiIdentityMatcher<unknown & {id: number}> = (item1, item2) =>
    item1.id === item2.id;

  stringifyUser(item: UserDto): string {
    return item.fullName;
  }

  onUserSearch(searchQuery: string | null) {
    this.userSearch$.next(searchQuery || '');
  }

  onDeleteSubstitution(id: number) {
    this.substitutionService
      .removeSubstituion(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  onAddSubstitution() {
    const rawValues = this.newSubstitutionForm.value;
    const preparedValues: [number[], string, string?] = [
      [rawValues.substitutive.id],
      (rawValues.dateStart as TuiDay).toUtcNativeDate().toISOString(),
    ];

    if (rawValues.dateEnd !== null) {
      preparedValues.push((rawValues.dateEnd as TuiDay).toUtcNativeDate().toISOString());
    }

    this.substitutionService
      .createSubstituion(...preparedValues)
      .pipe(
        tap(res => {
          if (res && res.data) {
            this.newSubstitutionForm.reset({
              substitutive: null,
              dateStart: this.today,
              dateEnd: null,
            });
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
