import {InjectionToken, isDevMode} from '@angular/core';

export const SERVICE_DESK_API_URL = new InjectionToken<string>(
  'URL для REST API приложения ServiceDesk',
  {
    factory: () =>
      isDevMode()
        ? 'https://testservice-api.pik.ru/api/v2'
        : 'https://service-api.pik.ru/api/v2',
  },
);
