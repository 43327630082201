import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {TuiSvgModule} from '@pik-taiga-ui/core';

import {EmployeeSDRequestsComponent} from './sd-requests.component';

@NgModule({
  imports: [CommonModule, TuiSvgModule, TuiLetModule],
  exports: [EmployeeSDRequestsComponent],
  declarations: [EmployeeSDRequestsComponent],
})
export class EmployeeSDRequestsModule {}
