<div
  *ngIf="user"
  class="pik-user"
  [class.pik-user--clear]="isInterior"
  [class.pik-user--block]="isBlock"
>
  <app-user-photo
    *ngIf="showAvatar"
    class="pik-user__avatar"
    [user]="user"
  ></app-user-photo>
  <div class="pik-user__info">
    <a
      *ngIf="clickableName && userPageLink; else userName"
      target="_blank"
      class="pik-user__name"
      [href]="userPageLink"
    >
      {{ user[nameField] }}
    </a>
    <div
      *ngIf="user.positionName"
      class="pik-user__prop"
    >
      {{ user.positionName }}
    </div>
    <div
      *ngIf="user.companyName"
      class="pik-user__prop"
    >
      {{ user.companyName }}
    </div>
    <div
      *ngIf="showBirthDate && user.birthDate"
      class="pik-user__prop"
    >
      Дата рождения: {{ user.birthDate | date: 'd MMMM' }}
    </div>
  </div>
  <button
    *ngIf="removable"
    class="pik-user__remover"
    (click)="onRemove($event)"
  >
    <mat-icon>highlight_off</mat-icon>
  </button>
</div>

<ng-template #userName>
  <span class="pik-user__name">{{ user[nameField] }}</span>
</ng-template>
