import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiFormatPhonePipeModule,
  TuiGroupModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
} from '@pik-taiga-ui/core';
import {TuiInputModule, TuiInputPhoneModule} from '@pik-taiga-ui/kit';
import {TextMaskModule} from 'angular2-text-mask';

import {EmployeeSignatureFormModule} from './form';
import {EmployeeSignatureComponent} from './signature.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,

    EmployeeSignatureFormModule,

    TuiLetModule,
    TuiGroupModule,
    TuiLinkModule,
    TuiButtonModule,
    TuiInputModule,
    TuiLoaderModule,
    TuiTextfieldControllerModule,
    TuiFormatPhonePipeModule,
    TuiInputPhoneModule,
  ],
  exports: [EmployeeSignatureComponent],
  declarations: [EmployeeSignatureComponent],
})
export class EmployeeSignatureModule {}
