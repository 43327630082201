import {GAConfig, PikEnvironment} from 'pik-header';

export const environment = {
  production: false,
  apiUrl: `${(<any>window)?.env?.api || 'https://testhome-api.pik.ru'}/api/v1`,
  redesignUrl: (<any>window)?.env?.redesignUrl || 'https://new.test.home.pik-digital.ru',
  employeeUrl: 'https://testhome.pik.ru/employees',
  homeApi: (<any>window)?.env?.api || 'https://testhome-api.pik.ru',
  homeApiUrl: `${(<any>window)?.env?.api || 'https://testhome-api.pik.ru'}/api/v1`,
  serviceDeskApiUrl: 'https://testservice-api.pik.ru/api',
  huntflow: 'https://api.test.huntflow.pik-digital.ru/api/v1',
  learnApi: 'https://api.test.learn.pik-digital.ru',
  pikEnvironment: PikEnvironment.test,
  lkDirectumUrl: 'https://test-ids-directum.pik.ru',
  googleAnalytics: <GAConfig>{
    ga: '',
    optimize: '',
    googleTag: '',
  },
};
