import {inject, InjectionToken} from '@angular/core';
import {EmployeeService} from '@app/core/services/employee.service';
import {map, shareReplay} from 'rxjs/operators';

/**
 * Проверить является ли пользователь аутстаффером возможно только
 * через home-api
 */
export const CURRENT_EMPLOYEE_INFO = new InjectionToken(
  'Стрим с объектом текущего EmployeeInfo из sd-api',
  {
    factory: () => {
      return inject(EmployeeService)
        .getCurrentUser()
        .pipe(
          map(res => res || null),
          shareReplay({bufferSize: 1, refCount: true}),
        );
    },
  },
);
