import {ChangeDetectionStrategy, Component, HostBinding, Inject, Input, Optional} from '@angular/core';
import {environment} from '@env/environment';
import {tuiDefaultProp} from '@pik-taiga-ui/cdk';

import {EmployeeInfo} from '../dynamic-request/interfaces/employee-info.interface';
import {TuiNotification, TuiNotificationsService} from "@pik-taiga-ui/core";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeCardComponent {
  private _employee: EmployeeInfo;
  // 'position' | 'unitName' | 'companyName' | 'telegram' | 'email' | 'hobbies'
  @Input()
  readonly showProperties: string[] | null = ['position', 'unitName'];

  @Input()
  readonly isBigCard: boolean | null = false;

  @Input()
  readonly avatarSize: string | null = 'xl';

  @Input()
  readonly linkable: boolean | null = true;

  @Input()
  readonly guidKey: string | null = 'individualGuid1C';

  @Input()
  readonly defaultAvatar: boolean = false;

  @Input()
  @HostBinding('class._clickable')
  readonly clickable = false;

  userLink = '#';

  constructor(
    @Inject(Clipboard) private readonly clipboard: Clipboard,
    @Inject(TuiNotificationsService)
    @Optional()
    private readonly notifications?: TuiNotificationsService,
  ) {}

  @Input()
  @tuiDefaultProp()
  get employee(): EmployeeInfo {
    return this._employee;
  }

  set employee(employee: EmployeeInfo) {
    if (employee) {
      this.userLink = employee.employeeForSiteId
        ? `${environment.employeeUrl}/${employee.employeeForSiteId}`
        : null;

      this._employee = employee;
    }
  }

  sendToClipboard(value: string) {
    this.clipboard.copy(value);

    if (this.notifications) {
      this.notifications
        .show('Скопировано в буфер обмена', {
          label: '',
          status: TuiNotification.Success,
        })
        .subscribe();
    }
  }
}
