<!-- <div data-appearance="warning" class="alert">
  <tui-svg src="tuiIconCancel"></tui-svg>
  Необходимо проверить актуальность данных
</div> -->
<div class="header">
  <div class="header__title">Контакты</div>
  <div class="header__actions">
    <button
      *ngIf="!(editingForm$ | async)"
      tuiIconButton
      icon="tuiIconSettings"
      size="xs"
      appearance="subaction"
      (click)="startEdit()"
    ></button>
  </div>
</div>
<ng-container *ngIf="attributesValues$ | async as values">
  <div *tuiLet="editingForm$ | async as editingForm" class="content">
    <ng-container
      *ngIf="!editingForm"
      [ngTemplateOutlet]="extraView"
      [ngTemplateOutletContext]="{$implicit: values}"
    ></ng-container>
    <ng-container
      *ngIf="editingForm"
      [ngTemplateOutlet]="extraForm"
      [ngTemplateOutletContext]="{$implicit: editingForm}"
    ></ng-container>
  </div>
</ng-container>

<ng-template #extraView let-values>
  <ng-container *ngIf="values !== null">
    <div class="property-group">
      <div class="property">
        <div class="property__name">Номер телефона</div>
        <a
          *ngIf="values.mobile; else emptyProp"
          tuiLink
          icon="tuiIconCallOut"
          class="property__value"
          [href]="'tel:+7' + values.mobile"
        >
          {{ values.mobile | tuiFormatPhone }}
        </a>
      </div>
      <div class="property">
        <div class="property__name">Telegram</div>
        <a
          *ngIf="values.telegram; else emptyProp"
          tuiLink
          target="_blank"
          class="property__value"
          [href]="'https://t.me/' + values.telegram"
        >
          @{{ values.telegram }}
        </a>
      </div>
    </div>
    <div class="property-group">
      <div class="property">
        <div class="property__name">Часовой пояс</div>
        <div *ngIf="values.timezone; else emptyProp" class="property__value">
          {{ values.timezone }}
        </div>
      </div>
      <div class="property">
        <div class="property__name">Город присутствия</div>
        <div *ngIf="values.city; else emptyProp" class="property__value">
          {{ values.city }}
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #extraForm let-formGroup>
  <tui-loader [showLoader]="isSaving$ | async" [overlay]="true">
    <div class="disclaimer">
      <p>
        С&nbsp;помощью кнопки с&nbsp;иконкой глаза можно скрывать некоторые
        данные на&nbsp;портале от&nbsp;других пользователей:
      </p>
      <ul>
        <li>
          <tui-svg src="tuiIconEyeOpen"></tui-svg>
          &mdash;&nbsp;информация видна всем
        </li>
        <li>
          <tui-svg src="tuiIconEyeClosed"></tui-svg>
          &mdash;&nbsp;информация видна только вам
        </li>
      </ul>
    </div>

    <ng-container [formGroup]="formGroup">
      <div class="property-group">
        <label tuiLabel class="property" [label]="mobileLabel">
          <tui-input-phone
            formControlName="mobile"
            tuiTextfieldSize="m"
            [tuiTextfieldLabelOutside]="true"
            [tuiTextfieldCleaner]="true"
          ></tui-input-phone>
          <tui-field-error formControlName="mobile"></tui-field-error>
        </label>
        <ng-template #mobileLabel>
          <div class="property-label">
            <div class="property-label__text">Мобильный телефон</div>
            <ng-container
              *ngTemplateOutlet="
                visibilityToogler;
                context: {
                  $implicit: 'mobileIsHidden',
                  formGroup: this.formGroup
                }
              "
            ></ng-container>
          </div>
        </ng-template>

        <label tuiLabel class="property" [label]="telegramLabel">
          <ng-template #telegramHint>
            <div class="telegram-hint">
              Вы можете использовать A-Z, a-z, 0-9 и_.
              <br />
              Минимальная длина - 5 символов.
            </div>
          </ng-template>
          <tui-input
            formControlName="telegram"
            tuiTextfieldSize="m"
            [tuiHintContent]="telegramHint"
            [tuiTextfieldLabelOutside]="true"
            [tuiTextfieldCleaner]="true"
            [tuiTextfieldAutocomplete]="'off'"
            [tuiHintMode]="formGroup.controls.telegram.invalid ? 'error' : null"
          ></tui-input>
          <tui-field-error formControlName="telegram"></tui-field-error>
        </label>
        <ng-template #telegramLabel>
          <div class="property-label">
            <div class="property-label__text">Telegram</div>
            <ng-container
              *ngTemplateOutlet="
                visibilityToogler;
                context: {
                  $implicit: 'telegramIsHidden',
                  formGroup: this.formGroup
                }
              "
            ></ng-container>
          </div>
        </ng-template>
      </div>

      <div class="property-group">
        <label tuiLabel class="property" [label]="timezoneLabel">
          <tui-select
            formControlName="timezone"
            tuiTextfieldSize="m"
            [tuiTextfieldLabelOutside]="true"
            [tuiTextfieldCleaner]="true"
          >
            <tui-data-list *tuiDataList>
              <button
                *ngFor="let timezone of timezones"
                tuiOption
                [value]="timezone"
              >
                {{ timezone }}
              </button>
            </tui-data-list>
          </tui-select>
        </label>
        <ng-template #timezoneLabel>
          <div class="property-label">
            <div class="property-label__text">Часовой пояс</div>
            <ng-container
              *ngTemplateOutlet="
                visibilityToogler;
                context: {
                  $implicit: 'timezoneIsHidden',
                  formGroup: this.formGroup
                }
              "
            ></ng-container>
          </div>
        </ng-template>

        <label tuiLabel class="property" [label]="cityLabel">
          <tui-input
            formControlName="city"
            tuiTextfieldSize="m"
            [tuiTextfieldLabelOutside]="true"
            [tuiTextfieldCleaner]="true"
            [tuiTextfieldAutocomplete]="'address-level2'"
          ></tui-input>
        </label>
        <ng-template #cityLabel>
          <div class="property-label">
            <div class="property-label__text">Город присутствия</div>
            <ng-container
              *ngTemplateOutlet="
                visibilityToogler;
                context: {$implicit: 'cityIsHidden', formGroup: this.formGroup}
              "
            ></ng-container>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <div class="form-actions">
      <button tuiButton size="m" appearance="flat" (click)="cancelEdit()">
        Отмена
      </button>
      <button
        tuiButton
        size="m"
        appearance="primary"
        [disabled]="formGroup.invalid"
        (click)="save()"
      >
        Сохранить
      </button>
    </div>
  </tui-loader>
</ng-template>

<ng-template #emptyProp>
  <div class="property__value">&mdash;</div>
</ng-template>

<ng-template #visibilityToogler let-fieldName let-formGroup="formGroup">
  <div
    *tuiLet="formGroup.controls[fieldName].value as isHidden"
    class="property-label__action"
    [attr.data-checked]="isHidden"
    (click)="toogleVisibility(fieldName)"
  >
    <tui-svg [src]="isHidden ? 'tuiIconEyeClosed' : 'tuiIconEyeOpen'"></tui-svg>
  </div>
</ng-template>
