import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {EmployeeInfo} from '@app/dynamic-request/interfaces/employee-info.interface';
import {EmployeeDto} from '@app/home-api';
import {EmployeeService, RequestService} from '@app/libs/servicedesk-api';
import {UserPhotoService} from '@app/photo-api';
import {CURRENT_EMPLOYEE_INFO} from '@app/tokens';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';
import {TuiDestroyService, TuiIdentityMatcher, round} from '@pik-taiga-ui/cdk';
import {TuiAlertService, TuiDialogContext, TuiNotification} from '@pik-taiga-ui/core';
import {TuiFileLike} from '@pik-taiga-ui/kit';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
  throwError,
} from 'rxjs';
import {AttachmentService} from '@app/core/services/attachment.service';

@Component({
  selector: 'app-change-avatar-request',
  templateUrl: './change-avatar-request.component.html',
  styleUrls: ['./change-avatar-request.component.scss'],
  providers: [TuiDestroyService],
})
export class ChangeAvatarRequestComponent {
  readonly requestType = 'changephoto';
  croppedImage: SafeUrl = '';
  imageChangedEvent: any = '';

  readonly form = new FormGroup({
    manager: new FormControl(null, Validators.required),
    faceBound: new FormControl(null),
    file: new FormControl(null),
    photoFile: new FormControl(null, Validators.required),
  });

  readonly errorMessage$ = new BehaviorSubject<string>('');

  readonly searchManagers$ = new BehaviorSubject<string>('');

  readonly managers$ = this.searchManagers$.pipe(
    map(value => (typeof value === 'string' ? value.trim() : '')),
    distinctUntilChanged(),
    debounceTime(300),
    switchMap(text =>
      combineLatest([
        this.employeeInfoService
          .getAll({
            text: text ?? '',
            active: false,
            onlyUsers: false,
            isActiveUser: false,
          })
          .pipe(map(({data}) => data)),
        this.currentEmployee$,
      ]).pipe(
        map(([data, currentEmployee]) =>
          data.filter(
            employee => employee.individualGuid1C !== currentEmployee.individualGuid1C,
          ),
        ),
        startWith(null),
      ),
    ),
    startWith([]),
  );

  readonly uploading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(CURRENT_EMPLOYEE_INFO)
    readonly currentEmployee$: Observable<EmployeeDto>,
    @Inject(EmployeeService)
    private readonly employeeInfoService: EmployeeService,
    @Inject(TuiDestroyService) private readonly destroy$: TuiDestroyService,
    private readonly sanitizer: DomSanitizer,
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean, EmployeeDto>,
    @Inject(RequestService) private readonly requestService: RequestService,
    @Inject(UserPhotoService) private readonly userPhotoService: UserPhotoService,
    @Inject(AttachmentService) private readonly attachmentService: AttachmentService,
  ) {}

  onFileUpload(file: TuiFileLike, form: FormGroup): void {
    if (file) {
      this.uploading$.next(true);

      const fileExtension = file.name.split('.').pop();

      this.userPhotoService
        .avatarValidation(file as File)
        .pipe(
          distinctUntilChanged(),
          switchMap(validation => {
            if (validation.isValid) {
              this.errorMessage$.next('');
              this.uploading$.next(true);
              return this.attachmentService.saveAttachment(file as File).pipe(
                finalize(() => this.uploading$.next(false)),
                map(({data}) => {
                  this.imageChangedEvent = file as File;
                  form.controls.photoFile.setValue({
                    name: `Фото.${fileExtension}`,
                    src: data.webViewLink,
                  });
                }),
              );
            }

            if (!validation.isValid && validation.errorMessage) {
              form.controls.file.reset();
              this.errorMessage$.next(validation.errorMessage);
              this.uploading$.next(false);
            }
            return of();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  imageCropped(croppedEvent: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(croppedEvent.base64);

    const {imagePosition} = croppedEvent;

    this.form.controls.faceBound.setValue({
      x: round(imagePosition.x1),
      y: round(imagePosition.y1),
      width: round(imagePosition.x2 - imagePosition.x1 - 1),
      height: round(imagePosition.y2 - imagePosition.y1 - 1),
    });
  }

  onClose() {
    this.context.completeWith(false);
  }

  onSubmit() {
    this.uploading$.next(true);
    const fields = [];

    Object.entries(this.form.value).forEach(([code, value]: [string, any]) => {
      switch (code) {
        case 'manager':
          fields.push({
            code: code,
            value: value?.individualGuid1C,
            type: 'select',
          });
          break;

        case 'faceBound':
          fields.push({
            code: code,
            value: JSON.stringify(value),
            type: 'json',
          });
          break;

        case 'photoFile':
          fields.push({
            code: code,
            value: JSON.stringify(value),
            type: 'fileDto',
          });
          break;
      }
    });

    this.currentEmployee$
      .pipe(
        switchMap(currentEmployee => {
          return this.requestService.createRequestV2({
            fields,
            employeeId: currentEmployee.id1C,
            requestTypeCode: this.requestType,
            attachmentIds: null,
          });
        }),
        finalize(() => this.uploading$.next(false)),
        catchError((error: unknown) => {
          const httpError: HttpErrorResponse = <HttpErrorResponse>error;
          const errorMessage = httpError.error.errors
            ? Object.values(httpError.error.errors).join(', ')
            : 'Произошла непредвиденная ошибка при выполнении запроса';

          if (this.alertService) {
            this.alertService
              .open(errorMessage, {
                status: TuiNotification.Error,
                label: `Произошла ошибка [${httpError.status} ${httpError.statusText}]`,
                autoClose: 5000,
              })
              .pipe(takeUntil(this.destroy$))
              .subscribe();
          }

          return throwError(() => new Error(errorMessage));
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(request => {
        if (request) {
          this.context?.completeWith(true);
          this.alertService
            .open('Заявка успешно создана', {
              status: TuiNotification.Success,
              label: 'Готово',
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        }
      });
  }

  stringifyByFullName = (item: EmployeeInfo) => item?.fullName || '';

  autocompleteMatcherById: TuiIdentityMatcher<unknown & {id: number}> = (item1, item2) =>
    item1.id === item2.id;

  onSearchValueChange = (searchQuery: string, searchSubject: BehaviorSubject<string>) =>
    searchSubject.next(searchQuery);
}
