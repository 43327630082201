<tui-tabs [(activeItemIndex)]="activeItemIndex">
  <button tuiTab>Изменить миниатюру</button>
  <button tuiTab>Обновить фотографию</button>
</tui-tabs>

<tui-loader [showLoader]="uploading$ | async" [overlay]="true">
  <ng-container *ngIf="activeItemIndex === 0">
    <image-cropper
      format="jpeg"
      class="cropper"
      [imageURL]="employee.individualGuid1C | employeeGuidToAvatarUrl: null"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      [cropperMinWidth]="200"
      [cropperMinHeight]="200"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>

    <div class="previews">
      <div class="preview preview--xl">
        <tui-avatar
          size="xl"
          [rounded]="true"
          [avatarUrl]="croppedImage"
          [autoColor]="true"
          [text]="employee.name"
        ></tui-avatar>
        <div class="preview__text">
          <div class="preview__title">{{ employee.name }}</div>
          <div *ngIf="employee.position" class="preview__prop">
            {{ employee.position }}
          </div>
          <div *ngIf="employee.company" class="preview__prop">
            {{ employee.company }}
          </div>
          <div *ngIf="employee.unitName" class="preview__prop">
            {{ employee.unitName }}
          </div>
        </div>
      </div>
      <div class="preview preview--m">
        <tui-avatar
          size="m"
          [rounded]="true"
          [avatarUrl]="croppedImage"
          [autoColor]="true"
          [text]="employee.name"
        ></tui-avatar>
        <div class="preview__text">
          <div class="preview__title">{{ employee.name }}</div>
          <div *ngIf="employee.position" class="preview__prop">
            {{ employee.position }}
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <button tuiButton appearance="flat" size="m" (click)="dismiss()">
        Отмена
      </button>
      <button tuiButton appearance="primary" size="m" (click)="save()">
        Сохранить
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="activeItemIndex === 1">
    <app-change-avatar-request></app-change-avatar-request>
  </ng-container>
</tui-loader>
