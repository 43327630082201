import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  TuiContextWithImplicit,
  TuiDay,
  TuiDestroyService,
  TuiStringHandler,
} from '@pik-taiga-ui/cdk';
import {humanizeDateRange} from '@utils';
import {BehaviorSubject, filter, map, merge} from 'rxjs';
import {scan, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {WorkingStatusDto, WorkingStatusService} from 'src/app/home-api';
import {tuiDayToIso} from 'src/app/utils';

type StatusType =
  | 'SickLeave'
  | 'RemoteWork'
  | 'Errand'
  | 'Vacation'
  | 'Vacationv2'
  | 'DaysOff'
  | 'Birthday';

const STATUS_TYPE_TITLE = {
  RemoteWork: '🌍 Работаю удалённо',
  SickLeave: '🤒 На больничном',
  Errand: '💼 В командировке',
  Vacation: '🏝️ В отпуске',
  Vacationv2: '🏝️ В отпуске',
  DaysOff: 'Отгул',
  Birthday: '🎁 День рождения',
};

interface WorkingStatusFormValues {
  workingStatusCode: StatusType;
  dateStart: TuiDay;
  dateEnd: TuiDay;
  comment: string;
}

interface ExtraWorkingStatus extends WorkingStatusDto {
  humanizedInterval: string;
  humanizedTitle: string;
}

interface StateAction {
  type: 'fetch' | 'fetched' | 'insert' | 'update' | 'remove';
  payload?: WorkingStatusDto | WorkingStatusDto[] | number;
}

/**
 * Компонент виджета текущих "рабочих" статусов пользователя
 *
 * @todo обработка ошибок при сохранении статуса
 * @todo моргает белой пустотой между скелетоном и отрисовкой пришедших данных
 */
@Component({
  selector: 'employee-working-status',
  templateUrl: './working-status.template.html',
  styleUrls: ['./working-status.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class EmployeeWorkingStatusComponent {
  private readonly actions$ = new BehaviorSubject<StateAction>({type: 'fetch'});
  private readonly fetchData$ = this.actions$.pipe(
    filter(({type}) => type === 'fetch'),
    switchMap(_ =>
      this.workingStatusService.getAll().pipe(
        map(res => {
          return <StateAction>{
            type: 'fetched',
            payload: res ? res.data : [],
          };
        }),
      ),
    ),
  );

  sickLeaveAlreadyExists = false;

  /** Редьюсер */
  readonly statuses$ = merge(this.actions$, this.fetchData$).pipe(
    scan((statuses: readonly ExtraWorkingStatus[] | null, action) => {
      switch (action.type) {
        case 'fetch':
          return null;
        case 'fetched': {
          const rawStatuses = action.payload as WorkingStatusDto[];

          return rawStatuses.map(rawStatus => {
            if (rawStatus.workingStatus.code === 'SickLeave') {
              this.sickLeaveAlreadyExists = true;
            }

            return this.humanizeStatus(rawStatus);
          });
        }
        case 'update': {
          const rawStatus = action.payload as WorkingStatusDto;

          return statuses.map(status =>
            status.id === rawStatus.id ? this.humanizeStatus(rawStatus) : status,
          );
        }
        case 'insert': {
          const rawStatus = action.payload as WorkingStatusDto;

          return [...statuses, this.humanizeStatus(rawStatus)];
        }

        case 'remove': {
          const id = action.payload as number;

          return statuses.filter(status => status.id !== id);
        }
        default:
          return statuses;
      }
    }, null),
    startWith(null),
  );

  readonly activeFormId$ = new BehaviorSubject<number>(null);
  readonly formsMap = new Map<number, FormGroup>();

  /** Запрет изменять задним числом */
  readonly minDate = TuiDay.fromLocalNativeDate(new Date());
  /** Горизонт планирования статуса в два месяца */
  readonly maxDate = this.minDate.append({month: 2});

  constructor(
    @Inject(WorkingStatusService)
    private readonly workingStatusService: WorkingStatusService,
    @Inject(TuiDestroyService) private readonly destroy$: TuiDestroyService,
  ) {}

  stringifyStatusType(): TuiStringHandler<TuiContextWithImplicit<StatusType>> {
    const statusMap = new Map([
      ['SickLeave', 'Больничный'],
      ['RemoteWork', 'Удалённая работа'],
    ]);

    return ({$implicit}: TuiContextWithImplicit<StatusType>) => statusMap.get($implicit);
  }

  showForm(status?: WorkingStatusDto) {
    const id = status ? status.id : -1;

    this.formsMap.set(id, this.createStatusFormGroup(status));
    this.activeFormId$.next(id);
  }

  closeForm(statusId: number) {
    this.formsMap.delete(statusId);
    this.activeFormId$.next(null);
  }

  removeStatus(id: number) {
    this.workingStatusService
      .deleteById(id)
      .pipe(
        tap(res => {
          if (res && res.data) {
            this.actions$.next({type: 'remove', payload: id});
            this.closeForm(id);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  saveForm(id: number, formValues: WorkingStatusFormValues, formDirty = false) {
    const body = {
      ...formValues,
      dateStart: formValues.dateStart ? tuiDayToIso(formValues.dateStart) : null,
      dateEnd: formValues.dateEnd ? tuiDayToIso(formValues.dateEnd) : null,
    };

    if (id === -1) {
      this.workingStatusService
        .create(body)
        .pipe(
          tap(res => {
            if (res && res.data) {
              this.actions$.next({type: 'insert', payload: res.data});
              this.closeForm(id);
            }
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    } else if (formDirty) {
      this.workingStatusService
        .updateById(id, body)
        .pipe(
          tap(res => {
            if (res && res.data) {
              this.actions$.next({type: 'update', payload: res.data});
              this.closeForm(id);
            }
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    } else {
      this.closeForm(id);
    }
  }

  private createStatusFormGroup(status?: WorkingStatusDto): FormGroup {
    const formGroup = new FormGroup({
      workingStatusCode: new FormControl('RemoteWork', Validators.required),
      dateStart: new FormControl(TuiDay.fromLocalNativeDate(new Date())),
      dateEnd: new FormControl(null),
      comment: new FormControl(null),
    });

    if (status) {
      formGroup.controls.workingStatusCode.disable();

      formGroup.setValue({
        workingStatusCode: status.workingStatus.code,
        dateStart: TuiDay.fromLocalNativeDate(new Date(status.dateStart)),
        dateEnd: status.dateEnd
          ? TuiDay.fromLocalNativeDate(new Date(status.dateEnd))
          : null,
        comment: status.comment,
      });
    }

    return formGroup;
  }

  private humanizeStatus(rawStatus: WorkingStatusDto): ExtraWorkingStatus {
    const himanized = humanizeDateRange(rawStatus.dateStart, rawStatus.dateEnd);

    return {
      ...rawStatus,
      humanizedInterval: himanized[0].toUpperCase() + himanized.substring(1),
      humanizedTitle: STATUS_TYPE_TITLE[rawStatus.workingStatus.code] || '',
    };
  }
}
