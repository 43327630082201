<div class="title">Заявка на выпуск</div>

<tui-loader [showLoader]="loading$ | async" [overlay]="true">
  <form class="form" [formGroup]="form">
    <label tuiLabel label="Мобильный телефон">
      <tui-input-phone
        formControlName="mobilePhone"
        tuiTextfieldSize="m"
        [tuiTextfieldCleaner]="true"
        [tuiTextfieldLabelOutside]="true"
      ></tui-input-phone>
      <tui-field-error formControlName="mobilePhone"></tui-field-error>
    </label>

    <label tuiLabel label="Тип документа">
      <tui-select
        formControlName="documentType"
        tuiTextfieldSize="m"
        [tuiTextfieldLabelOutside]="true"
        [identityMatcher]="identityDocTypeMatcher"
      >
        <tui-data-list *tuiDataList>
          <button *ngFor="let type of documentTypes" tuiOption [value]="type">
            {{ type.name }}
          </button>
        </tui-data-list>
      </tui-select>
    </label>

    <div class="field-group">
      <label tuiLabel label="Серия">
        <tui-input
          formControlName="series"
          tuiTextfieldSize="m"
          class="border-radius-fix-left"
          [textMask]="
            form.controls.documentType.value.type === 'passport'
              ? ruPassportSeriesTextMask
              : numbersTextMask
          "
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
        <tui-field-error formControlName="series"></tui-field-error>
      </label>

      <label tuiLabel label="Номер">
        <tui-input
          formControlName="number"
          tuiTextfieldSize="m"
          class="border-radius-fix-right"
          [textMask]="
            form.controls.documentType.value.type === 'passport'
              ? ruPassportNumberTextMask
              : alphabetAndNumbersTextMask
          "
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
        <tui-field-error formControlName="number"></tui-field-error>
      </label>
    </div>

    <label
      *ngIf="form.controls.issueOrganization.enabled"
      tuiLabel
      label="Кем выдан"
    >
      <tui-input
        formControlName="issueOrganization"
        tuiTextfieldSize="m"
        [tuiTextfieldCleaner]="true"
        [tuiTextfieldLabelOutside]="true"
      ></tui-input>
      <tui-field-error formControlName="issueOrganization"></tui-field-error>
    </label>

    <div class="field-group">
      <label
        *ngIf="form.controls.issueOrganizationId.enabled"
        tuiLabel
        label="Код подразделения"
      >
        <tui-input
          formControlName="issueOrganizationId"
          tuiTextfieldSize="m"
          class="border-radius-fix-left"
          [textMask]="issueOrganizationIdTextMask"
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input>
        <tui-field-error
          formControlName="issueOrganizationId"
        ></tui-field-error>
      </label>

      <label tuiLabel label="Дата выдачи">
        <tui-input-date
          formControlName="issueDate"
          tuiTextfieldSize="m"
          class="{{
            form.controls.documentValidTo.enabled
              ? 'border-radius-fix-left'
              : 'border-radius-fix-right'
          }}"
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
          [max]="today"
        ></tui-input-date>
        <tui-field-error formControlName="issueDate"></tui-field-error>
      </label>

      <label
        *ngIf="form.controls.documentValidTo.enabled"
        tuiLabel
        label="Дата окончания (если есть)"
      >
        <tui-input-date
          formControlName="documentValidTo"
          tuiTextfieldSize="m"
          class="border-radius-fix-right"
          [min]="today"
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
        ></tui-input-date>
      </label>
    </div>

    <label tuiLabel label="ИНН">
      <tui-input
        formControlName="inn"
        tuiTextfieldSize="m"
        [textMask]="innTextMask"
        [tuiTextfieldCleaner]="true"
        [tuiTextfieldLabelOutside]="true"
      ></tui-input>
      <tui-field-error formControlName="inn"></tui-field-error>
    </label>

    <label
      tuiLabel
      label="Подтверждающий документ (pdf, png или jpeg не больше 10Мб)"
    >
      <tui-input-file
        accept="image/jpeg, image/png, application/pdf"
        size="m"
        [formControl]="fileUploadControl"
        [maxFileSize]="10000000"
        [loadingFiles]="(loadingFiles$ | async) || []"
      ></tui-input-file>
    </label>

    <tui-checkbox-block
      formControlName="agreement"
      contentAlign="left"
      size="l"
    >
      <div class="checkbox-inner">
        Я&nbsp;согласен с&nbsp;выпуском неквалифицированной электронной подписи
        для целей реализации положения о&nbsp;кадровом электронном
        документообороте, утвержденном в&nbsp;ПАО &laquo;ПИК СЗ&raquo;. Даю
        согласие на&nbsp;передачу передачу данных в АО&nbsp;ПФ&nbsp;СКБ Контур
        для целей оформления неквалифицированной электронной подписи.
      </div>
    </tui-checkbox-block>

    <div class="actions">
      <button
        tuiButton
        appearance="primary"
        size="m"
        [disabled]="form.invalid"
        (click)="sendData()"
      >
        Отправить заявку
      </button>
    </div>
  </form>
</tui-loader>
