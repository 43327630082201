import {Inject, Injectable} from '@angular/core';
import {errorNotification} from '@core/observables';
import {TuiNotificationsService} from '@pik-taiga-ui/core';
import {map, merge, Subject} from 'rxjs';
import {scan, shareReplay, startWith, tap} from 'rxjs/operators';

import {ManagerService, SubstitutionDto} from '../home-api';

interface SubstituionsActionInit {
  type: 'init';
  payload: SubstitutionDto[];
}

interface SubstituionsActionAppend {
  type: 'append';
  payload: SubstitutionDto[];
}

interface SubstituionsActionDelete {
  type: 'delete';
  payload: number[];
}

type SubstituionsAction =
  | SubstituionsActionAppend
  | SubstituionsActionDelete
  | SubstituionsActionInit;

@Injectable({providedIn: 'root'})
export class SubstitutionsService {
  private readonly substituionsAppended$ = new Subject<readonly SubstitutionDto[]>();
  private readonly substituionsIdsDeleted$ = new Subject<readonly number[]>();
  private readonly allSubstituions$ = this.managerService.getSubstitutions().pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly allSubstituionsData$ = merge(
    this.allSubstituions$.pipe(
      map(res => (res.data === null ? null : res.data)),
      startWith(null),
      map(items => ({type: 'init', payload: items})),
    ),
    this.substituionsAppended$.pipe(map(items => ({type: 'append', payload: items}))),
    this.substituionsIdsDeleted$.pipe(map(ids => ({type: 'delete', payload: ids}))),
  ).pipe(
    scan((substitutions, action: SubstituionsAction) => {
      let newState: SubstitutionDto[] = null;

      switch (action.type) {
        case 'init':
          newState = action.payload;
          break;

        case 'append':
          newState = [...substitutions, ...action.payload];
          break;

        case 'delete':
          newState = substitutions.filter(item => action.payload.indexOf(item.id) < 0);
          break;

        default:
          break;
      }

      return newState;
    }, null as SubstitutionDto[]),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly allSubstituionsCount$ = this.allSubstituionsData$.pipe(
    map(substituions => (substituions ? substituions.length : 0)),
  );

  constructor(
    private readonly managerService: ManagerService,
    @Inject(TuiNotificationsService)
    private readonly notificationsService: TuiNotificationsService,
  ) {}

  createSubstituion(userIds: number[], dateStart: string, dateEnd?: string) {
    return this.managerService
      .createSubstitutions({
        dateStart,
        dateEnd,
        substitutiveUsersId: userIds,
      })
      .pipe(
        tap(res => {
          if (res && res.data && res.data.length > 0) {
            this.substituionsAppended$.next([...res.data]);
          }
        }),
        errorNotification(this.notificationsService),
      );
  }

  removeSubstituion(id: number) {
    return this.managerService.deleteSubstitutions(id).pipe(
      map(res => res !== null && res.status === 200),
      tap(isDeleted => {
        if (isDeleted) {
          this.substituionsIdsDeleted$.next([id]);
        }
      }),
      errorNotification(this.notificationsService),
    );
  }
}
