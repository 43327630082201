<tui-root class="root">
  <div *ngIf="isAuthenticated$ | async" class="main-content">
    <div class="header">
      <app-header class="pik-header main-nav"></app-header>
      <div
        class="secondary-nav project-header"
        [class.project-header--with-more]="!isMobile"
      >
        <div class="project-header__logo">
          <div class="project-name">Личный кабинет</div>
          <substitutions-opener
            *ngIf="isManager$ | async"
            class="project-action"
          ></substitutions-opener>
        </div>

        <ng-container
          *ngTemplateOutlet="isMobile ? navTabs : navTabsWithMore"
        ></ng-container>
      </div>
    </div>
    <tui-scrollbar class="module-container">
      <div class="module-content">
        <router-outlet></router-outlet>
      </div>
    </tui-scrollbar>
  </div>

  <tui-sheets-host ngProjectAs="tuiOverContent"></tui-sheets-host>
</tui-root>

<ng-template #navTabsWithMore>
  <nav
    *tuiLet="isManager$ | async as isManager"
    tuiTabsWithMore
    class="project-nav project-nav--with-more"
  >
    <a
      *tuiTab
      tuiTab
      routerLink="/"
      routerLinkActive
      [routerLinkActiveOptions]="{exact: true}"
    >
      Профиль
    </a>

    <ng-container *ngIf="isManager">
      <a *tuiTab tuiTab routerLink="/requests" routerLinkActive>Заявки</a>
    </ng-container>

    <ng-container *tuiLet="hasPermission$ | async as hasPermission">
      <ng-container *ngIf="hasPermission">
        <a *tuiTab tuiTab routerLink="/structure" routerLinkActive>Структура</a>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!isProd">
      <a *tuiTab tuiTab routerLink="/staffing" routerLinkActive>
        Штатное расписание
      </a>
    </ng-container>
    <ng-container *ngIf="canApproveVacationRequests$ | async">
      <a *tuiTab tuiTab routerLink="/vacations" routerLinkActive>
        Согласование отпусков
      </a>
    </ng-container>
    <a
      *tuiTab
      tuiTab
      target="_blank"
      [href]="linkToHome + '/motivation/metrics'"
    >
      Мотивация
    </a>
  </nav>
</ng-template>

<ng-template #navTabs>
  <nav *tuiLet="isManager$ | async as manager" tuiTabs class="project-nav">
    <a
      tuiTab
      routerLink="/"
      routerLinkActive
      [routerLinkActiveOptions]="{exact: true}"
    >
      Профиль
    </a>
    <a *ngIf="manager" tuiTab routerLink="/requests" routerLinkActive>Заявки</a>

    <ng-container *tuiLet="hasPermission$ | async as hasPermission">
      <ng-container *ngIf="hasPermission">
        <a *tuiTab tuiTab routerLink="/structure" routerLinkActive>Структура</a>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!isProd">
      <a tuiTab routerLink="/staffing" routerLinkActive>Штатное расписание</a>
    </ng-container>

    <a tuiTab target="_blank" [href]="linkToHome + '/motivation/metrics'">
      Мотивация
    </a>
  </nav>
</ng-template>
