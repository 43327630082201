import {ChangeDetectionStrategy, Component, Inject, Injector, Input} from '@angular/core';
import {Router} from '@angular/router';

import {RequestApproverService, RequestService} from '@app/libs/servicedesk-api';
import {DynamicRequestService} from '@app/libs/servicedesk-api/services/dynamic-request.service';

import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';
import {BehaviorSubject, combineLatest, merge, Observable, of, Subject} from 'rxjs';
import {filter, map, scan, startWith, switchMap, tap} from 'rxjs/operators';

import {
  approverGroupsAdapter,
  convertFullRequestDto,
  filterFieldsByType,
} from '../../adapters';
import {getRequestCopyUrl} from '../../adapters/request-url.adapter';
import {ConfirmDialogComponent} from '../../dialogs';
import {USER_IS_ADMIN, USER_IS_HR} from '../../tokens';
import {RequestChangesService} from './changes.service';
import {SDFullRequest} from './full-request.model';
import {TuiDialogService} from '@pik-taiga-ui/core';
import {CURRENT_EMPLOYEE} from '@app/home-api';
import {EmployeeInfo} from '@app/dynamic-request/interfaces/employee-info.interface';

const NONCANCELABLE_STATUSES_IN_TYPES: {[typeCode: string]: string[]} = {
  errandv2: ['Done', 'RejectedByManager'],
  infoaccessv2: ['Done', 'RejectedByManager'],
  firing: ['Done', 'OnWithdrawRequestSigning', 'WithdrawInProcess'],
  weekend: [
    'Done',
    'RejectedByEmployee',
    'RejectedByCnB',
    'OnWithdrawRequestSigning',
    'WithdrawInProcess',
  ],
  employeereception: ['Done', 'Created'],
  plannedvacation: ['Rejected', 'Withdraw'],
};

/**
 * Необходимо перепроверить необходимость получать вложенные файлы
 * и на других заявках, ибо vacation проверяется только для старой версии
 */
const TYPES_WITH_ATTACHMENTS = [
  'vacation',
  'singlenonpaymentchildbirthdocument',
  'monthnonpaymentchildbirthdocument',
  'personaldata',
  'recruitmentv2',
  'singlenonpaymentchildbirthdocumentv2',
  'monthnonpaymentchildbirthdocumentv2',
  'personaldatav2',
];

enum TypeByUser {
  auditor = 1,
  employee,
  individual,
  contractor = 5,
}

@Component({
  selector: 'sd-request-view',
  templateUrl: './request-view.template.html',
  styleUrls: ['./request-view.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicedeskRequestViewComponent {
  private _request: SDFullRequest;

  /**
   * Костыль для отображения заявки на доступ
   */
  readonly typeByUser = TypeByUser;
  readonly request$ = new BehaviorSubject<SDFullRequest>(null);

  readonly isAuthor$ = combineLatest([this.employee$, this.request$]).pipe(
    map(([currentEmployee, request]) => {
      return (
        request.authorId === currentEmployee.employeeGuid1C ||
        request.authorId === currentEmployee.id1C
      );
    }),
  );

  readonly youIsEmployee$ = combineLatest([this.employee$, this.request$]).pipe(
    map(
      ([currentEmployee, request]) =>
        request.employee.employeeGuid1C === currentEmployee.employeeGuid1C ||
        request.employee.employeeGuid1C === currentEmployee.id1C,
    ),
  );
  /**
   * Для проверки на наличие прав для кнопок используется
   * сверка принадлежит ли заявка текущему пользователю или
   * пользователь админ сервиса или сотрудник HR департамента.
   *
   * Но фактически, если пользователь видит заявку, то он уже либо
   * её автор, либо администратор сервиса или HR сотрудник.
   * Получается что должно быть достаточно проверки на статусы
   *
   * @todo Добавить в мерж this.isAdmin$, на текущий момент бэк проверяет только
   * isHR, админы не имеют возможности отменять заявки
   */

  readonly hasCancelRights$ = merge(this.isAuthor$, this.isHr$, this.youIsEmployee$).pipe(
    scan((acc, adminRights) => acc || adminRights, false),
  );

  readonly canCancel$ = this.request$.pipe(
    map(request => {
      return !this.isNonCancelableByTypeAndStatus(request.typeCode, request.statusCode);
    }),
  );

  /**
   * Проверка на доступность кнопки создания заявки ещё раз
   *
   * Смысл такой проверки, конкретно мне(@bystrovaiu) не ясен. А именно
   * зачем блокировать копирование заявок
   */
  readonly canCopy$ = this.request$.pipe(
    map(({typeCode, statusCode}) => {
      return (
        [
          `errand`,
          `errandv2`,
          `infoaccessv2`,
          `massrecruitment`,
          `recruitment`,
          `recruitmentv2`,
          `promotion`,
          `releaseemployees`,
        ].findIndex(_typeCode => typeCode === _typeCode) > -1 ||
        (typeCode === 'vacationv2' && statusCode === 'Rejected')
      );
    }),
  );

  readonly approveSteps$ = this.request$.pipe(
    filter(request => request && request.statusCode !== 'New'),
    map(request => request?.requestId),
    switchMap(id => {
      return id
        ? this.requestApproverService.getByRequestId(id).pipe(
            map(res => {
              if (res && res.data) {
                return res.data;
              }

              return [];
            }),
            startWith(null),
          )
        : of([]);
    }),
    map(groups =>
      groups ? approverGroupsAdapter(groups, this._request.typeCode) : groups,
    ),
  );

  readonly visibleFields$ = this.request$.pipe(
    map(request =>
      (filterFieldsByType(request) || [])
        .filter(field => field.visible)
        .sort((a, b) => a.order - b.order),
    ),
  );

  readonly attachments$ = this.request$.pipe(
    switchMap(({requestId, typeCode}) => {
      if (TYPES_WITH_ATTACHMENTS.findIndex(_typeCode => _typeCode === typeCode) > -1) {
        return this.requestService.getAttachmentsById(requestId).pipe(
          map(res => (res && res.data ? res.data : [])),
          startWith(null),
        );
      }

      return of([]);
    }),
    startWith([]),
  );

  readonly dueDate$ = this.request$.pipe(
    map(({typeCode, statusId, fields}) => {
      if (typeCode === 'vacationv2' && [1, 2].includes(statusId)) {
        const field = fields.find(({code}) => code === 'dueDate');

        return field ? field.value : null;
      }

      return null;
    }),
  );

  @Input() set request(request: SDFullRequest) {
    this._request = request;
    this.request$.next(request);
  }

  constructor(
    @Inject(RequestApproverService)
    private readonly requestApproverService: RequestApproverService,
    @Inject(RequestService)
    private readonly requestService: RequestService,
    @Inject(DynamicRequestService)
    private readonly dynamicRequestService: DynamicRequestService,
    @Inject(Router)
    private readonly router: Router,
    @Inject(CURRENT_EMPLOYEE) private readonly employee$: Observable<EmployeeInfo | null>,
    @Inject(USER_IS_ADMIN) private readonly isAdmin$: Observable<boolean>,
    @Inject(USER_IS_HR) private readonly isHr$: Observable<boolean>,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(RequestChangesService)
    private readonly requestChanges$: Subject<SDFullRequest>,
  ) {}

  copyRequest() {
    const requestCode = this._request.typeCode;

    const queryParams = getRequestCopyUrl(this._request);

    this.router.navigate([`/new/${requestCode}`], {queryParams});

    this.requestChanges$.next({
      ...this._request,
      statusColorCode: 'warning',
    });
  }

  cancelRequest() {
    const component = new PolymorpheusComponent(ConfirmDialogComponent, this.injector);

    this.dialogService
      .open<boolean>(component, {
        data: {question: 'Вы уверены что хотите отозвать заявку?'},
      })
      .pipe(
        switchMap(confirmed => {
          if (confirmed) {
            return this.requestService.cancelById(this._request.requestId).pipe(
              map(res => (res && res.data ? convertFullRequestDto(res.data) : null)),
              tap(
                (request: SDFullRequest) =>
                  request && this.dynamicRequestService.lastWithdrawn$.next(request),
              ),
            );
          }

          return of(null);
        }),
      )
      .subscribe(newRequestState => {
        if (newRequestState) {
          this.request = newRequestState;
          this.requestChanges$.next(newRequestState);
        }
      });
  }

  /**
   * Метод возращает результат проверки на возможность
   * отмены заявки по базовым статусам если для неё не назначены
   * специальные правила
   */
  private isNonCancelableByStatus(statusCode: string, typeCode: string) {
    switch (typeCode) {
      case 'plannedvacation':
        return (
          statusCode === 'Rejected' ||
          statusCode === 'OnApproval' ||
          statusCode === 'Withdraw'
        );
      default:
        return (
          statusCode === 'Rejected' ||
          statusCode === 'Done' ||
          statusCode === 'Withdraw' ||
          statusCode === 'Expired'
        );
    }
  }

  /**
   * Метод возращает результат проверки на возможность
   * отмены заявки по статусам в конкретных типах заявок
   */
  private isNonCancelableByTypeAndStatus(typeCode: string, statusCode: string) {
    if (NONCANCELABLE_STATUSES_IN_TYPES[typeCode] !== undefined) {
      return (
        NONCANCELABLE_STATUSES_IN_TYPES[typeCode].findIndex(
          _statusCode => _statusCode === statusCode,
        ) > -1 || this.isNonCancelableByStatus(statusCode, typeCode)
      );
    }

    return this.isNonCancelableByStatus(statusCode, typeCode);
  }
}
