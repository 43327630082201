import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {ApiResponse, SipPhoneDto} from '../interfaces';
import {HOME_API_URL} from '../tokens';

@Injectable({providedIn: 'root'})
export class SipPhoneService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getCurrent() {
    return this.http.get<ApiResponse<SipPhoneDto | null>>(
      `${this.apiUrl}/v1/SIPPhone/currentUser`,
    );
  }
}
