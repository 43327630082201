import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import type {Routes} from '@angular/router';
import {RouterModule} from '@angular/router';
import {TuiDestroyService, TuiLetModule} from '@pik-taiga-ui/cdk';
import {TuiButtonModule, TuiNotificationModule} from '@pik-taiga-ui/core';
import type {Observable} from 'rxjs';
import {map, shareReplay, startWith, switchMap, takeUntil} from 'rxjs/operators';

import type {EmployeeDto} from '@home-api';
import {
  AttributeService,
  CURRENT_EMPLOYEE,
  CURRENT_EMPLOYEES,
  EmployeeService,
  IndividualInfoService,
} from '@home-api';
import {EmployeeComponent} from './employee.component';
import {ATTRIBUTES_LIST, ATTRIBUTES_VALUES_LIST} from './tokens';
import {
  EmployeeBudgetModule,
  EmployeeDevicesModule,
  EmployeeDocumentsModule,
  EmployeeExtraCovidModule,
  EmployeeExtraModule,
  EmployeeExtraVaccineModule,
  EmployeeFavoritesModule,
  EmployeeIndividualModule,
  EmployeeIpPhoneModule,
  EmployeeSDRequestsModule,
  EmployeeSignatureModule,
  EmployeeWorkingStatusModule,
  EXTRA_ATTRIBUTES_LIST,
  EXTRA_ATTRIBUTES_VALUES_LIST,
  EXTRA_COVID_ATTRIBUTES_LIST,
  EXTRA_COVID_ATTRIBUTES_VALUES_LIST,
} from './widgets';
import {EmployeeLearningModule} from './widgets/learning/learning.module';
import {HobbiesModule} from './widgets/hobbies';
import {ElectronicDocumentManagementComponent} from './widgets/electronic-document-management/electronic-document-management.component';
import {EmployeeInfo} from '@app/dynamic-request/interfaces/employee-info.interface';

const routes: Routes = [{path: '', pathMatch: 'full', component: EmployeeComponent}];

const currentEmployeeFactory = (employeeService: EmployeeService) =>
  employeeService.current$;

const currentEmployeesFactory = (employeeService: EmployeeService) =>
  employeeService.currentEmployees$;
const allAttributesFactory = (
  destroy$: TuiDestroyService,
  attributeService: AttributeService,
) => {
  return attributeService.getAll().pipe(
    map(({data}) => data || []),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
    takeUntil(destroy$),
  );
};

const attributesValuesFactory = (
  destroy$: TuiDestroyService,
  currentEmployee$: Observable<EmployeeDto>,
  individualInfoService: IndividualInfoService,
) => {
  return currentEmployee$.pipe(
    switchMap(({individualId}) => individualInfoService.getByIndividualId(individualId)),
    startWith(null),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
    takeUntil(destroy$),
  );
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    // Модули блоков
    EmployeeBudgetModule,
    EmployeeDevicesModule,
    EmployeeDocumentsModule,
    EmployeeExtraCovidModule,
    EmployeeExtraVaccineModule,
    EmployeeExtraModule,
    EmployeeFavoritesModule,
    EmployeeIndividualModule,
    EmployeeIpPhoneModule,
    EmployeeSDRequestsModule,
    EmployeeSignatureModule,
    EmployeeWorkingStatusModule,
    EmployeeLearningModule,
    HobbiesModule,
    TuiNotificationModule,
    TuiLetModule,
    TuiButtonModule,
  ],
  exports: [EmployeeComponent],
  declarations: [EmployeeComponent, ElectronicDocumentManagementComponent],
  providers: [
    TuiDestroyService,

    {
      provide: CURRENT_EMPLOYEE,
      useFactory: currentEmployeeFactory,
      deps: [EmployeeService],
    },
    {
      provide: CURRENT_EMPLOYEES,
      useFactory: currentEmployeesFactory,
      deps: [EmployeeService],
    },

    {
      provide: ATTRIBUTES_LIST,
      useFactory: allAttributesFactory,
      deps: [TuiDestroyService, AttributeService],
    },
    {
      provide: ATTRIBUTES_VALUES_LIST,
      useFactory: attributesValuesFactory,
      deps: [TuiDestroyService, CURRENT_EMPLOYEE, IndividualInfoService],
    },
    {
      provide: EXTRA_ATTRIBUTES_LIST,
      useExisting: ATTRIBUTES_LIST,
    },
    {
      provide: EXTRA_COVID_ATTRIBUTES_LIST,
      useExisting: ATTRIBUTES_LIST,
    },
    {
      provide: EXTRA_ATTRIBUTES_VALUES_LIST,
      useExisting: ATTRIBUTES_VALUES_LIST,
    },
    {
      provide: EXTRA_COVID_ATTRIBUTES_VALUES_LIST,
      useExisting: ATTRIBUTES_VALUES_LIST,
    },
  ],
})
export class EmployeeModule {}
