import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EmployeeGuidToAvatarUrlPipeModule} from '@kit';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiLabelModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiSvgModule,
} from '@pik-taiga-ui/core';
import {
  TuiAvatarModule,
  TuiComboBoxModule,
  TuiFilesModule,
  TuiInputFilesModule,
  TuiTabsModule,
} from '@pik-taiga-ui/kit';

import {EmployeeIndividualComponent} from './individual.component';

@NgModule({
  imports: [
    CommonModule,
    EmployeeGuidToAvatarUrlPipeModule,

    TuiAvatarModule,
    TuiSvgModule,
    TuiAvatarModule,
    TuiLinkModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiTabsModule,
    TuiFilesModule,
    TuiInputFilesModule,
    TuiComboBoxModule,
    TuiDropdownModule,
    TuiLabelModule,
    TuiDataListModule,
  ],
  exports: [EmployeeIndividualComponent],
  declarations: [EmployeeIndividualComponent],
  providers: [],
})
export class EmployeeIndividualModule {}
