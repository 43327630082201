<div class="alert"></div>
<div class="header">
  <div class="header__title">Сертификат НЭП</div>
</div>
<div class="content">
  <!-- <p>
    Что такое НЭП и&nbsp;зачем он&nbsp;нужен можно узнать&nbsp;по
    <a
      href="https://docs.google.com/presentation/d/1d7avuMbJ1xHvLQNQkd53UMoV4kN_GagcyAMxIHMl1aw/edit?usp=sharing"
      target="_blank"
      tuiLink
    >
      этой ссылке
    </a>
  </p> -->

  <div *tuiLet="status$ | async as status" class="sign">
    <ng-container *ngIf="status" [ngSwitch]="status.stage">
      <employee-signature-form
        *ngSwitchCase="'notExists'"
        (formSended)="onFormSended()"
      ></employee-signature-form>
      <ng-container *ngSwitchCase="'needSMS'">
        <p>
          Вы&nbsp;отправили запрос на&nbsp;оформление неквалифицированной
          электронной подписи.
        </p>

        <p>
          Для подтверждения отправки формы, введите код из&nbsp;SMS,
          отправленный на&nbsp;номер
          <b class="nowrap">{{ status.mobilePhone | tuiFormatPhone }}</b>
        </p>

        <p *tuiLet="removingUnfinishedNep$ | async" class="retry">
          Ошибка в&nbsp;данных?
          <button
            tuiButton
            size="s"
            appearance="flat"
            (click)="onReissueNep$.next()"
          >
            Заполнить заявку заново
          </button>
        </p>

        <tui-loader [showLoader]="smsCodeChecking$ | async" [overlay]="true">
          <div tuiGroup class="sms-code-field">
            <tui-input
              #smsCodeModel="ngModel"
              tuiTextfieldSize="m"
              pattern="\d{6}"
              required
              [tuiTextfieldLabelOutside]="true"
              [textMask]="smsCodeTextMask"
              [(ngModel)]="smsCode"
            ></tui-input>
            <button
              tuiButton
              size="m"
              [disabled]="smsCodeModel.invalid"
              (click)="sendSMSCode()"
            >
              Подтвердить
            </button>
          </div>

          <div class="actions">
            <button
              *tuiLet="timer$ | async as timer"
              tuiButton
              size="s"
              appearance="flat"
              [disabled]="timer > 0"
              (click)="recieveSMSCode()"
            >
              Получить SMS повторно
              <ng-container *ngIf="timer > 0">({{ timer }})</ng-container>
            </button>
          </div>
        </tui-loader>
      </ng-container>
      <ng-container *ngSwitchCase="'waiting'">
        Ваш запрос выполнен успешно. Дождитесь выпуска НЭП.
      </ng-container>
      <ng-container *ngSwitchCase="'active'">
        <div class="sign">
          <div class="sign__info">
            <div class="sign__info--column">
              <div class="sign__expired">
                Активен до {{ status.validTo | date }}
              </div>
              <div class="sign__company">{{ status.companyName }}</div>
              <div class="sign__phone">
                {{ status.mobilePhone | tuiFormatPhone }}
              </div>
            </div>

            <a
              tuiIconButton
              size="s"
              icon="tuiIconSettings"
              href="https://service.pik.ru/new/personaldatav2"
              target="_blank"
              class="sign__edit-number"
            ></a>
          </div>

          <div *ngIf="showSignTools.editPhone" class="sign__tools sign-tools">
            <div class="sign-tools__block">
              <tui-input-phone
                #phoneNumberModel="ngModel"
                required
                minlength="12"
                tuiTextfieldSize="m"
                class="sign-tools__input"
                [tuiTextfieldLabelOutside]="true"
                [(ngModel)]="status.mobilePhone"
              >
                Введите номер телефона...
              </tui-input-phone>
              <button
                tuiIconButton
                type="button"
                size="s"
                appearance="flat"
                icon="tuiIconCheckCircle"
                class="sign__edit-number"
                [disabled]="phoneNumberModel.invalid"
                (click)="
                  !!status.mobilePhone && updateMobilePhone(status.mobilePhone)
                "
              ></button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault></ng-container>
      <ng-container *ngSwitchCase="'disabled'">
        По техническим причинам выпуск НЭПа временно недоступен
      </ng-container>
    </ng-container>

    <div *ngIf="status === null" class="skeleton">
      <div class="skeleton-sign">
        <div
          class="tui-skeleton skeleton-sign__avatar tui-skeleton_rounded"
        ></div>
        <div class="skeleton-sign__text">
          <div class="tui-skeleton tui-skeleton_text tui-skeleton_short"></div>
          <div class="tui-skeleton tui-skeleton_text"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer"></div>
