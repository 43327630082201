import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import {UserCardComponent} from './components/user-card/user-card.component';
import {UserLinkComponent} from './components/user-link/user-link.component';
import {UserPhotoComponent} from './components/user-photo/user-photo.component';
import {LetDirective} from './directives/ng-let.directive';
import {NumberInput} from './directives/number-input';

@NgModule({
  declarations: [
    LetDirective,
    NumberInput,
    UserCardComponent,
    UserPhotoComponent,
    UserLinkComponent,
  ],
  exports: [
    LetDirective,
    NumberInput,
    UserCardComponent,
    UserPhotoComponent,
    UserLinkComponent,
  ],
  imports: [CommonModule, MatIconModule],
})
export class SharedModule {}
