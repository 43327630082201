export function humanizeDateRange(start: string, end?: string): string {
  const today = new Date(new Date().toISOString().slice(0, 10));
  const startDate = new Date(start.slice(0, 10));

  if (end) {
    const endDate = new Date(end.slice(0, 10));

    // Один день. Дата начала === дате конца: Сегодня || 10 апреля
    if (startDate.valueOf() === endDate.valueOf()) {
      return today.valueOf() === startDate.valueOf()
        ? 'сегодня'
        : startDate.toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'});
    }

    // Один месяц, но разные дни: С 1 по 10 апреля
    if (endDate.getMonth() === startDate.getMonth()) {
      return `с ${startDate.getDate()} по ${endDate.toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
      })}`;
    }

    // Разные месяцы, разные дни: С 1 марта по 10 апреля
    // TODO: Разные года
    return `с ${startDate.toLocaleDateString('ru-RU', {
      day: 'numeric',
      month: 'long',
    })} по ${endDate.toLocaleDateString('ru-RU', {day: 'numeric', month: 'long'})}`;
  }

  return `с ${startDate.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: startDate.getFullYear() !== today.getFullYear() ? 'numeric' : undefined,
  })}`;
}
