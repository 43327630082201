import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SDRequestNumberPipeModule} from '../../pipes';
import {ServicedeskRequestCardComponent} from './request-card.component';
import {TuiTagModule} from '@pik-taiga-ui/kit';

@NgModule({
  imports: [CommonModule, SDRequestNumberPipeModule, TuiTagModule],
  exports: [ServicedeskRequestCardComponent],
  declarations: [ServicedeskRequestCardComponent],
})
export class ServicedeskRequestCardModule {}
