import {Provider} from '@angular/core';
import {filter, map, Observable, pluck} from 'rxjs';

import {DynamicRequest} from '../dynamic-request/interfaces/dynamic-request.interface';
import {MANAGER_VACATION_REQUESTS} from '../tokens';
import {PlannedVacationService} from '@app/libs/servicedesk-api';

export const MANAGER_VACATION_PROVIDER: Provider = {
  provide: MANAGER_VACATION_REQUESTS,
  useFactory: managerVacationRequestsFactory,
  deps: [PlannedVacationService],
};

function managerVacationRequestsFactory(
  plannedVacationService: PlannedVacationService,
): Observable<DynamicRequest[]> {
  return plannedVacationService.getAllCurrent().pipe(
    pluck('data'),
    filter((res: any) => res && res.data),
    map((res: any) => {
      return res.data;
    }),
    // shareReplay({bufferSize: 1, refCount: true}),
  );
}
