import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'password',
})
export class PasswordPipe implements PipeTransform {
  transform(value: string, enabled = true, replaceChar?: string): any {
    if (enabled && value && typeof value === 'string' && value.length > 0) {
      return (replaceChar || '*').repeat(value.length);
    }

    return value;
  }
}
