import {inject, InjectionToken} from '@angular/core';
import {StatusDto, StatusService} from '@app/libs/servicedesk-api';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';

const EMPTY_STATUS_ARRAY: StatusDto[] = [];

/**
 * Стрим со всеми статусами, с которыми есть хотя бы одна заявка у текущего пользователя
 */
export const EXIST_REQUEST_STATUSES = new InjectionToken<
  Observable<readonly StatusDto[]>
>(
  'Стрим со всеми статусами, с которыми есть хотя бы одна заявка у текущего пользователя',
  {
    factory: () => {
      return inject(StatusService)
        .getAllExists()
        .pipe(
          map(res => {
            if (res && res.data) {
              /**
               * Сортируем массив по индексу, так как на беке сортировка отсутствует напрочь
               * статусы приходят в разнобой
               */
              const sortedStatuses: readonly StatusDto[] = [...res.data].sort(
                (a, b) => a.id - b.id,
              );

              return sortedStatuses;
            }

            return EMPTY_STATUS_ARRAY;
          }),
          // Обработать ошибку
          catchError(() => of(EMPTY_STATUS_ARRAY)),
          shareReplay({
            bufferSize: 1,
            refCount: true,
          }),
        );
    },
  },
);

/** Стрим со всеми статусами, с которыми есть хотя бы одна заявка */
export const EXIST_ALL_REQUEST_STATUSES = new InjectionToken<
  Observable<readonly StatusDto[]>
>('Стрим со всеми статусами, с которыми есть хотя бы одна заявка', {
  factory: () => {
    return inject(StatusService)
      .getAllExists(true)
      .pipe(
        map(res => {
          if (res && res.data) {
            /**
             * Сортируем массив по индексу, так как на беке сортировка отсутствует напрочь
             * статусы приходят в разнобой
             */
            const sortedStatuses: readonly StatusDto[] = [...res.data].sort(
              (a, b) => a.id - b.id,
            );

            return sortedStatuses;
          }

          return EMPTY_STATUS_ARRAY;
        }),
        // Обработать ошибку
        catchError(() => of(EMPTY_STATUS_ARRAY)),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );
  },
});
