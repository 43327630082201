import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {ServicedeskRequestCardModule} from '../request-card';
import {ServicedeskRequestListComponent} from './request-list.component';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {TuiLoaderModule, TuiScrollbarModule} from '@pik-taiga-ui/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ServicedeskRequestCardModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiScrollbarModule,
  ],
  exports: [ServicedeskRequestListComponent],
  declarations: [ServicedeskRequestListComponent],
})
export class ServicedeskRequestListModule {}
