import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ServicedeskRequestViewModule} from '../request-view';
import {ServicedeskRequestLoaderComponent} from './request-loader.component';

import {RequestWrapperComponent} from './request-wrapper/request-wrapper.component';
import {ServicedeskRequestSheetWrapperComponent} from './request-sheet-wrapper/request-sheet-wrapper.component';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {TuiLoaderModule, TuiScrollbarModule, TuiExpandModule} from '@pik-taiga-ui/core';

@NgModule({
  imports: [
    CommonModule,
    ServicedeskRequestViewModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiScrollbarModule,
    TuiExpandModule,
  ],
  exports: [
    RequestWrapperComponent,
    ServicedeskRequestLoaderComponent,
    ServicedeskRequestSheetWrapperComponent,
  ],
  declarations: [
    RequestWrapperComponent,
    ServicedeskRequestLoaderComponent,
    ServicedeskRequestSheetWrapperComponent,
  ],
  providers: [],
})
export class ServicedeskRequestLoaderModule {}
