import {LOCALE_ID, Provider} from '@angular/core';
import {TUI_VALIDATION_ERRORS} from '@pik-taiga-ui/kit';
import {catchError, map, of, shareReplay} from 'rxjs';

import {BLOCKED_REQUESTS as DR_BLOCKED_REQUESTS} from './dynamic-request/tokens/blocked-requests';
import {BLOCKED_REQUESTS as EMPLOYEE_BLOCKED_REQUESTS} from './employee';
import {ManagerService} from './home-api';
import {IS_MANAGER} from './home-api/tokens/is-manager';
import {SD_BLOCKED_INSTANCES_PROVIDER} from './providers/sd-blocked-intances';
import {SD_BLOCKED_INSTANCES} from './tokens';

function maxLengthValidator(context: {requiredLength: string}): string {
  return `Максимум знаков — ${context.requiredLength}`;
}

function minLengthValidator(context: {requiredLength: string}): string {
  return `Минимум знаков — ${context.requiredLength}`;
}

const isManagerFactory = (managerService: ManagerService) => {
  return managerService.getManager().pipe(
    map(res => res.data !== null && res.data.hasAccess === true),
    catchError(() => of(false)),
    shareReplay({bufferSize: 1, refCount: false}),
  );
};
export const APP_PROVIDERS: Provider[] = [
  SD_BLOCKED_INSTANCES_PROVIDER,
  {
    // Провайдер заблокированных сущностей для модуля динамических  заявок
    provide: DR_BLOCKED_REQUESTS,
    useExisting: SD_BLOCKED_INSTANCES,
  },
  {
    provide: IS_MANAGER,
    useFactory: isManagerFactory,
    deps: [ManagerService],
  },
  {
    // Провайдер заблокированных сущностей для модуля страницы пользователя
    provide: EMPLOYEE_BLOCKED_REQUESTS,
    useExisting: SD_BLOCKED_INSTANCES,
  },
  {provide: LOCALE_ID, useValue: 'ru'},
  {
    provide: TUI_VALIDATION_ERRORS,
    useValue: {
      required: 'Это поле обязательное',
      email: 'Это невалидный email',
      maxlength: maxLengthValidator,
      minlength: minLengthValidator,
    },
  },
];
