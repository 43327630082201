import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {ApproverGroups} from '../../interfaces';

@Component({
  selector: 'sd-request-approver-group-list',
  templateUrl: './request-approver-group-list.template.html',
  styleUrls: ['./request-approver-group-list.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicedeskRequestApproverGroupListComponent {
  @Input() steps: ApproverGroups[];
  @Input() showAll = false;
}
