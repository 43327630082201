import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {TuiDialogService} from '@pik-taiga-ui/core';
import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';
import {PikLocatorService} from 'pik-header';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {delay, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {CURRENT_EMPLOYEE, CURRENT_EMPLOYEES, EmployeeDto} from 'src/app/home-api';

import {EmployeeAvatarEditorComponent} from './avatar-editor';

@Component({
  selector: 'employee-individual',
  templateUrl: './individual.template.html',
  styleUrls: ['./individual.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeIndividualComponent {
  private readonly avatarUpdated$ = new Subject<void>();
  private readonly filteredExtraEmployees$ = combineLatest([
    this.currentEmployee$,
    this.currentEmployees$,
  ]).pipe(
    map(([employee, employeesRes]) =>
      employeesRes.filter(entry => entry.id !== employee.id),
    ),
  );

  readonly homeUrl = this.pikLocator.urls.homeUrl;
  readonly avatarShowLoader$ = this.avatarUpdated$.pipe(
    switchMap(_ => of(false).pipe(delay(500), startWith(true))),
    startWith(false),
  );

  readonly showAll$ = new BehaviorSubject(false);
  readonly extraEmployees$ = combineLatest([
    this.showAll$,
    this.filteredExtraEmployees$,
  ]).pipe(
    map(([showAll, employees]) =>
      showAll || employees.length < 2 ? employees : [employees[0]],
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly extraEmployeesCount$ = this.filteredExtraEmployees$.pipe(
    map(res => res.length),
  );

  constructor(
    @Inject(CURRENT_EMPLOYEE) readonly currentEmployee$: Observable<EmployeeDto>,
    @Inject(CURRENT_EMPLOYEES) readonly currentEmployees$: Observable<EmployeeDto[]>,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    private readonly pikLocator: PikLocatorService,
  ) {}

  openAvatarEditor(employee: EmployeeDto) {
    this.dialogService
      .open<boolean>(new PolymorpheusComponent(EmployeeAvatarEditorComponent), {
        data: employee,
        dismissible: false,
      })
      .subscribe(isUpdated => {
        if (isUpdated) {
          this.avatarUpdated$.next();
        }
      });
  }

  toogleAll() {
    this.showAll$.next(!this.showAll$.value);
  }
}
