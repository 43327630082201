<div
  *ngIf="employee"
  class="employee-card"
  [class.employee-card_big]="isBigCard"
>
  <a
    *ngIf="!!userLink && linkable; else avatar"
    tuiLink
    target="_blank"
    [href]="userLink"
  >
    <ng-container *ngTemplateOutlet="avatar"></ng-container>
  </a>
  <ng-template #avatar>
    <div class="avatar">
      <tui-avatar
        [size]="avatarSize"
        [autoColor]="true"
        [rounded]="true"
        [avatarUrl]="!defaultAvatar ? (employee[guidKey] | employeeGuidToAvatarUrl) : null"
        [text]="employee.fullName || employee.name || ''"
      ></tui-avatar>
    </div>
  </ng-template>

  <div class="content">
    <div class="content__header">
      <a
        *ngIf="!!userLink && linkable; else employeeName"
        tuiLink
        target="_blank"
        [href]="userLink"
      >
        <ng-container *ngTemplateOutlet="employeeName"></ng-container>
      </a>
      <ng-template #employeeName>
        <div class="title">{{ employee.fullName || employee.name }}</div>
      </ng-template>
      <div class="actions"><ng-content></ng-content></div>
    </div>

    <div
      *ngIf="showProperties"
      class="properties"
    >
      <div
        *ngIf="showProperties.includes('position') && employee?.position"
        class="properties__position properties__item"
      >
        {{ employee?.position }}
      </div>

      <div
        *ngIf="showProperties.includes('unitName') && employee?.unitName"
        class="properties__unitName properties__item"
      >
        {{ employee?.unitName }}
      </div>

      <div
        *ngIf="showProperties.includes('companyName') && employee?.unitName"
        class="properties__unitName properties__item"
      >
        {{ employee?.companyName }}
      </div>

      <div
        *ngIf="showProperties.includes('telegram') && employee?.telegram"
        class="properties__telegram properties__item"
      >
        <a [href]="'https://t.me/' + employee?.telegram">
          @{{ employee?.telegram }}
        </a>
      </div>

      <div
        *ngIf="showProperties.includes('email') && employee?.email"
        class="properties__email properties__item"
        (click)="sendToClipboard(employee?.email)"
      >
        {{ employee?.email }}
      </div>

      <div
        *ngIf="showProperties.includes('hobbies') && employee?.hobbies"
        class="properties__hobbies properties__item"
      >
        <tui-tag
          *ngFor="let item of employee?.hobbies"
          size="m"
          class="properties__hobby-tag"
          [class.properties__hobby-tag_active]="item.isSearch"
          [value]="item.name"
        ></tui-tag>
      </div>
    </div>
  </div>
</div>
