import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {DynamicRequest} from 'src/app/dynamic-request/interfaces/dynamic-request.interface';

import {SERVICE_DESK_API_URL} from '../../tokens';
import {ApiResponseWithEmptyMeta} from '../../interfaces';

@Injectable({providedIn: 'root'})
export class PlannedVacationService {
  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getAllCurrent() {
    return this.http.get<ApiResponseWithEmptyMeta<DynamicRequest[]>>(
      `${this.apiUrl}/v1/PlannedVacation/current`,
    );
  }

  approve(requestId: number, comment: string) {
    return this.http.put<ApiResponseWithEmptyMeta<DynamicRequest>>(
      `${this.apiUrl}/v1/PlannedVacation/approve/approved/${requestId}?comment=${comment}`,
      {},
    );
  }

  approveRequests(requestIds: number[], comment: string) {
    return this.http.put<ApiResponseWithEmptyMeta<DynamicRequest[]>>(
      `${this.apiUrl}/v1/PlannedVacation/approve/approved/bulk?comment=${comment}`,
      [...requestIds],
    );
  }

  rejectRequests(requestIds: number[], comment: string) {
    return this.http.put<ApiResponseWithEmptyMeta<DynamicRequest[]>>(
      `${this.apiUrl}/v1/PlannedVacation/approve/rejected/bulk?comment=${comment}`,
      [...requestIds],
    );
  }

  reject(requestId: number, comment: string) {
    return this.http.put<ApiResponseWithEmptyMeta<DynamicRequest>>(
      `${this.apiUrl}/v1/PlannedVacation/approve/rejected/${requestId}?comment=${comment}`,
      {},
    );
  }
}
