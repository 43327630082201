import {Clipboard} from '@angular/cdk/clipboard';
import {ChangeDetectionStrategy, Component, Inject, Optional} from '@angular/core';
import {TuiNotification, TuiNotificationsService} from '@pik-taiga-ui/core';
import {startWith} from 'rxjs/operators';
import {SipPhoneService} from 'src/app/home-api';

@Component({
  selector: 'employee-ip-phone',
  templateUrl: './ip-phone.template.html',
  styleUrls: ['./ip-phone.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeIpPhoneComponent {
  readonly sipPhone$ = this.sipPhoneService.getCurrent().pipe(startWith(null));

  hidePassword = true;

  constructor(
    @Inject(SipPhoneService) private readonly sipPhoneService: SipPhoneService,
    @Inject(Clipboard) private readonly clipboard: Clipboard,
    @Inject(TuiNotificationsService)
    @Optional()
    private readonly notifications?: TuiNotificationsService,
  ) {}

  toggleVisibility() {
    this.hidePassword = !this.hidePassword;
  }

  sendToClipboard(value: string) {
    this.clipboard.copy(value);

    if (this.notifications) {
      this.notifications
        .show('Пароль скопирован в буфер обмена', {
          label: '',
          status: TuiNotification.Success,
        })
        .subscribe();
    }
  }
}
