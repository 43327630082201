import {Inject, Pipe, PipeTransform} from '@angular/core';

import {SD_REQUEST_NUMBER_FORMAT} from '../../tokens';

@Pipe({
  name: 'requestNumber',
})
export class SDRequestNumberPipe implements PipeTransform {
  constructor(@Inject(SD_REQUEST_NUMBER_FORMAT) private readonly format: string) {}

  transform(requestId: number): string {
    const requestNumber = requestId.toString();

    return this.format.replace(new RegExp(`0{${requestNumber.length}}$`), requestNumber);
  }
}
