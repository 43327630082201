import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  merge,
  Observable,
  scan,
  startWith,
  switchMap,
} from 'rxjs';
import {map} from 'rxjs/operators';

import {
  ApiGETListRequestParams,
  ApiListResponse,
  HttpAnyParams,
  HttpParamAction,
  RegStaffScheduleDto,
} from '../interfaces';
import {HOME_API_URL} from '../tokens';

interface RegStaffScheduleFilterParams {
  fullName?: string;
  isClosed?: boolean;
  freePosition?: boolean;
  regUnitGuid?: string;
  jobClassifierPosition?: string;
  organizationGuid?: string;
}

type RegStaffScheduleFilterAction = HttpParamAction<
  keyof RegStaffScheduleFilterParams | keyof ApiGETListRequestParams
>;

type RegStaffScheduleGetParams = ApiGETListRequestParams &
  RegStaffScheduleFilterParams &
  HttpAnyParams;

@Injectable({providedIn: 'root'})
export class RegStaffScheduleService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getEntries(rawParams?: RegStaffScheduleGetParams) {
    const params = new HttpParams({fromObject: rawParams});

    return this.http.get<ApiListResponse<readonly RegStaffScheduleDto[] | null>>(
      `${this.apiUrl}/v1/RegStaffSchedule/user/current`,
      {params},
    );
  }

  smartFilter(
    searchSubject$: Observable<string>,
    ...filterSubject$: Array<Observable<RegStaffScheduleFilterAction>>
  ) {
    return merge(
      searchSubject$.pipe(
        debounceTime(400),
        map(value => (typeof value === 'string' ? value.trim() : '')),
        distinctUntilChanged(),
        map((value): RegStaffScheduleFilterAction => ['fullName', value]),
      ),
      ...filterSubject$,
    ).pipe(
      scan(
        (params, [param, value]): RegStaffScheduleGetParams => ({
          ...params,
          [param]: value,
        }),
        {},
      ),
      startWith({}),
      switchMap(params => this.getEntries({...params}).pipe(startWith(null))),
    );
  }
}
