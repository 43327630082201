<div>
  <div data-appearance="warning" class="alert">
    <tui-svg src="tuiIconCancel" class="icon"></tui-svg>
    <span class="alert__text">Пройдите это обучение</span>
  </div>

  <div class="header">
    <div class="header__title">Обучение</div>

    <div class="header__actions"></div>
  </div>

  <div class="content">
    <span>
      <p>
        Собрали для вас главные правила информационной безопасности в одном
        курсе. Пройдите обязательное обучение и получите знак отличника ИБ.
      </p>
      <p class="content--hint">
        Не открывается курс? Инструкция для входа на платформу
        <a
          tuiLink
          target="_blank"
          href="https://info.pik.ru/wiki/spaces/InfoSecurity/pages/21749 "
        >
          по ссылке.
        </a>
      </p>
    </span>
  </div>

  <div class="footer">
    <button tuiButton type="button" size="m" (click)="onLink()">Пройти обучение</button>
  </div>
</div>
