import {ChangeDetectionStrategy, Component, Inject, Injector} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';
import {BehaviorSubject, Observable, merge} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {OPENED_REQUEST} from '../../request-list';
import {ServicedeskRequestLoaderComponent} from '../request-loader.component';
import {TuiDestroyService} from '@pik-taiga-ui/cdk';
import {TuiSheetService} from '@pik-taiga-ui/addon-mobile';
import {SDRequest} from '@app/servicedesk-kit/interfaces';

@Component({
  selector: 'request-mobile-layout',
  templateUrl: './request-sheet-wrapper.template.html',
  styleUrls: ['./request-sheet-wrapper.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class ServicedeskRequestSheetWrapperComponent {
  private readonly requestLoaderComponent = new PolymorpheusComponent(
    ServicedeskRequestLoaderComponent,
    this.injector,
  );

  requestSheetOpener$ = this.sheetService.open(this.requestLoaderComponent, {
    overlay: true,
  });

  readonly sheet$ = this.sheetService.sheets$.pipe(map(([sheet]) => sheet));

  constructor(
    @Inject(TuiDestroyService)
    private readonly destroy$: Observable<unknown>,

    @Inject(ActivatedRoute)
    private readonly route: ActivatedRoute,
    @Inject(OPENED_REQUEST)
    private readonly openedRequest$: BehaviorSubject<SDRequest | null>,
    @Inject(TuiSheetService)
    readonly sheetService: TuiSheetService,
    @Inject(Injector)
    private readonly injector: Injector,
  ) {
    merge(route.params, this.openedRequest$.pipe(map(request => request?.id)))
      .pipe(
        switchMap(_ => this.requestSheetOpener$),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
