import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EmployeeGuidToAvatarUrlPipeModule} from '@kit';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {TuiScrollbarModule, TuiSvgModule} from '@pik-taiga-ui/core';
import {TuiAvatarModule} from '@pik-taiga-ui/kit';

import {EmployeeFavoritesComponent} from './favorites.component';

@NgModule({
  imports: [
    CommonModule,
    TuiSvgModule,
    TuiLetModule,
    TuiScrollbarModule,
    TuiAvatarModule,
    EmployeeGuidToAvatarUrlPipeModule,
  ],
  exports: [EmployeeFavoritesComponent],
  declarations: [EmployeeFavoritesComponent],
})
export class EmployeeFavoritesModule {}
