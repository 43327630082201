<tui-loader
  class="loader"
  [showLoader]="loading$ | async"
  [overlay]="true"
>
  <tui-scrollbar class="scroll-container">
    <div class="request-view">
      <ng-container *tuiLet="request$ | async as request">
        <sd-request-view
          *ngIf="request"
          [request]="request"
        ></sd-request-view>
      </ng-container>
    </div>

    <div
      *ngIf="error$ | async as error"
      class="error"
    >
      <ng-container [ngSwitch]="error?.status">
        <ng-container *ngSwitchCase="'403'">
          <div class="error__title">Недостаточно прав на просмотр</div>
          <div class="error__help">
            <p>
              Для предоставления прав отправьте заявку на
              <a href="mailto:0911@pik.ru">0911@pik.ru</a>
              .
            </p>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="error__title">Ошибка</div>
          <div class="error__help">
            <p>При загрузке данных по заявке произошла ошибка.</p>
            <p>
              Попробуйте повторить запрос позже и если проблема повторяется
              длительное время&nbsp;&mdash; отправьте нам заявку на&nbsp;
              <a href="mailto:0911@pik.ru">0911@pik.ru</a>
              со скриншотом информации из блока ниже:
            </p>
          </div>

          <div
            class="error__info-toggler"
            (click)="infoExpanded = true"
          >
            Техническая информация
            <tui-expand
              class="error__info"
              [expanded]="infoExpanded"
            >
              {{ error?.error }}
              <ng-template tuiExpandContent>...</ng-template>
            </tui-expand>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </tui-scrollbar>
</tui-loader>
