import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ServicedeskRequestApproverGroupListComponent} from './request-approver-group-list.component';
import {TuiExpandModule, TuiSvgModule} from '@pik-taiga-ui/core';
import {ServicedeskRequestApproverGroupModule} from '../request-approver-group/request-approver-group.module';

@NgModule({
  imports: [
    CommonModule,
    ServicedeskRequestApproverGroupModule,
    TuiSvgModule,
    TuiExpandModule,
  ],
  exports: [ServicedeskRequestApproverGroupListComponent],
  declarations: [ServicedeskRequestApproverGroupListComponent],
})
export class ServicedeskRequestApproverGroupListModule {}
