import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {
  ApiGETListRequestParams,
  ApiListResponse,
  ApiResponse,
  FollowedIndividualDto,
  HttpAnyParams,
} from '../interfaces';
import {HOME_API_URL} from '../tokens';

type SubscriptionGETParams = ApiGETListRequestParams & HttpAnyParams;

type SubscriptionResolve = {error: string | null};

@Injectable({providedIn: 'root'})
export class SubscriptionService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Получить все подписки текущего пользователя
   *
   * @todo с бэка приходит неверный totalCount, на единицу больше
   */
  getAll(params: SubscriptionGETParams) {
    return this.http.get<ApiListResponse<readonly FollowedIndividualDto[] | null>>(
      `${this.apiUrl}/v1/subscription/subscribes/all`,
      {
        params,
      },
    );
  }

  /** Добавить индивидуала в избранное */
  subscribe(individualGuid1C: string) {
    return this.http.post<ApiResponse<SubscriptionResolve>>(
      `${this.apiUrl}/v1/subscription/subscribe/${individualGuid1C}`,
      {},
    );
  }

  /** Удалить индивидуала из избранного */
  unsubscribe(individualGuid1C: string) {
    return this.http.post<ApiResponse<SubscriptionResolve>>(
      `${this.apiUrl}/v1/subscription/unsubscribe/${individualGuid1C}`,
      {},
    );
  }

  /** Изменить поля в подписке (доступен только order) */
  updateSub(individualGuid1C: string, body: {order: number | null}) {
    return this.http.put<ApiResponse<SubscriptionResolve>>(
      `${this.apiUrl}/v1/subscription/subscribes/${individualGuid1C}`,
      body,
    );
  }
}
