import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {
  ApiResponseWithEmptyMeta,
  CompanyBlockedInstanceDto,
  InstanceVisibilityDto,
  UserBlockedInstanceDto,
} from '../interfaces';
import {SERVICE_DESK_API_URL} from '../tokens';

@Injectable({providedIn: 'root'})
export class BlockedInstanceService {
  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /** Получить список всех заблокированных сущностей для текущего пользователя */
  getAllCurrent() {
    return this.http.get<ApiResponseWithEmptyMeta<UserBlockedInstanceDto>>(
      `${this.apiUrl}/v1/BlockedInstance/user/current`,
    );
  }

  getAllByCompany(companyGuid: string, visible?: boolean) {
    let params = new HttpParams();

    if (visible !== undefined) {
      params = params.append('visible', visible);
    }

    return this.http.get<ApiResponseWithEmptyMeta<CompanyBlockedInstanceDto[]>>(
      `${this.apiUrl}/v1/BlockedInstance/company/${companyGuid}`,
      {params},
    );
  }

  getAllByType(typeId: number, visible?: boolean) {
    let params = new HttpParams();

    if (visible !== undefined) {
      params = params.append('visible', visible);
    }

    return this.http.get<ApiResponseWithEmptyMeta<CompanyBlockedInstanceDto[]>>(
      `${this.apiUrl}/v1/BlockedInstance/type/${typeId}`,
      {params},
    );
  }

  changeVisibility(instanceVisibility: {
    companyGuid: string;
    blockedInstanceTypeId: number;
    visible: boolean;
  }) {
    return this.http.put<ApiResponseWithEmptyMeta<InstanceVisibilityDto>>(
      `${this.apiUrl}/v1/BlockedInstance`,
      instanceVisibility,
    );
  }

  changeVisibilityForAllCompanies(instanceVisibility: {
    blockedInstanceTypeId: number;
    hideForAllCompanies: boolean;
  }) {
    return this.http.post<
      ApiResponseWithEmptyMeta<{
        readonly blockedInstanceTypeId: number;
        readonly hideForAllCompanies: boolean;
      }>
    >(`${this.apiUrl}/v1/BlockedInstance/forAll`, instanceVisibility);
  }
}
