<ng-container [formGroup]="filterForm">
  <tui-input
    formControlName="text"
    icon="tuiIconSearchLarge"
    iconAlign="left"
    tuiTextfieldSize="l"
    tuiTextfieldExampleText="Начните печатать для поиска"
    class="search"
    [tuiTextfieldCleaner]="true"
  >
    Поиск по номеру и ФИО
  </tui-input>

  <tui-multi-select
    formControlName="requestType"
    class="filter-select"
    [valueContent]="multiselectValueContent"
    [identityMatcher]="identityMatcherById"
    [tuiTextfieldLabelOutside]="true"
    [tuiTextfieldCleaner]="true"
    [editable]="false"
  >
    Фильтр по типу
    <div
      *tuiDataList
      class="list"
    >
      <div class="filter-select__search">
        <tui-primitive-textfield
          #input
          iconContent="tuiIconSearchLarge"
          iconAlign="left"
          [tuiTextfieldLabelOutside]="true"
          [(value)]="typeSearcher"
          (keydown.arrowDown.prevent)="onArrowDown(list, $event)"
        >
          Поиск по типам
        </tui-primitive-textfield>
      </div>
      <hr />
      <tui-data-list
        *tuiLet="types$ | async as types"
        #list
        emptyContent="Нет типов с таким названием"
        (keydown)="onKeyDown($event.key, input.nativeFocusableElement)"
      >
        <tui-opt-group *ngIf="!value">
          <button
            *tuiLet="
              filterForm.controls.requestType.value?.length >
              types?.length / 2 as moreThanHalfSelected
            "
            tuiOption
            (click)="
              moreThanHalfSelected
                ? uncheckAll('requestType')
                : checkAll('requestType', types)
            "
          >
            {{ moreThanHalfSelected ? 'Убрать все' : 'Выбрать все' }}
          </button>
        </tui-opt-group>
        <ng-container *ngIf="types">
          <ng-container
            *tuiLet="types | tuiFilter: stringMatcher:typeSearcher as filtered"
          >
            <tui-opt-group
              *ngIf="filtered.length"
              tuiMultiSelectGroup
            >
              <button
                *ngFor="let type of filtered"
                tuiOption
                class="long-text-option"
                [value]="type"
              >
                {{ type.name }}
              </button>
            </tui-opt-group>
          </ng-container>
        </ng-container>
      </tui-data-list>
    </div>

    <ng-template
      #multiselectValueContent
      let-data
    >
      Выбрано {{ data.length | i18nPlural: valuesPluralizeMap:'ru-RU' }}
    </ng-template>
  </tui-multi-select>

  <tui-multi-select
    formControlName="status"
    class="filter-select"
    [valueContent]="multiselectValueContent"
    [identityMatcher]="identityMatcherById"
    [tuiTextfieldCleaner]="true"
    [tuiTextfieldLabelOutside]="true"
    [editable]="false"
  >
    Фильтр по статусу
    <div
      *tuiDataList
      class="list"
    >
      <div class="filter-select__search">
        <tui-primitive-textfield
          #input
          iconContent="tuiIconSearchLarge"
          iconAlign="left"
          [tuiTextfieldLabelOutside]="true"
          [(value)]="statusSearcher"
          (keydown.arrowDown.prevent)="onArrowDown(list, $event)"
        >
          Поиск по статусам
        </tui-primitive-textfield>
      </div>
      <hr />
      <tui-data-list
        *tuiLet="statuses$ | async as statuses"
        #list
        emptyContent="Нет статусов с таким названием"
        (keydown)="onKeyDown($event.key, input.nativeFocusableElement)"
      >
        <tui-opt-group *ngIf="!value">
          <button
            *tuiLet="
              filterForm.controls.status.value?.length >
              statuses?.length / 2 as moreThanHalfSelected
            "
            tuiOption
            (click)="
              moreThanHalfSelected
                ? uncheckAll('status')
                : checkAll('status', statuses)
            "
          >
            {{ moreThanHalfSelected ? 'Убрать все' : 'Выбрать все' }}
          </button>
        </tui-opt-group>
        <ng-container *ngIf="statuses">
          <ng-container
            *tuiLet="
              statuses | tuiFilter: stringMatcher:statusSearcher as filtered
            "
          >
            <tui-opt-group
              *ngIf="filtered.length"
              tuiMultiSelectGroup
            >
              <button
                *ngFor="let status of filtered"
                tuiOption
                class="long-text-option"
                [value]="status"
              >
                <tui-badge
                  size="s"
                  class="status-badge"
                  [status]="status.color"
                ></tui-badge>
                {{ status.name }}
              </button>
            </tui-opt-group>
          </ng-container>
        </ng-container>
      </tui-data-list>
    </div>

    <ng-template
      #multiselectValueContent
      let-data
    >
      Выбрано {{ data.length | i18nPlural: valuesPluralizeMap:'ru-RU' }}
    </ng-template>
  </tui-multi-select>

  <ng-container *tuiLet="hasAdminRights$ | async as hasAdminRights">
    <!-- Необходима "вечная" подписка на allRequestModeOn -->
    <ng-container *tuiLet="allRequestModeOn$ | async as allRequestModeOn">
      <!-- mode-button описан в отдельном scss файле -->
      <button
        *ngIf="hasAdminRights"
        tuiHint="Включить режим администратора"
        tuiIconButton
        appearance="flat"
        class="mode-button"
        [class.mode-button--on]="allRequestModeOn"
        [hideDelay]="0"
        [icon]="adminModeIcon$ | async"
        (click)="toggleAdminMode()"
      ></button>
    </ng-container>
  </ng-container>
</ng-container>
