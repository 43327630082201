import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {combineLatest, map, Observable, Subject} from 'rxjs';

import {CURRENT_EMPLOYEE, EmployeeDto, EmployeeService} from '@home-api';
import {LearningWidgetService} from './widgets/learning/services/learning-widget.service';
import {BLOCKED_REQUESTS} from './tokens';
import {takeUntil, tap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'employee-page',
  templateUrl: './employee.template.html',
  styleUrls: ['./employee.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  readonly hiddenWidgets = {
    budget: false,
  };

  isLearningPassed: boolean = true;

  readonly isOutStaffer$ = this.currentEmployee$.pipe(
    tap(employee => this.title.setTitle(employee ? employee.name : 'Личный кабинет')),
    map(({isOutstaffer}) => isOutstaffer),
    takeUntil(this.destroy$),
  );

  readonly isShowKedoWidget$ = this.blockedInstances$.pipe(map(blocked => !blocked.nep));

  readonly isSignatureAvailable$ = combineLatest([
    this.blockedInstances$,
    this.isOutStaffer$,
  ]).pipe(
    map(([blocked, isOutstaffer]) => !isOutstaffer && !blocked.nep),
    takeUntil(this.destroy$),
  );

  constructor(
    @Inject(CURRENT_EMPLOYEE) private readonly currentEmployee$: Observable<EmployeeDto>,
    @Inject(Title) private readonly title: Title,
    @Inject(BLOCKED_REQUESTS)
    private readonly blockedInstances$: Observable<{
      [sdRequestCode: string]: true | undefined;
    }>,
    private readonly learningWidgetService: LearningWidgetService,
    private employeeServiceEmployeeService: EmployeeService,
  ) {}

  ngOnInit(): void {
    this.initExamPassFetch();
  }

  private initExamPassFetch(): void {
    this.learningWidgetService
      .getExamPassStatus()
      .pipe(
        map(({value}) => (this.isLearningPassed = value)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
