import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxsModule} from '@ngxs/store';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiSvgModule,
} from '@pik-taiga-ui/core';

import {LearningComponent} from './learning.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    TuiButtonModule,
    TuiSvgModule,
    TuiLoaderModule,
    TuiLetModule,
    TuiLinkModule,
  ],
  exports: [LearningComponent],
  declarations: [LearningComponent],
  providers: [],
})
export class EmployeeLearningModule {}
