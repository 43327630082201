import {inject, InjectionToken} from '@angular/core';
import {StatusDto, StatusService} from '@app/libs/servicedesk-api';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';

const EMPTY_STATUS_ARRAY: StatusDto[] = [];

export const REQUEST_STATUSES = new InjectionToken<Observable<readonly StatusDto[]>>(
  'Стрим со всеми существующими статусами заявок',
  {
    factory: () => {
      return inject(StatusService)
        .getAll()
        .pipe(
          map(res => {
            if (res && res.data) {
              /**
               * Сортируем массив по индексу, так как на беке сортировка отсутствует напрочь
               * статусы приходят в разнобой
               */
              const sortedStatuses: readonly StatusDto[] = [...res.data].sort(
                (a, b) => a.id - b.id,
              );

              return sortedStatuses;
            }

            return EMPTY_STATUS_ARRAY;
          }),
          // Обработать ошибку
          catchError(() => of(EMPTY_STATUS_ARRAY)),
          shareReplay({
            bufferSize: 1,
            refCount: true,
          }),
        );
    },
  },
);
