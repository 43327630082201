import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {RequestFieldDto} from '@app/libs/servicedesk-api';

@Component({
  selector: 'sd-request-fields',
  templateUrl: './request-fields.template.html',
  styleUrls: ['./request-fields.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicedeskRequestFieldsComponent {
  @Input() fields: RequestFieldDto[];

  jsonField(value): string {
    try {
      return typeof value === 'string' ? JSON.parse(value)?.src : '';
    } catch (e) {
      return '';
    }
  }
}
