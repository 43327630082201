import {Inject, Injectable} from '@angular/core';
import {GetAllRequestsFilterParams} from '@app/libs/servicedesk-api';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {map, scan, shareReplay} from 'rxjs/operators';

import {AllRequestModeService} from '../../services';

const DEFAULT_FITLER_VALUE: GetAllRequestsFilterParams = {};

export type FilterKey = keyof GetAllRequestsFilterParams;

export type FilterKeyValue = {[key in FilterKey]?: any};

export type FilterAction<T = any> = {
  type: 'setValue' | 'setValues' | 'clearValue' | 'clear';
  payload?: T;
};

@Injectable({providedIn: 'root'})
export class ServicedeskRequestListFilterService {
  private readonly actions$ = new ReplaySubject<FilterAction>(1);

  readonly filterState$ = this.actions$.pipe(
    scan(
      (state, action) => {
        switch (action.type) {
          case 'clear':
            return {all: this.adminMode$.value};

          case 'setValue': {
            const payload: {
              key: FilterKey;
              value: any;
            } = action.payload;

            return {...state, [payload.key]: payload.value};
          }

          case 'setValues': {
            const entries = Object.entries(
              (<FilterAction<FilterKeyValue>>action).payload,
            );
            const newState = {...state};

            entries.forEach(([key, value]) => {
              if (value === null) {
                delete newState[key];
              } else {
                newState[key] = value;
              }
            });

            return newState;
          }

          case 'clearValue': {
            const newState = {...state};

            delete newState[action.payload];

            return newState;
          }

          default:
            break;
        }

        return state;
      },
      Object.assign(
        {...DEFAULT_FITLER_VALUE},
        this.adminMode$.value
          ? {
              all: this.adminMode$.value === true,
            }
          : {},
      ),
    ),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
  );

  readonly filterIsActive$ = this.filterState$.pipe(
    map(state => {
      const keys = Object.keys(state);

      return (
        keys.length > 0 && (keys.length > 1 || (keys.length === 1 && keys[0] !== 'all'))
      );
    }),
  );

  constructor(
    @Inject(AllRequestModeService) private readonly adminMode$: BehaviorSubject<boolean>,
  ) {}

  setValue(key: FilterKey, value: any) {
    if (value === null) {
      this.actions$.next({
        type: 'clearValue',
        payload: key,
      });
    } else {
      this.actions$.next({
        type: 'setValue',
        payload: {
          key,
          value,
        },
      });
    }
  }

  setValues(values: FilterKeyValue) {
    this.actions$.next({type: 'setValues', payload: values});
  }
  clearState() {
    this.actions$.next({type: 'clear', payload: null});
  }
}
