import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {TuiDestroyService} from '@pik-taiga-ui/cdk';
import type {TuiTextMaskOptions} from '@pik-taiga-ui/core';
import {
  TuiDialogService,
  TuiNotification,
  TuiNotificationsService,
} from '@pik-taiga-ui/core';
import {BehaviorSubject, EMPTY, map, of, Subject, timer} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  startWith,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';
import {EmployeeSignService} from 'src/app/home-api';

import {getJSONFromKonturError} from './kontur-error-helper';
import {ConfirmDialogComponent} from '@app/servicedesk-kit/dialogs';
import {errorNotification} from '@app/core/observables';

interface ParcedStatus {
  stage: 'notExists' | 'needSMS' | 'waiting' | 'active' | 'disabled';
  mobilePhone: string | null;
  companyName?: string;
  validTo?: string;
}

const GETTING_A_SIGNATURE_DISABLED = false;

@Component({
  selector: 'employee-signature',
  templateUrl: './signature.template.html',
  styleUrls: ['./signature.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class EmployeeSignatureComponent {
  private readonly updateStatus$ = new BehaviorSubject<void>(null);
  private readonly recieveSMS$ = new Subject<boolean>();
  readonly certFormId$ = new BehaviorSubject<string | null>(null);
  readonly onReissueNep$ = new Subject<void>();

  readonly recieveSMSRequest$ = this.recieveSMS$.pipe(
    startWith(false),
    switchMap((retry?: boolean) => {
      if (!retry) {
        const smsSendTimeISO = sessionStorage.getItem('EMPLOYEE_SIGNATURE_SMS_EXPIRED');

        if (smsSendTimeISO) {
          if (new Date(smsSendTimeISO).valueOf() - Date.now() > 0) {
            return of(true);
          }
        }
      }

      const expiredUtc = Date.now() + 15 * 60 * 1000;

      sessionStorage.setItem(
        'EMPLOYEE_SIGNATURE_SMS_EXPIRED',
        new Date(expiredUtc).toISOString(),
      );
      sessionStorage.setItem('EMPLOYEE_SIGNATURE_SMS_SENDED', new Date().toISOString());

      return this.employeeSignService.recieveSMSCode().pipe(
        map(res => !!res),
        catchError((_err: unknown) => {
          return this.notifications
            .show('Попробуйте ещё раз чуть позже', {
              status: TuiNotification.Error,
              label: 'Не\xa0удалось отправить смс-код',
            })
            .pipe(map(_ => true));
        }),
        startWith(null),
      );
    }),
    startWith(null),
  );

  readonly timer$ = this.recieveSMSRequest$.pipe(
    switchMap(smsSended => {
      if (!smsSended) {
        return of(0);
      }

      const sendedDate = new Date(
        sessionStorage.getItem('EMPLOYEE_SIGNATURE_SMS_SENDED'),
      ).valueOf();
      const secondsLeft = 60 - Math.min(60, Math.floor((Date.now() - sendedDate) / 1000));

      return timer(0, 1000).pipe(
        map(index => secondsLeft - index),
        takeWhile(x => x >= 0),
      );
    }),
  );

  readonly smsCodeChecking$ = new BehaviorSubject<boolean>(false);

  readonly status$ = this.updateStatus$.pipe(
    switchMap(() => this.employeeSignService.getCurrentStatuses()),

    map(({data}) => {
      const parsedStatus: ParcedStatus = {
        stage: 'notExists',
        mobilePhone: null,
      };

      if (data && data.length > 0) {
        const currentStatus = data[data.length - 1];
        this.certFormId$.next(currentStatus.formId);

        if (currentStatus) {
          parsedStatus.mobilePhone = currentStatus.mobilePhone;

          if (currentStatus.smsCodeConfirmed) {
            if (currentStatus.status === 'released') {
              parsedStatus.stage = 'active';
              parsedStatus.companyName = currentStatus.companyName;
              parsedStatus.validTo = currentStatus.validTo;
            } else {
              parsedStatus.stage = 'waiting';
            }
          } else if (currentStatus.documentPagesUploaded) {
            parsedStatus.stage = 'needSMS';
          }
        }
      }

      if (GETTING_A_SIGNATURE_DISABLED && parsedStatus.stage !== 'active') {
        parsedStatus.stage = 'disabled';
      }

      return parsedStatus;
    }),
    startWith(null),
  );

  readonly removingUnfinishedNep$ = this.onReissueNep$.pipe(
    concatMap(() =>
      this.dialogService.open<boolean>(
        new PolymorpheusComponent(ConfirmDialogComponent),
        {
          data: {question: 'Текущая заявка на выпуск НЭП будет отменена. Продолжить?'},
        },
      ),
    ),
    filter(answer => answer !== false && answer !== null),
    concatMap(() =>
      this.employeeSignService.deleteEmployeeSign(this.certFormId$.getValue()),
    ),
    filter(response => response !== null),
    tap(() => {
      this.updateStatus$.next();

      this.notifications.show('Заявка на выпуск НЭП отменена', {
        status: TuiNotification.Success,
        autoClose: 5000,
      });
    }),
    errorNotification(this.notifications),
  );

  smsCode: string = null;
  readonly smsCodeTextMask: TuiTextMaskOptions = {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: false,
  };

  showSignTools = {editPhone: false};

  constructor(
    @Inject(EmployeeSignService)
    private readonly employeeSignService: EmployeeSignService,
    @Inject(TuiDestroyService)
    private readonly destroy$: TuiDestroyService,
    @Inject(TuiNotificationsService)
    private readonly notifications: TuiNotificationsService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {}

  onFormSended() {
    this.updateStatus$.next();
  }

  recieveSMSCode() {
    this.recieveSMS$.next(true);
  }

  updateMobilePhone(phone: string) {
    this.employeeSignService
      .updateMobilePhone(phone)
      .pipe(
        catchError((err: unknown) => {
          let message = 'Произошла ошибка при смене номера телефона';

          if (err instanceof HttpErrorResponse) {
            message = JSON.parse(err.error.error)?.innerMessage;
          }

          return this.notifications.show(message, {
            status: TuiNotification.Error,
            label: 'Произошла ошибка при смене номера телефона',
            autoClose: 6000,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.showSignTools.editPhone = false;
  }

  sendSMSCode() {
    this.smsCodeChecking$.next(true);
    this.employeeSignService
      .sendSMSCode(this.smsCode)
      .pipe(
        finalize(() => this.smsCodeChecking$.next(false)),
        switchMap(res => {
          if (res && res.data) {
            sessionStorage.removeItem('EMPLOYEE_SIGNATURE_SMS_EXPIRED');
            sessionStorage.removeItem('EMPLOYEE_SIGNATURE_SMS_SENDED');
            this.updateStatus$.next();

            return EMPTY;
          }
        }),
        catchError((err: unknown) => {
          return this.notifications.show(
            getJSONFromKonturError((err as any).error?.data),
            {
              status: TuiNotification.Error,
              label: 'Произошла ошибка при проверке кода',
              autoClose: 6000,
            },
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
