<ng-container *tuiLet="sipPhone$ | async as phone">
  <div class="alert"></div>
  <div class="header">
    <div class="header__title">IP-телефония</div>
    <div class="header__actions">
      <a
        *ngIf="!phone || !phone.data"
        href="https://jira.pik.ru/servicedesk/customer/portal/1/create/23"
        target="_blank"
        tuiButton
        icon="tuiIconExternal"
        size="xs"
        appearance="flat"
        (click)="toggleVisibility()"
      >
        Запросить телефонию
      </a>

      <button
        *ngIf="phone && phone.data"
        tuiIconButton
        size="xs"
        appearance="flat"
        tuiHintDirection="topLeft"
        [tuiHintShowDelay]="300"
        [tuiHintHideDelay]="0"
        [tuiHint]="hidePassword ? 'Показать пароль' : 'Скрыть пароль'"
        [icon]="hidePassword ? 'tuiIconEyeOpen' : 'tuiIconEyeClosed'"
        (click)="toggleVisibility()"
      ></button>
      <!-- <a
        href="https://docs.google.com/document/d/1d7uGyOmZ0K33QGD9jUbfOKeid-ud2QPUP2sGhsjjTdQ"
        target="_blank"
        tuiIconButton
        icon="tuiIconTooltip"
        size="xs"
        appearance="flat"
      ></a> -->
    </div>
  </div>
  <div class="content">
    <div *ngIf="phone && phone.data" class="phone-data">
      <div class="phone-prop">
        <div class="phone-prop__label">Логин</div>
        <div class="phone-prop__value">{{ phone.data.login }}</div>
      </div>
      <div class="phone-prop">
        <div class="phone-prop__label">Пароль</div>
        <div class="phone-prop__value">
          {{ phone.data.password | password: hidePassword }}
          <button
            tuiIconButton
            icon="tuiIconCopy"
            size="xs"
            appearance="flat"
            tuiHint="Скопировать пароль в&nbsp;буффер"
            [tuiHintShowDelay]="300"
            [tuiHintHideDelay]="0"
            (click)="sendToClipboard(phone.data.password)"
          ></button>
        </div>
      </div>
    </div>

    <div *ngIf="phone && !phone.data" class="no-content">
      Вам ещё не&nbsp;выделен номер
      <!-- TODO: Как получить номер? - Нужна будет кнопка оформления номер, блок не скрываем -->
    </div>

    <div *ngIf="phone === null" class="skeleton">
      <div class="tui-skeleton tui-skeleton_text"></div>
      <div class="tui-skeleton tui-skeleton_text"></div>
    </div>
  </div>
  <div class="footer">
    <a href="https://disk.yandex.ru/i/3zOFQo3Zittbzw">
      Инструкция для пользователей
    </a>
  </div>
</ng-container>
