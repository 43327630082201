<div class="alert"></div>
<div class="header">
  <div class="header__title">
    Подписки
    <!-- <span *ngIf="subsCount$ | async as subsCount">({{ subsCount }})</span> -->
  </div>
</div>
<tui-scrollbar *tuiLet="topSubs$ | async as topSubsRes" class="content">
  <div *ngIf="topSubsRes" class="subscriptions">
    <ng-container
      *ngFor="let sub of topSubsRes.data"
      [ngTemplateOutlet]="employeeCard"
      [ngTemplateOutletContext]="{$implicit: sub}"
    ></ng-container>

    <ng-container *ngIf="showAll$ | async">
      <ng-container *tuiLet="extraSubs$ | async as extraSubsRes">
        <ng-container
          *ngIf="extraSubsRes === null"
          [ngTemplateOutlet]="skeleton"
        ></ng-container>

        <ng-container
          *ngFor="let sub of extraSubsRes?.data"
          [ngTemplateOutlet]="employeeCard"
          [ngTemplateOutletContext]="{$implicit: sub}"
        ></ng-container>
      </ng-container>
    </ng-container>
    <div
      *ngIf="topSubsRes.data === null || topSubsRes.data.length === 0"
      class="no-content"
    >
      Подпишитесь на&nbsp;коллег, чтобы видеть их&nbsp;здесь
    </div>
  </div>

  <ng-container
    *ngIf="topSubsRes === null"
    [ngTemplateOutlet]="skeleton"
  ></ng-container>
</tui-scrollbar>

<div *tuiLet="showAll$ | async as showAll" class="footer">
  <button
    *ngIf="extraSubsExist$ | async"
    class="show-more"
    (click)="toogleAll()"
  >
    {{ showAll ? 'Скрыть' : 'Показать всех' }}
    <tui-svg
      [src]="showAll ? 'tuiIconChevronUp' : 'tuiIconChevronDown'"
    ></tui-svg>
  </button>
</div>

<ng-template #employeeCard let-employee>
  <a
    target="_blank"
    class="subscriptions__entry employee-card"
    [href]="homeUrl + '/employees/' + employee.id"
  >
    <tui-avatar
      size="l"
      class="employee-card__avatar"
      [autoColor]="true"
      [rounded]="true"
      [text]="employee.fullName"
      [avatarUrl]="employee.individualGuid1C | employeeGuidToAvatarUrl: 1"
    ></tui-avatar>
    <div class="employee-card__name">
      {{ employee.surName }} {{ employee.name }}
    </div>
    <div class="employee-card__events">
      <div *ngFor="let event of employee.events" class="employee-card__event">
        {{ event.humanizedString }}
      </div>
      <div
        *ngIf="!employee.events || employee.events.length === 0"
        class="employee-card__event"
      >
        {{ employee.positionName }}
      </div>
    </div>
  </a>
</ng-template>

<ng-template #skeleton>
  <div class="skeleton">
    <div class="skeleton-user">
      <div
        class="tui-skeleton skeleton-user__avatar tui-skeleton_rounded"
      ></div>
      <div
        class="tui-skeleton tui-skeleton_text tui-skeleton_center tui-skeleton_short"
      ></div>
      <div class="tui-skeleton tui-skeleton_text"></div>
    </div>
    <div class="skeleton-user">
      <div
        class="tui-skeleton skeleton-user__avatar tui-skeleton_rounded"
      ></div>
      <div
        class="tui-skeleton tui-skeleton_text tui-skeleton_center tui-skeleton_short"
      ></div>
      <div class="tui-skeleton tui-skeleton_text"></div>
    </div>
    <div class="skeleton-user">
      <div
        class="tui-skeleton skeleton-user__avatar tui-skeleton_rounded"
      ></div>
      <div
        class="tui-skeleton tui-skeleton_text tui-skeleton_center tui-skeleton_short"
      ></div>
      <div class="tui-skeleton tui-skeleton_text"></div>
    </div>
    <div class="skeleton-user">
      <div
        class="tui-skeleton skeleton-user__avatar tui-skeleton_rounded"
      ></div>
      <div
        class="tui-skeleton tui-skeleton_text tui-skeleton_center tui-skeleton_short"
      ></div>
      <div class="tui-skeleton tui-skeleton_text"></div>
    </div>
  </div>
</ng-template>
