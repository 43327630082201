import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {
  ApiListResponse,
  GetAllRequestsFilterParams,
  RequestService,
} from '@app/libs/servicedesk-api';

import {
  asyncScheduler,
  BehaviorSubject,
  EMPTY,
  fromEvent,
  merge,
  NEVER,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  ignoreElements,
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
  switchMapTo,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';

import {convertRequestDto} from '../../adapters';
import {SDRequest} from '../../interfaces';
import {AllRequestModeService} from '../../services';
import {ServicedeskRequestListFilterService} from '../request-list-filter';
import {SDFullRequest} from './../request-view/full-request.model';
import {REQUEST_CHANGES} from './tokens';
import {TuiDestroyService} from '@pik-taiga-ui/cdk';
import {TuiScrollbarComponent} from '@pik-taiga-ui/core';
import {CURRENT_EMPLOYEE} from '@app/home-api';
import {EmployeeInfo} from '@app/dynamic-request/interfaces/employee-info.interface';

const SCROLL_OFFSET = 20;

type RequestListAction = {
  type: 'filter' | 'nextPage';
  payload?: GetAllRequestsFilterParams;
};

type RequestChangesAction =
  | {type: 'updateRequest'; payload: SDFullRequest}
  | {type: 'newResolve'; payload: ApiListResponse<SDRequest>};

/**
 * Компонент для отображения списка заявок с обработкой состояний
 * ошибки, пустых данных, загрузки
 *
 * Можно обогатить работой с клавиатурой: перемещение по стрелкам
 */
@Component({
  selector: 'sd-request-list',
  templateUrl: './request-list.template.html',
  styleUrls: ['./request-list.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class ServicedeskRequestListComponent implements AfterViewInit {
  @Input() private readonly autoOpenFirst = true;

  @ViewChild(TuiScrollbarComponent, {read: ElementRef})
  private readonly scrollBar?: ElementRef<HTMLElement>;

  readonly scrollReachEnd$ = new Subject<void>();
  readonly hasMore$ = new Subject<boolean>();
  readonly pageLoading$ = new BehaviorSubject<boolean>(false);
  readonly loading$ = new BehaviorSubject<boolean>(false);

  readonly mainRequest$ = merge(
    this.filterService.filterState$.pipe(
      map(filterState => {
        this.loading$.next(true);

        return <RequestListAction>{type: 'filter', payload: filterState};
      }),
    ),
    this.hasMore$.pipe(
      switchMap(hasMore => (hasMore ? this.scrollReachEnd$ : NEVER)),
      filter(() => !this.pageLoading$.value),
      map(() => {
        this.pageLoading$.next(true);

        return <RequestListAction>{type: 'nextPage'};
      }),
      debounceTime(300),
    ),
  ).pipe(
    scan((prevParams, action) => {
      switch (action.type) {
        case 'filter':
          return {...action.payload};
        case 'nextPage':
          return {...prevParams, offset: (prevParams.offset || 0) + SCROLL_OFFSET};
        default:
          return prevParams;
      }
    }, null),
    switchMap((params: GetAllRequestsFilterParams) => {
      return this.requestService.getAll(params || null).pipe(
        tap(res => {
          this.hasMore$.next(res && res.data.length === SCROLL_OFFSET);
        }),
        startWith(null),
      );
    }),
    map(res => {
      if (res && res.data) {
        return <ApiListResponse<SDRequest>>{
          ...res,
          data: res.data.map(convertRequestDto),
        };
      }

      return null;
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  readonly adminModeOn$ = this.adminMode$;
  readonly filterUsed$ = this.filterService.filterIsActive$;

  readonly requests$ = merge(
    this.mainRequest$.pipe(
      catchError((_err: unknown) => {
        return of({type: 'newResolve', payload: {data: <SDRequest[]>[], meta: null}});
      }),
      map<ApiListResponse<SDRequest>, RequestChangesAction>(resolve => {
        return {type: 'newResolve', payload: resolve};
      }),
    ),
    (this.changes$ || EMPTY).pipe(
      filter(request => !!request),
      // Обрабатываются только изменения в статусе заявки
      map<SDFullRequest, RequestChangesAction>(request => {
        return {type: 'updateRequest', payload: request};
      }),
    ),
  ).pipe(
    scan<RequestChangesAction, readonly SDRequest[]>((requests, action) => {
      switch (action.type) {
        // Новые данные от бэкенда
        case 'newResolve':
          if (action.payload === null && requests === null) {
            return null;
          }

          if (action.payload === null) {
            return requests;
          }

          if (action.payload.meta && action.payload.meta.offset > 0) {
            this.pageLoading$.next(false);

            return [...requests, ...action.payload.data];
          }

          this.scrollBar && this.scrollBar.nativeElement.scrollTo(0, 0);
          this.loading$.next(false);

          return action.payload.data;
        /**
         * При изменении статуса заявки извне необходимо найти
         * её копию в списке и обновить статус
         */
        case 'updateRequest': {
          const indexInList = requests.findIndex(
            ({requestId}) => requestId === action.payload.requestId,
          );

          if (indexInList > -1) {
            const newRequestsState: readonly SDRequest[] = [
              ...requests.slice(0, indexInList),
              <SDRequest>{
                ...requests[indexInList],
                statusId: action.payload.statusId,
                statusName: action.payload.statusName,
                statusCode: action.payload.statusCode,
                statusColorCode: action.payload.statusColorCode,
              },
              ...requests.slice(indexInList + 1),
            ];

            return newRequestsState;
          }

          return requests;
        }
        default:
          return requests;
      }
    }, null),
    tap(requests => {
      if (this.autoOpenFirst && requests && requests.length > 0) {
        this.router.navigate([requests[0].requestId], {
          relativeTo: this.route,
          replaceUrl: true,
        });
      }
    }),
  );

  readonly errors$ = this.mainRequest$.pipe(
    ignoreElements(),
    catchError((_err: unknown) => {
      return of('Произошла ошибка при получении списка заявок');
    }),
  );

  currentEmployeeGuid$ = this.currentEmployee$.pipe(
    map(employee => (employee ? employee.individualGuid1C : null)),
  );

  constructor(
    @Inject(AllRequestModeService) private readonly adminMode$: BehaviorSubject<boolean>,
    @Inject(RequestService) private readonly requestService: RequestService,
    @Inject(ServicedeskRequestListFilterService)
    private readonly filterService: ServicedeskRequestListFilterService,
    @Inject(TuiDestroyService) private readonly destroy$: Subject<void>,
    @Inject(CURRENT_EMPLOYEE)
    private readonly currentEmployee$: Observable<EmployeeInfo | null>,
    @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
    @Inject(Router) private readonly router: Router,
    @Optional()
    @Inject(REQUEST_CHANGES)
    private readonly changes$: Observable<SDFullRequest> = of(null),
  ) {}

  readonly requestTrackBy = (request: SDRequest) => request.requestId;

  ngAfterViewInit() {
    if (this.autoOpenFirst) {
      this.router.events
        .pipe(
          filter(
            event =>
              event instanceof NavigationEnd && this.route.snapshot.children.length === 0,
          ),
          switchMapTo(this.requests$),
          tap(requests => {
            if (requests && requests.length > 0) {
              this.router.navigate([requests[0].requestId], {
                relativeTo: this.route,
                replaceUrl: true,
              });
            }
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }

    const reachEnd$ = !this.scrollBar
      ? new Observable<boolean>()
      : fromEvent<Event>(this.scrollBar.nativeElement, 'scroll').pipe(
          throttleTime(375, asyncScheduler, {trailing: true}),
          distinctUntilChanged(),
          map(
            ({target}) =>
              (<HTMLElement>target).scrollHeight - (<HTMLElement>target).scrollTop <
              SCROLL_OFFSET + (<HTMLElement>target).clientHeight,
          ),
          filter(reached => !!reached),
          tap(() => this.scrollReachEnd$.next()),
          takeUntil(this.destroy$),
        );

    reachEnd$.subscribe();
  }
}
