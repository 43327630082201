import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {throwError} from 'rxjs';

import {ApiListResponse, UserAssetDto} from '../interfaces';
import {HOME_API_URL} from '../tokens';

@Injectable({providedIn: 'root'})
export class UserAssetsService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Получить устройства текущего пользователя
   *
   * @TODO Недоступен на бэке
   */
  getCurrent() {
    return throwError(
      () => new Error('Метод для получения текущих устройств недоступен на бэке'),
    );
  }

  getByUserId(userId: number) {
    return this.http.get<ApiListResponse<readonly UserAssetDto[]>>(
      `${this.apiUrl}/v1/userAsset/${userId}`,
    );
  }
}
