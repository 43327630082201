<tui-loader
  *tuiLet="loading$ | async as loading"
  class="loader"
  [showLoader]="loading"
  [overlay]="true"
>
  <tui-scrollbar
    *tuiLet="requests$ | async as requests"
    class="scroll-container"
  >
    <ng-container *tuiLet="errors$ | async as error">
      <ng-container *ngIf="!error && requests === null">
        <div
          *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]"
          class="skeleton"
        >
          <div class="skeleton__header">
            <div class="tui-skeleton tui-skeleton_text"></div>
            <div class="tui-skeleton tui-skeleton_text"></div>
          </div>
          <div class="tui-skeleton tui-skeleton_text"></div>
          <div class="tui-skeleton tui-skeleton_text tui-skeleton_short"></div>
        </div>
      </ng-container>

      <ng-container *ngIf="!error && !!requests">
        <div *ngIf="requests.length === 0" class="notification">
          <p>
            {{
              (filterUsed$ | async) || loading
                ? 'Нет ни одной заявки подходящей под фильтры'
                : 'Вы не создали ещё ни одной заявки'
            }}
          </p>
        </div>

        <ng-container
          *tuiLet="currentEmployeeGuid$ | async as currentEmployeeGuid"
        >
          <ng-container *tuiLet="adminModeOn$ | async as adminModeOn">
            <ng-container
              *ngFor="
                let request of requests;
                let index = index;
                trackBy: requestTrackBy
              "
            >
              <div
                *ngIf="
                  adminModeOn &&
                  (index === 0 ||
                    request.createdMonth !== requests[index - 1].createdMonth)
                "
                class="month-divider"
              >
                {{ request.createdMonth }}
              </div>
              <a
                sdRequestCard
                routerLinkActive
                [colored]="true"
                [request]="request"
                [routerLink]="[request.id]"
                [showEmployee]="
                  adminModeOn ||
                  currentEmployeeGuid !== request.employeeIndividualGuid1C
                "
                [showAuthor]="
                  adminModeOn ||
                  currentEmployeeGuid !== request.authorIndividualGuid1C
                "
              ></a>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <div *ngIf="error" class="notification">
        <p>При загрузке списка заявок произошла ошибка.</p>
        <p>
          Попробуйте повторить попытку позже или обратитесь в поддержку по этой
          ссылке:
        </p>
        <p>
          <a>Адрес поддержки в jira</a>
        </p>
      </div>
    </ng-container>

    <div
      *ngIf="!(hasMore$ | async) && requests && requests.length > 0"
      class="no-more"
    >
      На этом всё
    </div>

    <ng-container *ngIf="hasMore$ | async">
      <tui-loader
        *tuiLet="pageLoading$ | async as pageLoading"
        class="page-loader"
        [showLoader]="pageLoading"
      >
        <div class="page-loader__text">
          Загружаем ещё{{ pageLoading ? '!' : '?' }}
        </div>
      </tui-loader>
    </ng-container>
  </tui-scrollbar>
</tui-loader>
