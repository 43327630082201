import {Component, Input} from '@angular/core';
import {PikLocatorService} from 'pik-header';

interface UserLike {
  individualGuid1C?: string;
  [key: string]: any;
}
@Component({
  selector: 'app-user-photo',
  styleUrls: ['./user-photo.component.scss'],
  templateUrl: './user-photo.component.html',
})
export class UserPhotoComponent {
  private readonly photoApi = this.locatorService.urls.photoApi;
  @Input() guidSource = 'individualGuid1C';

  photoUrl = '';
  @Input() set user(user: UserLike) {
    this.photoUrl = `${this.photoApi}/api/v1/userphoto/${
      user[this.guidSource]
    }?renditionId=1`;
  }

  constructor(public locatorService: PikLocatorService) {}

  handleError(): void {
    this.photoUrl = 'assets/images/user.svg';
  }
}
