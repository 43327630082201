<div class="header">
  <div class="header__title">О себе</div>
  <div class="header__actions">
    <button
      *ngIf="!(editingForm$ | async)"
      tuiIconButton
      icon="tuiIconSettings"
      size="xs"
      appearance="subaction"
      (click)="startEdit()"
    ></button>
  </div>
</div>
<ng-container *ngIf="attributesValues$ | async as values">
  <div *tuiLet="editingForm$ | async as editingForm" class="content">
    <ng-container
      *ngIf="!editingForm"
      [ngTemplateOutlet]="extraView"
      [ngTemplateOutletContext]="{$implicit: values}"
    ></ng-container>
    <ng-container
      *ngIf="editingForm"
      [ngTemplateOutlet]="extraForm"
      [ngTemplateOutletContext]="{$implicit: editingForm}"
    ></ng-container>
  </div>
</ng-container>

<ng-template #extraView let-values>
  <ng-container *ngIf="values !== null">
    <div class="property-group">
      <div class="property">
        <div class="property__name">Чем занимаюсь на работе</div>
        <div *ngIf="values.aboutMe; else emptyProp" class="property__value">
          <div class="property__formatted-value">{{ values.aboutMe }}</div>
        </div>
      </div>
    </div>

    <div class="property-group">
      <div class="property">
        <div class="property__name property__name_mb">Что умею</div>
        <div *ngIf="values.skills; else emptyProp" class="property__value">
          <tui-tag
            *ngFor="let skill of values.skills"
            status="custom"
            class="property__value-tag"
            [value]="skill.name"
          ></tui-tag>
        </div>
      </div>
    </div>

    <div class="property-group">
      <div class="property">
        <div class="property__name property__name_mb">
          <div>Чем занимаюсь в свободное время</div>
          <div *ngIf="values.hobbies" class="property__btn-all">
            <a href="/searchHobbies">Поиск единомышленников</a>
          </div>
        </div>
        <div *ngIf="values.hobbies; else emptyProp" class="property__value">

          <a *ngFor="let hobbie of values.hobbies"
             class="property__value-tag"
             [href]="'/searchHobbies/' + hobbie.name">
            {{ hobbie.name }}
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #extraForm let-formGroup>
  <tui-loader [showLoader]="isSaving$ | async" [overlay]="true">
    <div class="disclaimer">
      <p>
        С&nbsp;помощью кнопки с&nbsp;иконкой глаза можно скрывать некоторые
        данные на&nbsp;портале от&nbsp;других пользователей:
      </p>
      <ul>
        <li>
          <tui-svg src="tuiIconEyeOpen"></tui-svg>
          &mdash;&nbsp;информация видна всем
        </li>
        <li>
          <tui-svg src="tuiIconEyeClosed"></tui-svg>
          &mdash;&nbsp;информация видна только вам
        </li>
      </ul>
    </div>

    <ng-container [formGroup]="formGroup">
      <label tuiLabel class="property" [label]="aboutMeLabel">
        <tui-text-area
          formControlName="aboutMe"
          tuiTextfieldSize="m"
          tuiTextfieldExampleText="Проектирую невероятное с вероятностью три двенадцатых"
          [expandable]="true"
          [tuiTextfieldLabelOutside]="true"
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldMaxLength]="500"
          [tuiHintContent]="hints.aboutMe"
        >
          Напишите род деятельности, проект, хобби или просто смайлик
        </tui-text-area>
      </label>
      <ng-template #aboutMeLabel>
        <div class="property-label">
          <div class="property-label__text">Чем занимаюсь на работе</div>
          <ng-container
            *ngTemplateOutlet="
              visibilityToogler;
              context: {$implicit: 'aboutMeIsHidden', formGroup: this.formGroup}
            "
          ></ng-container>
        </div>
      </ng-template>
      <div class="property-group">
        <label
          *ngIf="autocomleteMap.get('skills') as autocomplete"
          tuiLabel
          class="property"
          [label]="skillLabel"
        >
          <ng-container
            [ngTemplateOutlet]="autocompleteTemplate"
            [ngTemplateOutletContext]="{$implicit: autocomplete}"
          ></ng-container>

          <ng-template #skillLabel>
            <div class="property-label">
              <div class="property-label__text">Что умею</div>
              <ng-container
                *ngTemplateOutlet="
                  visibilityToogler;
                  context: {
                    $implicit: 'skillsIsHidden',
                    formGroup: this.formGroup
                  }
                "
              ></ng-container>
            </div>
          </ng-template>
        </label>
      </div>
      <div class="property-group">
        <label
          *ngIf="autocomleteMap.get('hobbies') as autocomplete"
          tuiLabel
          class="property"
          [label]="hobbieLabel"
        >
          <ng-container
            [ngTemplateOutlet]="autocompleteTemplate"
            [ngTemplateOutletContext]="{$implicit: autocomplete}"
          ></ng-container>
          <ng-template #hobbieLabel>
            <div class="property-label">
              <div class="property-label__text">
                Чем занимаюсь в свободное время
              </div>
              <ng-container
                *ngTemplateOutlet="
                  visibilityToogler;
                  context: {
                    $implicit: 'hobbiesIsHidden',
                    formGroup: this.formGroup
                  }
                "
              ></ng-container>
            </div>
          </ng-template>
        </label>
      </div>
    </ng-container>

    <div class="form-actions">
      <button tuiButton size="m" appearance="flat" (click)="cancelEdit()">
        Отмена
      </button>
      <button
        tuiButton
        size="m"
        appearance="primary"
        [disabled]="formGroup.invalid"
        (click)="save()"
      >
        Сохранить
      </button>
    </div>
  </tui-loader>
</ng-template>

<ng-template #emptyProp>
  <div class="property__value property__value_empty">&mdash;</div>
</ng-template>

<ng-template #visibilityToogler let-fieldName let-formGroup="formGroup">
  <div
    *tuiLet="formGroup.controls[fieldName].value as isHidden"
    class="property-label__action"
    [attr.data-checked]="isHidden"
    (click)="toogleVisibility(fieldName)"
  >
    <tui-svg [src]="isHidden ? 'tuiIconEyeClosed' : 'tuiIconEyeOpen'"></tui-svg>
  </div>
</ng-template>

<ng-template #autocompleteTemplate let-autocomplete>
  <tui-multi-select
    *tuiLet="autocomplete.searchObservable$ | async as state"
    #component
    tuiTextfieldExampleText="Начните вводить значение..."
    [tuiTextfieldLabelOutside]="true"
    [formControl]="autocomplete.control"
    [tuiTextfieldCleaner]="true"
    [tuiTextfieldSize]="'m'"
    [editable]="false"
    [identityMatcher]="autocompleteMatcherById"
    [stringify]="stringifyByName"
    [tuiHintContent]="autocomplete.hint"
    (focusedChange)="$event && autocomplete.nextSubject$.next(false)"
    (searchChange)="onSearchValueChange($event, autocomplete.searchSubject$)"
  >
    <custom-list
      *tuiDataList
      [items]="state.items | tuiHideSelected: component"
      [loading]="state.loading"
      [hasMore]="state.hasMore"
      (searchChange)="onSearchValueChange($event, autocomplete.searchSubject$)"
      (getNext)="autocomplete.nextSubject$.next(true)"
    ></custom-list>
  </tui-multi-select>
  <tui-field-error [formControl]="autocomplete.control"></tui-field-error>
</ng-template>
