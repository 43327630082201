<tui-loader
  *tuiLet="loading$ | async as loading"
  [showLoader]="loading"
  [overlay]="true"
>
  <div *ngIf="formNotFinished$ | async" data-appearance="warning" class="alert">
    <span class="alert__text">Пройдите опрос</span>
  </div>

  <div class="header">
    <div class="header__title">Здоровье</div>

    <div class="header__actions"></div>
  </div>

  <div *tuiLet="formNotFinished$ | async as notFinished" class="content">
    <span *ngIf="notFinished">
      <div class="content__header">Вакцинация от гриппа</div>
      <p>
        Заботимся&nbsp;о&nbsp;здоровье сотрудников
        и&nbsp;держим&nbsp;руку&nbsp;на&nbsp;пульсе.
      </p>
      <p>
        Пожалуйста, пройдите небольшой
        <strong>опрос по вакцинации от гриппа.</strong>
        Статистика поможет понять, какая динамика сейчас по компании и нужны ли
        дополнительные меры поддержки сотрудников.
      </p>
      <p>
        Чтобы пройти вакцинацию от гриппа, можно обратиться в поликлинику по
        месту жительства. Подробнее о пунктах вакцинации в Москве можно узнать
        <a tuiLink target="_blank" href="https://mosgorzdrav.ru/antivirus">
          здесь.
        </a>
      </p>
      <p>Берегите себя ❤️</p>
    </span>

    <span *ngIf="!notFinished">
      <p>Когда придёт время актуализировать информацию, мы&nbsp;напомним 🙂</p>

      <p>
        Если остались вопросы, пишите

        <a tuiLink target="_blank" href="https://t.me/SYUZI_MSK">нам.</a>
      </p>
    </span>

    <form *ngIf="notFinished" [formGroup]="form">
      <div
        *ngIf="form.controls.fluStatus && !form.controls.fluStatus.disabled"
        class="radio-group"
      >
        <div class="radio-group__caption">Опрос:</div>

        <div class="radio-group__variants">
          <tui-radio-labeled
            *ngFor="let item of fluStatusVariants"
            size="l"
            formControlName="fluStatus"
            class="radio-item"
            [identityMatcher]="radioIdentityMatcher"
            [item]="item"
          >
            <div class="radio-item__label" [innerHtml]="item.name"></div>
            <div
              *ngIf="item.description"
              class="radio-item__desc"
              [innerHtml]="item.description"
            ></div>
          </tui-radio-labeled>
        </div>
      </div>

      <div class="form-actions">
        <button
          tuiButton
          size="m"
          [disabled]="form.invalid"
          (click)="saveValues()"
        >
          Отправить
        </button>
      </div>
    </form>
  </div>

  <ng-template #radioItem let-item>
    <div class="radio-item__label" [innerHtml]="item.name"></div>
    <div
      *ngIf="item.description"
      class="radio-item__desc"
      [innerHtml]="item.description"
    ></div>
  </ng-template>
</tui-loader>
