import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AttachmentResponse} from '@app/core/interfaces/attachment-response';
import {ResponseWrapper} from 'pik-header';
import {Observable} from 'rxjs';
import {CoreModule} from '../core.module';
import {SERVICE_DESK_API_URL} from '@app/servicedesk-api/tokens';

@Injectable({
  providedIn: CoreModule,
})
export class AttachmentService {
  private readonly serviceUrl = `${this.apiUrl}/Attachment`;

  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  saveAttachment(file: File): Observable<ResponseWrapper<AttachmentResponse>> {
    const formData = new FormData();

    formData.append('file', file);

    return this.http.post<ResponseWrapper<AttachmentResponse>>(this.serviceUrl, formData);
  }
}
