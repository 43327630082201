import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {pluck, Subject} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  ApiGETListRequestParams,
  ApiListResponse,
  ApiResponse,
  HttpAnyParams,
  UserDto,
} from '../interfaces';
import {HOME_API_URL} from '../tokens';

interface UserFilterParams {
  text?: string;
}

type UserGetParams = ApiGETListRequestParams & UserFilterParams & HttpAnyParams;

@Injectable({providedIn: 'root'})
export class UserService {
  public currentUser: UserDto;

  readonly currentUser$ = this.getCurrent().pipe(
    pluck('data'),
    startWith(null),
    tap(user => {
      this.currentUser = {...user};
    }),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
  );

  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getAll(rawParams: UserGetParams) {
    const params = new HttpParams({fromObject: rawParams});

    return this.http.get<ApiListResponse<readonly UserDto[] | null>>(
      `${this.apiUrl}/v1/user`,
      {
        params,
      },
    );
  }

  getAllUnique(rawParams: UserGetParams) {
    const params = new HttpParams({fromObject: rawParams});

    return this.http.get<ApiListResponse<readonly UserDto[] | null>>(
      `${this.apiUrl}/v1/user/unique`,
      {
        params,
      },
    );
  }

  getCurrent() {
    return this.http.get<ApiResponse<UserDto | null>>(`${this.apiUrl}/v1/user/current`);
  }

  autocomplete(searchSubject$: Subject<string>) {
    return searchSubject$.pipe(
      debounceTime(400),
      map(value => (typeof value === 'string' ? value.trim() : '')),
      distinctUntilChanged(),
      switchMap((searchQuery: string) =>
        this.getAll({text: searchQuery, limit: 20}).pipe(pluck('data'), startWith(null)),
      ),
      startWith([]),
    );
  }

  autocompleteUnique(searchSubject$: Subject<string>) {
    return searchSubject$.pipe(
      debounceTime(400),
      map(value => (typeof value === 'string' ? value.trim() : '')),
      distinctUntilChanged(),
      switchMap((searchQuery: string) =>
        this.getAllUnique({text: searchQuery, limit: 20}).pipe(
          pluck('data'),
          startWith(null),
        ),
      ),
    );
  }
}
