import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {ApiListResponse, AttributeDto} from '../interfaces';
import {HOME_API_URL} from '../tokens';

@Injectable({providedIn: 'root'})
export class AttributeService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getAll() {
    return this.http.get<ApiListResponse<readonly AttributeDto[]>>(
      `${this.apiUrl}/v1/attribute`,
    );
  }

  /** @todo дописать метод сервиса и интерфейсы */
  create() {
    return this.http.get<any>('');
  }

  /** @todo дописать метод сервиса и интерфейсы */
  getById() {
    return this.http.get<any>('');
  }

  /** @todo дописать метод сервиса и интерфейсы */
  update() {
    return this.http.get<any>('');
  }

  delete(attributeId: number) {
    return this.http.delete<boolean>(`${this.apiUrl}/v1/attribute/${attributeId}`);
  }
}
