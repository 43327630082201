import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'employee-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.scss'],
})
export class LearningComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onLink(): void {
    window.open('https://moodle.home.pik-digital.ru/my/courses.php', '_blank');
  }
}
