import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ApiResponse} from 'src/app/home-api';

import {AvatarValidationResponse, UserPhotoDto} from '../interfaces';
import {PHOTO_API_URL} from '../tokens';
import {Observable, map, of} from 'rxjs';

export interface ResizeReqBody {
  individualGuid: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

@Injectable({providedIn: 'root'})
export class UserPhotoService {
  constructor(
    @Inject(PHOTO_API_URL) private readonly photoApiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Изменить размер отображаемой области фотографии индивидуала
   *
   * @todo неверная модель ответа в swagger. Указан boolean, на деле полная модель
   */
  resize(body: ResizeReqBody) {
    return this.http.put<ApiResponse<UserPhotoDto>>(
      `${this.photoApiUrl}/v1/userPhoto/resize`,
      body,
    );
  }

  /**
   * Проверяет фото на наличие лица (не более одного)
   */
  avatarValidation(file: File): Observable<AvatarValidationResponse> {
    const formData = new FormData();

    formData.append('file', file);

    return this.http
      .post<ApiResponse<AvatarValidationResponse>>(
        `${this.photoApiUrl}/v1/PhotoValidation`,
        formData,
      )
      .pipe(map(({data}) => data));
  }
}
