import {InjectionToken, isDevMode} from '@angular/core';

export const HOME_API_URL = new InjectionToken<string>(
  'URL-адрес API Хоума (без указания версии в адресе)',
  {
    factory: () =>
      isDevMode()
        ? 'https://364.api.test.home.pik-digital.ru/api'
        : 'https://home-api.pik.ru/api',
  },
);
