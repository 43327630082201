<div class="positions-grid">
  <ng-container *tuiLet="currentEmployee$ | async as mainEmployee">
    <ng-container *ngIf="mainEmployee !== null">
      <div class="positions-grid__avatar employee-avatar">
        <tui-loader
          *tuiLet="avatarShowLoader$ | async as showLoader"
          [showLoader]="showLoader"
          [overlay]="true"
        >
          <tui-avatar
            size="xl"
            [text]="mainEmployee.name"
            [autoColor]="true"
            [rounded]="true"
            [avatarUrl]="
              showLoader
                ? null
                : (mainEmployee.individualGuid1C | employeeGuidToAvatarUrl)
            "
          ></tui-avatar>

          <button
            class="employee-avatar__action"
            (click)="openAvatarEditor(mainEmployee)"
          >
            <tui-svg src="tuiIconSettingsLarge"></tui-svg>
          </button>
        </tui-loader>
      </div>
      <div class="positions-grid__entry">
        <div class="full-name">
          <div class="full-name__h">{{ mainEmployee.name }}</div>
        </div>
        <ng-container
          [ngTemplateOutlet]="positionTemplate"
          [ngTemplateOutletContext]="{$implicit: mainEmployee}"
        ></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="mainEmployee === null">
      <div class="skeleton-avatar">
        <div
          class="tui-skeleton skeleton-avatar__img tui-skeleton_rounded"
        ></div>
      </div>
      <div class="skeleton-text">
        <div class="tui-skeleton tui-skeleton_text"></div>
        <div class="tui-skeleton tui-skeleton_text tui-skeleton_short"></div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *tuiLet="extraEmployees$ | async as extraEmployees">
    <ng-container *ngFor="let employee of extraEmployees; let index = index">
      <div class="positions-grid__index">#{{ index + 2 }}</div>

      <ng-container
        [ngTemplateOutlet]="positionTemplate"
        [ngTemplateOutletContext]="{$implicit: employee}"
      ></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-container *tuiLet="showAll$ | async as showAll">
  <div *tuiLet="extraEmployeesCount$ | async as count" class="footer">
    <button *ngIf="count > 1" class="show-more" (click)="toogleAll()">
      {{ showAll ? 'Скрыть' : 'Показать все' }}
      <tui-svg
        [src]="showAll ? 'tuiIconChevronUp' : 'tuiIconChevronDown'"
      ></tui-svg>
    </button>
  </div>
</ng-container>

<ng-template #positionTemplate let-employee>
  <div class="position">
    <div class="position__info">
      <div class="position__title">
        <a
          tuiLink
          target="_blank"
          class="position__title-link"
          [href]="homeUrl + '/employees/' + employee.id"
        >
          {{ employee.position }}
        </a>
      </div>
      <div class="position__prop">{{ employee.company }}</div>
      <div class="position__prop">{{ employee.unitName }}</div>
    </div>
    <div class="position__days">
      <div class="days">
        <div class="days__count">{{ employee.vacationDays || 0 }}</div>
        <div class="days__type">д. отпуска</div>
      </div>
      <div *ngIf="employee.daysOff && employee.daysOff > 0" class="days">
        <div class="days__count">{{ employee.daysOff || 0 }}</div>
        <div class="days__type">д. отгулов</div>
      </div>
    </div>
  </div>
</ng-template>
