import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';

import {
  ApiListRequestQueryParams,
  ApiListResponse,
  ApiResponseWithEmptyMeta,
  RequestTypeDto,
} from '../interfaces';
import {REQUESTS_SOURCE, SERVICE_DESK_API_URL} from '../tokens';

export type GetAllRequestTypeFilterParams = ApiListRequestQueryParams<{
  isDynamic?: boolean;
  source?: string[];
  /** Категория. 0-Документы, 1-Заявки, 2-Прочее */
  category?: 0 | 1 | 2;
}>;

@Injectable({providedIn: 'root'})
export class RequestTypeService {
  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    @Optional() @Inject(REQUESTS_SOURCE) private readonly sources: string[],
    private readonly http: HttpClient,
  ) {}

  /**
   * Получение списка всех типов заявок с возможностью фильтрации
   * по источнику, категории и динамичности
   */
  getAll(params?: GetAllRequestTypeFilterParams) {
    let _params: GetAllRequestTypeFilterParams = params;

    if (this.sources) {
      if (!_params) {
        _params = {};
      }

      _params.source = this.sources;
    }

    return this.http.get<ApiListResponse<RequestTypeDto>>(
      `${this.apiUrl}/v2/RequestType`,
      {
        params: _params,
      },
    );
  }

  /** Получение типа заявки по ИД */
  getById(id: number) {
    return this.http.get<ApiResponseWithEmptyMeta<RequestTypeDto>>(
      `${this.apiUrl}/v1/RequestType/${id}`,
    );
  }

  /**
   * Получение всех типов заявок, для которых создан хотя бы один экземпляр
   *
   * `all = true` позволяет получить статусы во всех существующих заявках
   */
  getAllExists(all?: boolean) {
    const params = all !== undefined ? {all} : null;

    return this.http.get<ApiResponseWithEmptyMeta<readonly RequestTypeDto[]>>(
      `${this.apiUrl}/v1/RequestType/exists`,
      {params},
    );
  }

  /**
   * Создание статуса.
   *
   * Полностью отстутствует проверка на дубли
   */
  create(body: {name: string; internalName: string}) {
    return this.http.post<ApiResponseWithEmptyMeta<RequestTypeDto>>(
      `${this.apiUrl}/v1/Status`,
      body,
    );
  }

  /**
   * Обновление свойств в типе заявки
   *
   * Обязательно указывать `id` не только в query-параметрах, но и в body запроса
   * иначе будет создана новая запись
   */
  updateById(id: number, body: {name: string; internalName: string}) {
    return this.http.post<ApiResponseWithEmptyMeta<true>>(
      `${this.apiUrl}/v1/Status/${id}`,
      {...body, id},
    );
  }

  /**
   * Удаление статуса по ИД
   *
   * @deprecated Существует в swagger, но не существует на бэке
   */
  removeById(id: number) {
    return this.http.delete<ApiResponseWithEmptyMeta<never>>(
      `${this.apiUrl}/v1/Status/${id}`,
    );
  }
}
