import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiGroupModule,
  TuiLabelModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
} from '@pik-taiga-ui/core';
import {
  TuiCheckboxBlockModule,
  TuiFieldErrorModule,
  TuiInputDateModule,
  TuiInputFileModule,
  TuiInputModule,
  TuiInputPhoneModule,
  TuiSelectModule,
} from '@pik-taiga-ui/kit';
import {TextMaskModule} from 'angular2-text-mask';

import {EmployeeSignatureFormComponent} from './form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    TextMaskModule,

    TuiLoaderModule,
    TuiFieldErrorModule,
    TuiLabelModule,
    TuiInputModule,
    TuiInputDateModule,
    TuiButtonModule,
    TuiGroupModule,
    TuiSelectModule,
    TuiDataListModule,
    TuiInputPhoneModule,
    TuiInputFileModule,
    TuiCheckboxBlockModule,
    TuiTextfieldControllerModule,
  ],
  exports: [EmployeeSignatureFormComponent],
  declarations: [EmployeeSignatureFormComponent],
  providers: [],
})
export class EmployeeSignatureFormModule {}
