export const dates2024 = [
  [
    "за январь",
    "03.02.2024",
    "15.02.2024"
  ],
  [
    "за февраль",
    "05.03.2024",
    "15.03.2024"
  ],
  [
    "за март",
    "05.04.2024",
    "15.04.2024"
  ],
  [
    "за апрель",
    "03.05.2024",
    "15.05.2024"
  ],
  [
    "за май",
    "05.06.2024",
    "14.06.2024"
  ],
  [
    "за июнь",
    "05.07.2024",
    "15.07.2024"
  ],
  [
    "за июль",
    "05.08.2024",
    "15.08.2024"
  ],
  [
    "за август",
    "05.09.2024",
    "13.09.2024"
  ],
  [
    "за сентябрь",
    "04.10.2024",
    "15.10.2024"
  ],
  [
    "за октябрь",
    "05.11.2024",
    "15.11.2024"
  ],
  [
    "за ноябрь",
    "05.12.2024",
    "13.12.2024"
  ],
  [
    "за декабрь",
    "28.12.2024",
    "15.01.2025"
  ],
  [
    "за январь 2025",
    "05.02.2025",
    "14.02.2025"
  ]
];
