import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE} from '@ng-web-apis/common';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AllRequestModeService extends BehaviorSubject<boolean> {
  constructor(@Inject(LOCAL_STORAGE) private readonly storage: Storage) {
    super(
      storage.getItem('allRequestMode') && storage.getItem('allRequestMode') === 'true',
    );
  }

  next(enabled: boolean): void {
    this.storage.setItem('allRequestMode', `${enabled}`);
    super.next(enabled);
  }

  toggle() {
    this.next(!this.value);
  }
}
