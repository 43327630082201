<div class="alert"></div>
<div class="header">
  <div class="header__title">Заявки</div>
</div>
<div class="content">
  <div *tuiLet="blockedRequests$ | async as blockedRequests" class="documents">
    <!-- <a
      *ngIf="isVacationAvailable$ | async as count"
      target="_blank"
      class="documents__entry document"
      [href]="this.serviceDeskURL + '/?new=vacationv2'"
    >
      <div class="document__header">
        <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
        <div class="document__title">Взять отпуск</div>
      </div>
      <div class="document__description">Доступно {{ vacationDaysCount$ | async }}д.</div>
    </a> -->

    <a
      *ngIf="isDaysOffAvailable$ | async"
      target="_blank"
      class="documents__entry document"
      [href]="this.serviceDeskURL + '/?new=daysoff'"
    >
      <div class="document__header">
        <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
        <div class="document__title">Взять отгул</div>
      </div>
    </a>

    <a
      *ngIf="blockedRequests && blockedRequests.errandv2 !== true"
      target="_blank"
      class="documents__entry document"
      [href]="this.serviceDeskURL + '/?new=errandv2'"
    >
      <div class="document__header">
        <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
        <div class="document__title">Командировка</div>
      </div>
    </a>

    <ng-container *ngIf="!!(jiraLinkAvailable$ | async)">
      <a
        target="_blank"
        class="documents__entry document"
        [href]="this.comfortSDJiraLink"
      >
        <div class="document__header">
          <tui-svg src="tuiIconFile" class="document__icon"></tui-svg>
          <div class="document__title">Создать заявку</div>
        </div>
      </a>
    </ng-container>
  </div>
</div>
<div class="footer">
  <a target="_blank" class="show-all" [href]="serviceDeskURL + '/new'">
    Показать все заявки
    <tui-svg src="tuiIconExternal"></tui-svg>
  </a>
</div>
