<div class="header">Список замещающих</div>
<div *tuiLet="substitutions$ | async as substitutions" class="content">
  <tui-loader [showLoader]="substitutions === null" [overlay]="true">
    <div
      *ngIf="substitutions && substitutions.length > 0; else emptySubstitutions"
      class="substitutions-list"
    >
      <div
        *ngFor="let substitution of substitutions"
        class="substitutions-list__item"
      >
        <div class="employee-card">
          <div class="employee-card__avatar">
            <tui-avatar
              avatarUrl="{{photoApi}}/api/v1/userphoto/{{substitution.substitutive.individualGuid1C}}"
              size="s"
              [text]="substitution.substitutive.fullName"
              [rounded]="true"
            ></tui-avatar>
          </div>
          <div class="employee-card__content">
            <div class="employee-card__full-name">
              {{ substitution.substitutive.fullName }}
            </div>
            <div class="employee-card__prop">
              <span>
                C&nbsp;{{ substitution.dateStart | date: 'dd.MM.YYYY' }}
              </span>
              <span *ngIf="substitution.dateEnd">
                по&nbsp;{{ substitution.dateEnd | date: 'dd.MM.YYYY' }}
              </span>
              <span *ngIf="!substitution.dateEnd">(без даты окончания)</span>
            </div>
          </div>
          <div class="employee-card__actions">
            <button
              tuiIconButton
              icon="tuiIconCloseLarge"
              appearance="remover"
              size="s"
              [rounded]="true"
              (click)="onDeleteSubstitution(substitution.id)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </tui-loader>

  <form class="form" [formGroup]="newSubstitutionForm">
    <div class="form__title">Добавить замещающего</div>
    <tui-combo-box
      *tuiLet="users$ | async as users"
      tuiTextfieldExampleText="Поиск по ФИО"
      formControlName="substitutive"
      tuiTextfieldSize="m"
      [stringify]="stringifyUser"
      [tuiTextfieldCleaner]="true"
      [identityMatcher]="autocompleteMatcherById"
      (searchChange)="onUserSearch($event)"
    >
      Замещающий*

      <tui-data-list-wrapper
        *tuiDataList
        [items]="users"
        [itemContent]="userListItem"
      ></tui-data-list-wrapper>

      <ng-template #userListItem let-user>
        <div class="select-item">
          <div class="select-item__name">{{ user.fullName }}</div>
          <div class="select-item__props">
            <span *ngIf="!!user.principalName">{{ user.principalName }}</span>
          </div>
        </div>
      </ng-template>
    </tui-combo-box>

    <div tuiGroup>
      <div>
        <tui-input-date
          formControlName="dateStart"
          tuiTextfieldSize="m"
          [min]="today"
        >
          Дата начала*
        </tui-input-date>
      </div>
      <div>
        <tui-input-date
          formControlName="dateEnd"
          tuiTextfieldSize="m"
          [min]="newSubstitutionForm.controls.dateStart.value || today"
        >
          Дата окончания
        </tui-input-date>
      </div>
    </div>

    <div class="form__actions">
      <button
        tuiButton
        size="s"
        [disabled]="newSubstitutionForm.pristine || newSubstitutionForm.invalid"
        (click)="onAddSubstitution()"
      >
        Добавить
      </button>
    </div>
  </form>
</div>

<ng-template #emptySubstitutions>Сейчас нет активных замещений</ng-template>
