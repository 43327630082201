import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TuiDay, TuiDestroyService, tuiPure} from '@pik-taiga-ui/cdk';
import {
  TuiNotification,
  TuiNotificationsService,
  TuiTextMaskOptions,
} from '@pik-taiga-ui/core';
import {BehaviorSubject, map, Observable, of, share, startWith, switchMap} from 'rxjs';
import {catchError, finalize, takeUntil, tap} from 'rxjs/operators';
import {EmployeeSignService} from 'src/app/home-api';

import {getJSONFromKonturError} from '../kontur-error-helper';

/** Вероятно - будет избыточным создавать классы для stringify в селекте */
class DocumentType {
  constructor(readonly type: 'passport' | 'otherIdentity', readonly name: string) {}

  toString() {
    return this.name;
  }
}

@Component({
  selector: 'employee-signature-form',
  templateUrl: './form.template.html',
  styleUrls: ['./form.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class EmployeeSignatureFormComponent {
  @Output() private readonly formSended = new EventEmitter();

  readonly today = TuiDay.fromLocalNativeDate(new Date());
  readonly fileUploadControl = new FormControl();

  readonly loading$ = new BehaviorSubject<boolean>(false);

  @tuiPure
  get loadingFiles$(): Observable<readonly File[]> {
    return this.fileUploadRequests$.pipe(
      map(file => (file instanceof File ? [file] : [])),
      startWith([]),
    );
  }

  @tuiPure
  private get fileUploadRequests$(): Observable<File | null> {
    return this.fileUploadControl.valueChanges.pipe(
      tap(file => {
        if (file === null) {
          this.form.patchValue({
            fileLink: null,
            fileType: null,
            fileName: null,
          });
        }
      }),
      switchMap(file =>
        file
          ? this.employeeSignService.uploadFile(file).pipe(
              tap(res => {
                if (res && res.data) {
                  const {fileLink, fileType, fileName} = res.data;

                  this.form.patchValue({
                    fileLink,
                    fileType,
                    fileName,
                  });
                }
              }),
              startWith(file),
            )
          : of(null),
      ),
      share(),
    );
  }

  readonly innTextMask: TuiTextMaskOptions = {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: false,
  };

  readonly issueOrganizationIdTextMask: TuiTextMaskOptions = {
    mask: [/\d/, /\d/, /\d/, '\u2013', /\d/, /\d/, /\d/],
    guide: false,
  };

  readonly ruPassportSeriesTextMask: TuiTextMaskOptions = {
    mask: [/\d/, /\d/, /\d/, /\d/],
    guide: false,
  };

  readonly ruPassportNumberTextMask: TuiTextMaskOptions = {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: false,
  };

  readonly numbersTextMask: TuiTextMaskOptions = {
    mask: rawValue => {
      return rawValue.split('').map(_ => /\d/);
    },
    guide: false,
  };

  readonly alphabetAndNumbersTextMask: TuiTextMaskOptions = {
    mask: rawValue => {
      return rawValue.split('').map(_ => /[а-яА-ЯёЁ\w\d]/);
    },
    guide: false,
  };

  readonly documentTypes: DocumentType[] = [
    new DocumentType('passport', 'Паспорт гражданина РФ'),
    new DocumentType('otherIdentity', 'Иной документ, удостоверяющий личность'),
  ];

  // Паттерны с дефисами содержат ДЕФИСЫ (не минусы, а \u1203 aka &ndash;)
  readonly form = new FormGroup({
    mobilePhone: new FormControl(null, [Validators.required, Validators.minLength(12)]),
    inn: new FormControl(null, [Validators.required, Validators.minLength(12)]),
    series: new FormControl(null, [Validators.required, Validators.pattern(/^\d{4}$/)]),
    number: new FormControl(null, [Validators.required, Validators.pattern(/^\d{6}$/)]),
    issueDate: new FormControl(null, [Validators.required]),
    issueOrganization: new FormControl({value: null, disabled: true}, [
      Validators.required,
    ]),
    issueOrganizationId: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^\d{3}–\d{3}$/),
    ]),
    documentValidTo: new FormControl({value: null, disabled: true}),
    documentType: new FormControl(this.documentTypes[0], [Validators.required]),
    agreement: new FormControl(false, [Validators.requiredTrue]),
    fileLink: new FormControl(null, [Validators.required]),
    fileName: new FormControl(null),
    fileType: new FormControl(null),
  });

  constructor(
    @Inject(EmployeeSignService)
    private readonly employeeSignService: EmployeeSignService,
    @Inject(TuiDestroyService) private readonly destroy$: TuiDestroyService,
    @Inject(TuiNotificationsService)
    private readonly notifications: TuiNotificationsService,
  ) {
    this.form.controls.documentType.valueChanges.subscribe(({type}) => {
      const {issueOrganization, issueOrganizationId, documentValidTo, series, number} =
        this.form.controls;

      if (type === 'passport') {
        issueOrganization.disable();
        documentValidTo.disable();
        issueOrganizationId.enable();

        if (series.value) {
          series.patchValue((<string>series.value).slice(0, 4), {emitEvent: false});
        }

        if (number.value) {
          number.patchValue((<string>number.value).replace(/[^\d]/g, '').slice(0, 6), {
            emitEvent: false,
          });
        }

        series.setValidators([Validators.required, Validators.pattern(/^\d{4}$/)]);
        number.setValidators([Validators.required, Validators.pattern(/^\d{6}$/)]);
      }

      if (type === 'otherIdentity') {
        issueOrganization.enable();
        documentValidTo.enable();
        issueOrganizationId.disable();
        series.clearValidators();
        number.setValidators([
          Validators.required,
          Validators.pattern(/[а-яА-ЯёЁ\w\d]+/),
        ]);
      }

      series.updateValueAndValidity();
      number.updateValueAndValidity();
    });
  }

  identityDocTypeMatcher(doc1: DocumentType, doc2: DocumentType) {
    return doc1.type === doc2.type;
  }

  sendData() {
    this.loading$.next(true);
    this.employeeSignService
      .createRequest({
        mobilePhone: this.form.value.mobilePhone,
        inn: this.form.value.inn,
        series: this.form.value.series,
        number: this.form.value.number,
        issueDate: (<TuiDay>this.form.value.issueDate).toJSON(),
        issueOrganizationId: this.form.value.issueOrganizationId
          ? this.form.value.issueOrganizationId.replace(/[^\d]/g, '')
          : null,
        issueOrganization: this.form.value.issueOrganization,
        documentValidTo: this.form.value.documentValidTo
          ? (<TuiDay>this.form.value.documentValidTo).toJSON()
          : null,
        documentType: this.form.value.documentType.type,
        fileLink: this.form.value.fileLink,
        fileName: this.form.value.fileName,
        fileType: this.form.value.fileType,
      })
      .pipe(
        finalize(() => this.loading$.next(false)),
        switchMap(res => {
          if (res && res.data) {
            this.formSended.next(res);

            return this.notifications.show('Заявка отправлена', {
              status: TuiNotification.Success,
            });
          }
        }),
        catchError((err: unknown) => {
          return this.notifications.show(
            getJSONFromKonturError((err as any).error?.data),
            {
              status: TuiNotification.Error,
              label: 'Не удалось отправить заявку',
            },
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
