<tui-primitive-textfield
  #input
  iconContent="tuiIconSearchLarge"
  iconAlign="left"
  size="s"
  class="custom-list__search"
  [tuiTextfieldLabelOutside]="true"
  [tuiTextfieldCleaner]="true"
  (valueChange)="searchChange.emit($any($event))"
>
  Начните вводить название...
</tui-primitive-textfield>
<hr />

<ng-container [ngSwitch]="type">
  <ng-template ngSwitchCase="checkboxes">
    <tui-data-list #list>
      <tui-opt-group>
        <button tuiOption (click)="selectAll.emit(items)">Выбрать все</button>

        <button tuiOption (click)="selectAll.emit(null)">Снять все</button>
      </tui-opt-group>
      <tui-opt-group tuiMultiSelectGroup>
        <button
          *ngFor="let item of items; trackBy: nameIdentity"
          tuiOption
          [value]="item"
        >
          {{ item.name }}
        </button>
      </tui-opt-group>

      <ng-container *ngIf="scrollable && items?.length">
        <div *ngIf="hasMore; else theend" class="custom-list__preloader">
          <tui-loader [showLoader]="loading" [overlay]="true"></tui-loader>
        </div>

        <ng-template #theend>
          <span class="custom-list__no-more-message">На этом все...</span>
        </ng-template>
      </ng-container>
    </tui-data-list>
  </ng-template>

  <ng-template ngSwitchCase="list">
    <tui-data-list #list>
      <div *ngIf="!items" class="custom-list__preloader-holder">
        <tui-loader style="height: inherit" [overlay]="true"></tui-loader>
      </div>

      <button
        *ngFor="let item of items; trackBy: nameIdentity"
        tuiOption
        [value]="item"
      >
        <ng-container
          *ngIf="itemContent; else simple"
          [ngTemplateOutlet]="itemContent"
          [ngTemplateOutletContext]="{$implicit: item}"
        ></ng-container>

        <ng-template #simple>{{ item?.name }}</ng-template>
      </button>

      <ng-container *ngIf="scrollable && items?.length">
        <div *ngIf="hasMore" class="custom-list__preloader">
          <tui-loader [showLoader]="loading" [overlay]="true"></tui-loader>
        </div>
      </ng-container>
    </tui-data-list>
  </ng-template>
</ng-container>
