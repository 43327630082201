import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {ApiGETListRequestParams, ApiListResponse, MngUnitDto} from '../interfaces';
import {HOME_API_URL} from '../tokens';
import {Observable} from 'rxjs';
import {SERVICE_DESK_API_URL} from '@servicedesk-api/tokens';
import {MngChangeType} from 'src/app/sctructure/interfaces/mngChangeDto';

interface GetUnitsParams extends ApiGETListRequestParams {
  name?: string;
  parentName?: string;
  parentGuid?: string;
  deep?: number;
}

@Injectable({providedIn: 'root'})
export class MngUnitService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    @Inject(SERVICE_DESK_API_URL) private readonly serviceDeskApiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getUnits(_params?: GetUnitsParams) {
    let params = new HttpParams();

    if (_params) {
      Object.entries(_params).forEach(([param, value]) => {
        params = params.append(param, value);
      });
    }

    return this.http.get<ApiListResponse<MngUnitDto[] | null>>(
      `${this.apiUrl}/v1/MngUnit`,
      {params},
    );
  }

  postUnit(payload: {
    name: string;
    isHiden: boolean;
  }): Observable<ApiListResponse<MngUnitDto>> {
    return this.http.post<ApiListResponse<MngUnitDto>>(`${this.apiUrl}/v1/MngUnit`, {
      ...payload,
    });
  }

  deleteUnit(mngUnitGuid: string): Observable<ApiListResponse<MngUnitDto>> {
    return this.http.delete<ApiListResponse<MngUnitDto>>(`${this.apiUrl}/v1/MngUnit`, {
      params: {
        mngUnitGuid,
      },
    });
  }

  postRequest(payload: MngChangeType) {
    return this.http.post<ApiListResponse<any>>(
      `${this.serviceDeskApiUrl}/DynamicRequest`,
      {
        ...payload,
      },
    );
  }
}
