import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TuiLetModule, TuiPreventDefaultModule} from '@pik-taiga-ui/cdk';
import {
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiSvgModule,
  TuiTooltipModule,
} from '@pik-taiga-ui/core';
import {TuiCalendarMonthModule} from '@pik-taiga-ui/kit';

import {EmployeeDocumentsComponent} from './documents.component';

@NgModule({
  imports: [
    CommonModule,
    TuiSvgModule,
    TuiLinkModule,
    TuiLetModule,
    TuiTooltipModule,
    TuiHintModule,
    TuiLoaderModule,
    TuiCalendarMonthModule,
    TuiHostedDropdownModule,
    TuiPreventDefaultModule,
  ],
  exports: [EmployeeDocumentsComponent],
  declarations: [EmployeeDocumentsComponent],
})
export class EmployeeDocumentsModule {}
