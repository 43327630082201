import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TuiLinkModule} from '@pik-taiga-ui/core';
import {TuiAvatarModule, TuiTagModule} from '@pik-taiga-ui/kit';

import {EmployeeGuidToAvatarUrlPipeModule} from '@kit';
import {EmployeeCardComponent} from './employee-card.component';

@NgModule({
  declarations: [EmployeeCardComponent],
    imports: [
        CommonModule,
        TuiLinkModule,
        TuiAvatarModule,
        EmployeeGuidToAvatarUrlPipeModule,
        TuiTagModule,
    ],
  exports: [EmployeeCardComponent],
})
export class EmployeeCardModule {}
