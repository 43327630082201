import {InjectionToken} from '@angular/core';
import {Observable, of} from 'rxjs';

export const BLOCKED_REQUESTS = new InjectionToken<
  Observable<{
    [requestTypeCode: string]: true | undefined;
  }>
>('Список заблокированных для текущего пользователя типов заявок', {
  factory: () => of(null),
});
