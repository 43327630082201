import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {ApiResponse, PaymentSheetInfoDto} from '../interfaces';
import {HOME_API_URL} from '../tokens';

@Injectable({providedIn: 'root'})
export class PaymentSheetService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Запросить расчётный лист за определенный месяц на email текущего пользователя
   *
   * @todo если запросить за несуществующий месяц бэк ответит ошибкой, но письмо отправит с пустой пдфкой
   * @todo если запросить за будущее время, то бэк ответит валидной моделью, на почту упадёт письмо с шаблонной пдфкой, но не заполненной
   */
  createRequest(year: number, month: number) {
    return this.http.post<ApiResponse<PaymentSheetInfoDto[] | null>>(
      `${this.apiUrl}/v1/PaymentSheet`,
      null,
      {
        params: {year, month},
      },
    );
  }
}
