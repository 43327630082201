import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {HomeEmployee} from '@core/interfaces/manager.interface';
import {ResponseWrapper} from 'pik-header';
import {Observable} from 'rxjs';

import {SERVICE_DESK_API_URL} from '../tokens';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    private readonly $http: HttpClient,
  ) {}

  getEmployees(
    fullName?: string,
    limit = 150,
  ): Observable<ResponseWrapper<HomeEmployee[]>> {
    return this.$http.get<ResponseWrapper<HomeEmployee[]>>(
      `${this.apiUrl}/v1/Employee/all`,
      {
        params: {fullName, limit: `${limit}`},
      },
    );
  }

  getAll(
    params?: HttpParams | {[key: string]: string | boolean},
  ): Observable<ResponseWrapper<HomeEmployee[]>> {
    return this.$http.get<ResponseWrapper<HomeEmployee[]>>(
      `${this.apiUrl}/v1/EmployeeInfo`,
      {
        params,
      },
    );
  }

  /**
   * Получить текущего пользователя
   */
  getCurrentUser(id?: number): Observable<ResponseWrapper<HomeEmployee>> {
    return this.$http
      .get<ResponseWrapper<HomeEmployee>>(`${this.apiUrl}/v1/EmployeeInfo/current`, {
        params: {id},
      })
      .pipe();
  }
}
