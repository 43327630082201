import {inject, InjectionToken} from '@angular/core';
import {RequestTypeDto, RequestTypeService} from '@app/libs/servicedesk-api';
import {Observable, of} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';

const EMPTY_TYPES_ARRAY: RequestTypeDto[] = [];

/**
 * Стрим со всеми типами, с которыми есть хотя бы одна заявка у текущего пользователя
 */
export const EXIST_REQUEST_TYPES = new InjectionToken<
  Observable<readonly RequestTypeDto[]>
>('Стрим со всеми типами, с которыми есть хотя бы одна заявка у текущего пользователя', {
  factory: () => {
    return inject(RequestTypeService)
      .getAll()
      .pipe(
        map(res => {
          if (res && res.data) {
            /**
             * Фильтруем от "псевдо-типов" и сортируем массив по индексу, так как на беке сортировка отсутствует напрочь
             * типы приходят в разнобой
             */
            const sortedTypes: readonly RequestTypeDto[] = res.data
              .filter(type => !type.externalLink)
              .sort((a, b) => a.id - b.id);

            return sortedTypes;
          }

          return EMPTY_TYPES_ARRAY;
        }),
        // Обработать ошибку
        catchError(() => of(EMPTY_TYPES_ARRAY)),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );
  },
});

/** Стрим со всеми типами, с которыми есть хотя бы одна заявка */
export const EXIST_ALL_REQUEST_TYPES = new InjectionToken<
  Observable<readonly RequestTypeDto[]>
>('Стрим со всеми типами, с которыми есть хотя бы одна заявка', {
  factory: () => {
    return inject(RequestTypeService)
      .getAllExists(true)
      .pipe(
        map(res => {
          if (res && res.data) {
            /**
             * Сортируем массив по индексу, так как на беке сортировка отсутствует напрочь
             * типы приходят в разнобой
             */
            const sortedStatuses: readonly RequestTypeDto[] = [...res.data].sort(
              (a, b) => a.id - b.id,
            );

            return sortedStatuses;
          }

          return EMPTY_TYPES_ARRAY;
        }),
        // Обработать ошибку
        catchError(() => of(EMPTY_TYPES_ARRAY)),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );
  },
});
