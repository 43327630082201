import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {ApiListResponse} from '@home-api';
import {HOME_API_URL} from '../tokens';

type aboutTable = 'skills' | 'hobbies';

interface AboutItemDto {
  readonly id: number;
  readonly name: string | null;
  readonly created: string;
  readonly modified: string;
  readonly deleted: string | null;
}

@Injectable({providedIn: 'root'})
export class AboutService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getAll(table: aboutTable, params?: {name?: string; limit?: number; offset?: number}) {
    return this.http.get<ApiListResponse<readonly AboutItemDto[]>>(
      `${this.apiUrl}/v1/about/${table}`,
      {
        params: {
          limit: 20,
          ...params,
        },
      },
    );
  }

  /** @todo дописать метод сервиса и интерфейсы */
  create() {
    return this.http.get<any>('');
  }

  /** @todo дописать метод сервиса и интерфейсы */
  getById() {
    return this.http.get<any>('');
  }

  /** @todo дописать метод сервиса и интерфейсы */
  update() {
    return this.http.get<any>('');
  }

  delete(attributeId: number) {
    return this.http.delete<boolean>(`${this.apiUrl}/v1/attribute/${attributeId}`);
  }
}
