import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {
  ApiListResponse,
  ApiResponse,
  EmployeeSignDocuemntDto,
  EmployeeSignDto,
  EmployeeSignHrStatusDto,
  EmployeeSignStatusDto,
  SignIssueDto,
} from '../interfaces';
import {HOME_API_URL} from '../tokens';
import {map, Observable} from 'rxjs';
import {ApiResponseWithEmptyMeta} from '@app/servicedesk-api';

export interface CreateRequestBody {
  mobilePhone: string;
  inn: string;
  series: string;
  number: string;
  issueDate: string;
  issueOrganizationId: string;
  issueOrganization: string;
  documentValidTo: string;
  documentType: string;
  fileLink: string;
  fileName: string;
  fileType: string;
}

@Injectable({providedIn: 'root'})
export class EmployeeSignService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /** Получение состояния заявок на выпуск НЭП текущего пользователя */
  getCurrentStatuses() {
    return this.http.get<ApiListResponse<readonly EmployeeSignStatusDto[]>>(
      `${this.apiUrl}/v2/EmployeeSign/statusSignEmployee`,
    );
  }

  /** Получение записей по выпуску НЭП в соотв. с фильтрами */
  getAllStatuses() {
    return this.http.get<ApiListResponse<readonly EmployeeSignHrStatusDto[]>>(
      `${this.apiUrl}/v2/EmployeeSign/hrStatusSignEmployee`,
    );
  }

  /**
   * Создание заявки на выпуск НЭП
   *
   * Отправка формы === согласие на выпуск НЭП.
   * Учитывайте это при создании формы и давайте пользователям понять,
   * что без их согласия это сделать невозможно (чекбокс)
   */
  createRequest(body: CreateRequestBody) {
    return this.http.post<ApiResponse<EmployeeSignDto>>(
      `${this.apiUrl}/v2/EmployeeSign/newSignEmployee`,
      body,
    );
  }

  /** Отправить данные подписи в шину по IndividualId или EmployeeGuid1C */
  sendToQueue(body: {individualId?: number[]; employeeGuid1C?: string}) {
    return this.http.post<boolean>(`${this.apiUrl}/v2/EmployeeSign/sendToQueue`, body);
  }

  /** Загрузить страницу документа заявки на выпуск НЭП в хранилище */
  uploadFile(file: File) {
    const fd = new FormData();

    fd.append('file', file);

    return this.http.put<ApiResponse<EmployeeSignDocuemntDto>>(
      `${this.apiUrl}/v2/EmployeeSign/uploadFile`,
      fd,
    );
  }

  /**
   * Получение статуса заявки на выпуск НЭП из Контура
   * @param formId Id заявки на выпуск НЭП
   */
  getKonturStatus(formId: number) {
    return this.http.get<ApiResponse<SignIssueDto>>(
      `${this.apiUrl}/v2/EmployeeSign/statusSignFromKontur`,
      {params: {formId}},
    );
  }

  /**
   * Запрос на получение СМС с кодом подтверждения
   *
   * @todo Модель ответа либо старая, либо неверная
   */
  recieveSMSCode() {
    return this.http.get<ApiResponse<any>>(
      `${this.apiUrl}/v2/EmployeeSign/receiveSMSCode`,
    );
  }

  /**
   * Подтвердить операцию полученным кодом из СМС
   *
   * @todo Модель ответа либо старая, либо неверная
   */
  sendSMSCode(smsCode: string) {
    return this.http.get<ApiResponse<any>>(`${this.apiUrl}/v2/EmployeeSign/sendSMSCode`, {
      params: {smsCode},
    });
  }

  /** Изменить номер телефона по которому выпускался НЭП */
  updateMobilePhone(mobilePhone: string) {
    return this.http.put<ApiResponse<boolean>>(
      `${this.apiUrl}/v2/EmployeeSign/mobilePhone`,
      {
        mobilePhone,
      },
    );
  }

  /** Удалить НЭП на статусе processing */
  deleteEmployeeSign(formId: string): Observable<boolean> {
    return this.http
      .delete<ApiResponseWithEmptyMeta<boolean>>(
        `${this.apiUrl}/v2/EmployeeSign/form/${formId}`,
      )
      .pipe(map(({data}) => data));
  }
}
