import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {ApiResponseWithEmptyMeta, StatusDto} from '../interfaces';
import {SERVICE_DESK_API_URL} from '../tokens';

@Injectable({providedIn: 'root'})
export class StatusService {
  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Получение списка всех статусов с возможностью фильтрации
   * по типу заявки
   */
  getAll(typeId?: number) {
    const params = typeId !== undefined ? {typeId} : null;

    return this.http.get<ApiResponseWithEmptyMeta<StatusDto[]>>(
      `${this.apiUrl}/v1/Status`,
      {params},
    );
  }

  /** Получение статуса по ИД */
  getById(id: number) {
    return this.http.get<ApiResponseWithEmptyMeta<readonly StatusDto[]>>(
      `${this.apiUrl}/v1/Status/${id}`,
    );
  }

  /**
   * Получение всех статусов в существующих заявках доступных для пользователя
   *
   * `all = true` позволяет получить статусы во всех существующих заявках
   */
  getAllExists(all?: boolean) {
    const params = all !== undefined ? {all} : null;

    return this.http.get<ApiResponseWithEmptyMeta<readonly StatusDto[]>>(
      `${this.apiUrl}/v1/Status/exists`,
      {params},
    );
  }

  /**
   * Создание статуса.
   *
   * Полностью отстутствует проверка на дубли
   */
  create(body: {name: string; internalName: string}) {
    return this.http.post<ApiResponseWithEmptyMeta<StatusDto>>(
      `${this.apiUrl}/v1/Status`,
      body,
    );
  }

  /**
   * Изменение статуса
   *
   * Обязательно указывать `id` не только в query-параметрах, но и в body запроса
   * иначе будет создана новая запись
   */
  updateById(id: number, body: {name: string; internalName: string}) {
    return this.http.post<ApiResponseWithEmptyMeta<true>>(
      `${this.apiUrl}/v1/Status/${id}`,
      body,
    );
  }

  /**
   * Удаление статуса по ИД
   *
   * @deprecated Существует в swagger, но не существует на бэке
   */
  removeById(id: number) {
    return this.http.delete<ApiResponseWithEmptyMeta<never>>(
      `${this.apiUrl}/v1/Status/${id}`,
    );
  }
}
