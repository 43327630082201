import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, Inject, Optional} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {round} from '@pik-taiga-ui/cdk';
import {
  TuiDialogContext,
  TuiNotification,
  TuiNotificationsService,
} from '@pik-taiga-ui/core';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {BehaviorSubject, catchError, finalize, throwError} from 'rxjs';
import {EmployeeDto} from 'src/app/home-api';
import {UserPhotoService} from 'src/app/photo-api';

@Component({
  selector: 'employee-avatar-editor',
  templateUrl: './avatar-editor.template.html',
  styleUrls: ['./avatar-editor.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeAvatarEditorComponent {
  readonly uploading$ = new BehaviorSubject(false);
  readonly employee = this.context.data;
  croppedImage: SafeUrl = '';
  croppedImageEvent: ImageCroppedEvent;
  activeItemIndex = 0;

  constructor(
    @Inject(UserPhotoService) private readonly userPhotoService: UserPhotoService,
    @Optional()
    @Inject(TuiNotificationsService)
    private readonly notificationsService: TuiNotificationsService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean, EmployeeDto>,
    private readonly sanitizer: DomSanitizer,
  ) {}

  dismiss() {
    this.context.completeWith(false);
  }

  save() {
    this.uploading$.next(true);

    const {imagePosition} = this.croppedImageEvent;

    this.userPhotoService
      .resize({
        individualGuid: this.employee.individualGuid1C,
        x: round(imagePosition.x1),
        y: round(imagePosition.y1),
        width: round(imagePosition.x2 - imagePosition.x1 - 1),
        height: round(imagePosition.y2 - imagePosition.y1 - 1),
      })
      .pipe(
        finalize(() => this.uploading$.next(false)),
        catchError((error: unknown) => {
          const httpError: HttpErrorResponse = <HttpErrorResponse>error;
          const errorMessage = httpError.error.errors
            ? Object.values(httpError.error.errors).join(', ')
            : 'Произошла непредвиденная ошибка при выполнении запроса';

          if (this.notificationsService) {
            this.notificationsService
              .show(errorMessage, {
                status: TuiNotification.Error,
                label: `Произошла ошибка [${httpError.status} ${httpError.statusText}]`,
                autoClose: 5000,
              })
              .subscribe();
          }

          return throwError(() => new Error(errorMessage));
        }),
      )
      .subscribe(res => {
        if (res && res.data) {
          this.context.completeWith(true);
        }
      });

    return '';
  }

  imageCropped(croppedEvent: ImageCroppedEvent) {
    this.croppedImageEvent = croppedEvent;
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(croppedEvent.base64);
  }
}
