import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {HOME_API_URL} from '@app/home-api';
import {HomeEmployee} from '@core/interfaces/manager.interface';
import {CoreServicesModule} from '@core/services/core-services.module';
import {ResponseWrapper} from 'pik-header';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: CoreServicesModule,
})
export class EmployeeService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly $http: HttpClient,
  ) {}

  getEmployees(
    fullName?: string,
    limit = 150,
  ): Observable<ResponseWrapper<HomeEmployee[]>> {
    return this.$http.get<ResponseWrapper<HomeEmployee[]>>(
      `${this.apiUrl}/v1/Employee/all`,
      {
        params: {fullName, limit: `${limit}`},
      },
    );
  }

  getAll(
    text?: string,
    noDuplicates?: boolean,
    limit = 150,
  ): Observable<ResponseWrapper<HomeEmployee[]>> {
    return this.$http.get<ResponseWrapper<HomeEmployee[]>>(
      `${this.apiUrl}/v1/EmployeeInfo`,
      {
        params: {text, noDuplicates, limit: `${limit}`},
      },
    );
  }

  /**
   * Получить текущего пользователя
   */
  getCurrentUser(): Observable<ResponseWrapper<HomeEmployee>> {
    return this.$http
      .get<ResponseWrapper<HomeEmployee>>(`${this.apiUrl}/v1/employee/current`);
  }
}
