import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';

import {
  ApiListRequestQueryParams,
  ApiListResponse,
  ApiResponseWithEmptyMeta,
  FullAccessRequestDto,
  FullRequestDto,
  HttpClientParams,
  RequestDto,
} from '../interfaces';
import {REQUESTS_SOURCE, SERVICE_DESK_API_URL} from '../tokens';
import {ApiResponse} from '@app/home-api';
import {DynamicRequest} from '@app/dynamic-request/interfaces/dynamic-request.interface';
import {Observable, map} from 'rxjs';
import {DynamicRequestCreateObject} from '../interfaces/dynamic-request/dynamic-request-create-object';

export type GetAllRequestsFilterParams = ApiListRequestQueryParams<{
  typeId?: string;
  statusId?: string;
  excludedStatuses?: string;
  text?: string;
  requestId?: number;
  modifiedFrom?: string;
  createdFrom?: string;
  source?: string[];
  all?: boolean;
}>;

type GetAllRequestsQueryParams = HttpClientParams<GetAllRequestsFilterParams>;

@Injectable({providedIn: 'root'})
export class RequestService {
  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    @Optional() @Inject(REQUESTS_SOURCE) private readonly sources: string[],
    private readonly http: HttpClient,
  ) {}

  /**
   * Получение списка заявок
   *
   * Для ускорения процесса построения списка в `meta` не высчитывется `totalCount` и всегда равен `0`
   */
  getAll(params?: GetAllRequestsQueryParams) {
    let _params: GetAllRequestsQueryParams = null;

    /**
     * @todo Бэк не распознаёт массивы в query-параметрах, но парсит их из строки через запятую
     * приходится склеивать параметр в одну строку для валидного ответа
     */
    if (params) {
      if (
        params.statusId &&
        Array.isArray(params.statusId) &&
        params.statusId.length > 1
      ) {
        params.statusId = params.statusId.join(',');
      }

      if (params.typeId && Array.isArray(params.typeId) && params.typeId.length > 1) {
        params.typeId = params.typeId.join(',');
      }

      _params = params;
    }

    if (this.sources) {
      if (!_params) {
        _params = {};
      }

      _params.source = this.sources;
    }

    return this.http.get<ApiListResponse<RequestDto>>(`${this.apiUrl}/v1/Request`, {
      params: _params,
    });
  }

  /** Получение заявки по ИД Заявки из общей таблицы */
  getById(id: number) {
    return this.http.get<ApiResponseWithEmptyMeta<RequestDto>>(
      `${this.apiUrl}/v1/Request/${id}`,
    );
  }

  /** Получение полной заявки по Id из общей таблицы */
  getFullById(id: number) {
    return this.http.get<ApiResponseWithEmptyMeta<FullRequestDto | FullAccessRequestDto>>(
      `${this.apiUrl}/v1/Request/full/${id}`,
    );
  }

  /**
   * Удаление заявки по ИД Заявки из общей таблицы
   *
   * После удаления заявку можно будет по-прежнему получить по `id`
   */
  removeById(id: number) {
    return this.http.delete<ApiResponseWithEmptyMeta<boolean>>(
      `${this.apiUrl}/v1/Request/full/${id}`,
    );
  }

  /**
   * Получение offset для отображения Заявки
   *
   * @deprecated этод не оправдан и не имеет нормального
   * практического применения
   */
  getOffsetById(id: number) {
    return this.http.get<ApiResponseWithEmptyMeta<number>>(
      `${this.apiUrl}/v1/Request/offset/${id}`,
    );
  }

  /**
   * Получение всех вложений к заявке
   */
  getAttachmentsById(id: number) {
    return this.http.get<ApiResponseWithEmptyMeta<any[]>>(
      `${this.apiUrl}/v1/Request/${id}/attachments`,
    );
  }

  /**
   * Отменяет заявку по id
   *
   * Проставляется статус `Withdraw (id: 11)` и запускается процесс отмены
   *
   * @todo похоже бэк не проверяет текущий статус заявки
   */
  cancelById(id: number) {
    return this.http.put<ApiResponseWithEmptyMeta<FullRequestDto>>(
      `${this.apiUrl}/v1/Request/cancel/${id}`,
      null,
    );
  }

  /**
   * Создать динамическую заявку
   */
  createRequestV2(data: DynamicRequestCreateObject): Observable<DynamicRequest> {
    return this.http
      .post<ApiResponse<DynamicRequest>>(`${this.apiUrl}/v2/DynamicRequest`, data)
      .pipe(map(({data}) => data));
  }

  // POST
  // /api/v1/Request/{id}/attachment
  // Добавление вложения в заявку
}
