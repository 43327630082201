import {KnownRequestStatusCode} from '@app/libs/servicedesk-api';

import {StatusColor} from '../interfaces';

export const STATUS_COLOR: {
  [statusCode in KnownRequestStatusCode]: StatusColor | 'processed';
} = {
  New: 'info',
  InHRProcessing: 'info',
  Processing: 'warning',
  OnApproval: 'warning',
  OnManagerApproval: 'warning',
  ApprovedByManager: 'warning',
  HRProcessing: 'warning',
  OnMotivationApproval: 'warning',
  OnHRDepartmentApproval: 'warning',
  OnWithdrawRequestSigning: 'warning',
  WithdrawInProcess: 'warning',

  Done: 'success',
  Approved: 'success',
  // Open: 'success',

  Rejected: 'error',
  RejectedByManager: 'error',
  Withdraw: 'error',
  Expired: 'error',

  PartiallyApproved: 'neutral',
  OrderDone: 'neutral',
  Delegated: 'processed',
  RequestToWithdrawSigned: 'neutral',
  Created: 'neutral',
  OnCnBApproval: 'neutral',
  RejectedByEmployee: 'neutral',
  RejectedByCnB: 'neutral',
  approvalNotRequired: 'neutral',

  // Есть только на тесте
  /** @deprecated */
  RequestSigned: 'neutral',
  /** @deprecated */
  PaymentDone: 'neutral',
  /** @deprecated */
  OrderSigned: 'neutral',
  /** @deprecated */
  RequestForDocumentsSigned: 'neutral',

  // Есть только на проде
  Review: 'neutral',
  OrderInProgress: 'neutral',
  WaitFiring: 'neutral',
  EmployeeSigning: 'neutral',
};
