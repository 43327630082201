import type {Provider} from '@angular/core';
import {map, shareReplay} from 'rxjs';

import {SD_BLOCKED_INSTANCES} from '../tokens';
import {BlockedInstanceService} from '@app/libs/servicedesk-api';

export function blockedInstacesFactory(service: BlockedInstanceService) {
  return service.getAllCurrent().pipe(
    map(res => {
      if (res && res.data) {
        const blockedEntries = {};

        res.data.blockedInstances.forEach(({blockedInstanceTypeCode}) => {
          blockedEntries[blockedInstanceTypeCode] = true;
        });

        return blockedEntries as {[sdRequestCode: string]: true};
      }
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
}

export const SD_BLOCKED_INSTANCES_PROVIDER: Provider = {
  provide: SD_BLOCKED_INSTANCES,
  useFactory: blockedInstacesFactory,
  deps: [BlockedInstanceService],
};
