<div class="request_body">
  <div class="instruction">
    <tui-notification status="warning">
      Перед загрузкой новой фотографии ознакомьтесь&nbsp;с
      <a
        href="https://info.pik.ru/wiki/spaces/ForNewEmployees/pages/24734"
        target="_blank"
      >
        инструкцией
      </a>
    </tui-notification>
  </div>

  <tui-loader [overlay]="true" [showLoader]="uploading$ | async">
    <div class="meta">
      <ng-container *ngIf="imageChangedEvent">
        <image-cropper
          format="jpeg"
          class="cropper"
          [imageFile]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          [cropperMinWidth]="200"
          [cropperMinHeight]="200"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>

        <div *tuiLet="currentEmployee$ | async as employee" class="previews">
          <div class="preview preview--xl">
            <tui-avatar
              size="xl"
              [rounded]="true"
              [avatarUrl]="croppedImage"
              [autoColor]="true"
              [text]="employee.name"
            ></tui-avatar>
            <div class="preview__text">
              <div class="preview__title">{{ employee.name }}</div>
              <div *ngIf="employee.position" class="preview__prop">
                {{ employee.position }}
              </div>
              <div *ngIf="employee.company" class="preview__prop">
                {{ employee.company }}
              </div>
              <div *ngIf="employee.unitName" class="preview__prop">
                {{ employee.unitName }}
              </div>
            </div>
          </div>
          <div class="preview preview--m">
            <tui-avatar
              size="m"
              [rounded]="true"
              [avatarUrl]="croppedImage"
              [autoColor]="true"
              [text]="employee.name"
            ></tui-avatar>
            <div class="preview__text">
              <div class="preview__title">{{ employee.name }}</div>
              <div *ngIf="employee.position" class="preview__prop">
                {{ employee.position }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="errorMessage$ | async as errorMessage">
        <tui-notification status="error" [hasIcon]="false">
          {{ errorMessage }}
        </tui-notification>
      </ng-container>

      <ng-container *ngIf="!imageChangedEvent">
        <div class="field">
          <label tuiLabel>
            Файл
            <tui-input-files
              size="l"
              accept=".JPG, .jpeg"
              link="Выберите изображение"
              label="или перетащите его сюда"
              class="container"
              [formControl]="form.controls.file"
              (ngModelChange)="onFileUpload($event, form)"
            ></tui-input-files>
          </label>
          <div class="description">
            Вы&nbsp;можете загрузить изображение в&nbsp;формате JPG, размер
            которого не&nbsp;должен превышать 30&nbsp;MB
          </div>
        </div>
      </ng-container>

      <div class="field">
        <label tuiLabel>
          Согласующий
          <tui-combo-box
            *tuiLet="managers$ | async as items"
            tuiTextfieldSize="l"
            [tuiTextfieldCleaner]="true"
            [tuiTextfieldLabelOutside]="true"
            [stringify]="stringifyByFullName"
            [strictMatcher]="autocompleteMatcherById"
            [formControl]="form.controls.manager"
            (searchChange)="onSearchValueChange($event, searchManagers$)"
          >
            <tui-data-list-wrapper
              *tuiDataList
              [items]="items"
              [itemContent]="content"
            ></tui-data-list-wrapper>
          </tui-combo-box>
        </label>
      </div>
    </div>
  </tui-loader>

  <div class="buttons">
    <button tuiButton appearance="flat" size="m" (click)="onClose()">
      Отмена
    </button>
    <button
      tuiButton
      appearance="primary"
      size="m"
      [disabled]="form.invalid"
      (click)="onSubmit()"
    >
      Загрузить
    </button>
  </div>
</div>

<ng-template #content let-data>
  <app-employee-card
    [employee]="data"
    [avatarSize]="'m'"
    [linkable]="false"
    [showProperties]="['unitName', 'companyName']"
  ></app-employee-card>
</ng-template>
