import {Pipe, PipeTransform} from '@angular/core';
import {PikLocatorService} from 'pik-header';

@Pipe({
  name: 'employeeGuidToAvatarUrl',
  pure: true,
})
export class EmployeeGuidToAvatarUrlPipe implements PipeTransform {
  constructor(private readonly locator: PikLocatorService) {}

  transform(guid: string, renditionId = 2): string | null {
    return guid
      ? `${this.locator.urls.photoApi}/api/v1/UserPhoto/${guid}${
          renditionId !== null ? `?renditionId=${renditionId}` : ''
        }`
      : null;
  }
}
