import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '@env/environment';
import {PikAuthService} from 'pik-header';

@Component({
  selector: 'app-pik-user',
  styleUrls: ['./user.component.scss'],
  templateUrl: './user.component.html',
})
export class PikUserComponent implements OnInit {
  @Input() guidSource = 'individualGuid1C';
  @Input() nameField = 'fullName';
  @Input() removable = false;
  @Input() showAvatar = true;
  @Input() showBirthDate = false;
  @Input() clickableName = true;
  @Input() isInterior = false;
  @Input() isBlock = false;

  @Input() readonly user: {
    name: string;
    fullName?: string;
    surName?: string;
    middleName?: string;
    positionName?: string;
    companyName?: string;
    employeeForSiteId?: number;
    birthDate?: string;

    [key: string]: any;
  };

  @Output() remove = new EventEmitter<boolean>();
  userPageLink = '#';
  imageUrl = '';

  constructor(private readonly authService: PikAuthService) {}

  ngOnInit() {
    if (this.user) {
      if (!this.user.fullName) {
        this.user.fullName = `${this.user.surName} ${this.user.name} ${this.user.middleName}`;
      }

      this.userPageLink = this.user.employeeForSiteId
        ? `${environment.employeeUrl}/${this.user.employeeForSiteId}`
        : null;
    }
  }

  onRemove(ev: MouseEvent) {
    ev.preventDefault();
    this.remove.emit(true);
  }
}
