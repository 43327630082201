import {Location} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.template.html',
  styleUrls: ['./not-found.style.scss'],
})
export class NotFoundComponent {
  readonly routeUrl$ = this.location.path();

  constructor(
    @Inject(Title) private readonly title: Title,
    @Inject(Location) private readonly location: Location,
  ) {
    this.title.setTitle('404 — Страница не существует');
  }

  goBack() {
    this.location.back();
  }
}
