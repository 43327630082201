import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiFormatPhonePipeModule,
  TuiHintControllerModule,
  TuiLabelModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@pik-taiga-ui/core';
import {
  TuiFieldErrorModule,
  TuiInputModule,
  TuiInputPhoneModule,
  TuiSelectModule,
  TuiSelectOptionModule,
  TuiTextAreaModule,
} from '@pik-taiga-ui/kit';

import {EmployeeExtraComponent} from './extra.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    TuiFormatPhonePipeModule,
    TuiLetModule,
    TuiSvgModule,
    TuiButtonModule,
    TuiLinkModule,
    TuiLoaderModule,
    TuiInputModule,
    TuiInputPhoneModule,
    TuiSelectModule,
    TuiTextAreaModule,
    TuiLabelModule,
    TuiFieldErrorModule,
    TuiHintControllerModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    TuiSelectOptionModule,
  ],
  exports: [EmployeeExtraComponent],
  declarations: [EmployeeExtraComponent],
  providers: [],
})
export class EmployeeExtraModule {}
