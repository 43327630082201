<ng-container *tuiLet="activeFormId$ | async as activeFormId">
  <div class="alert"></div>
  <div class="header">
    <div class="header__title">Рабочий статус</div>
    <div class="header__actions">
      <button
        *ngIf="activeFormId !== -1"
        tuiButton
        size="xs"
        appearance="flat"
        (click)="showForm()"
      >
        Добавить
      </button>
    </div>
  </div>
  <ng-container *tuiLet="statuses$ | async as statuses">
    <ng-container *ngIf="statuses !== null">
      <div
        *ngIf="statuses.length > 0 || activeFormId !== null"
        class="statuses"
      >
        <ng-container
          *ngIf="activeFormId === -1"
          [ngTemplateOutlet]="statusForm"
          [ngTemplateOutletContext]="{$implicit: formsMap.get(-1), id: -1}"
        ></ng-container>

        <ng-container *ngFor="let status of statuses">
          <ng-container
            *ngIf="activeFormId === status.id"
            [ngTemplateOutlet]="statusForm"
            [ngTemplateOutletContext]="{$implicit: formsMap.get(status.id), id: status.id }"
          ></ng-container>

          <ng-container
            *ngIf="activeFormId !== status.id"
            [ngTemplateOutlet]="statusView"
            [ngTemplateOutletContext]="{$implicit: status}"
          ></ng-container>
        </ng-container>
      </div>

      <div *ngIf="statuses.length === 0" class="no-content">
        <!-- Нужен ли -->
      </div>
    </ng-container>

    <div *ngIf="statuses === null" class="skeleton">
      <div class="skeleton-status">
        <div class="tui-skeleton tui-skeleton_text"></div>
        <div class="tui-skeleton tui-skeleton_text tui-skeleton_short"></div>
        <div class="tui-skeleton tui-skeleton_text"></div>
      </div>
      <div class="skeleton-status">
        <div class="tui-skeleton tui-skeleton_text"></div>
        <div class="tui-skeleton tui-skeleton_text tui-skeleton_short"></div>
        <div class="tui-skeleton tui-skeleton_text"></div>
      </div>
    </div>
  </ng-container>
  <div class="footer"></div>
</ng-container>

<ng-template #statusForm let-formGroup let-id="id">
  <form class="status-form" [formGroup]="formGroup">
    <div class="status-form__title">
      {{
        formGroup.controls.workingStatusCode.disabled
          ? 'Редактирование&nbsp;статуса'
          : 'Новый&nbsp;статус'
      }}
    </div>
    <div class="status-form__content-wrapper">
      <div class="status-form__content form-content">
        <label tuiLabel label="Тип статуса">
          <tui-select
            formControlName="workingStatusCode"
            tuiTextfieldSize="m"
            [tuiTextfieldLabelOutside]="true"
            [valueContent]="stringifyStatusType()"
          >
            <ng-template tuiDataList>
              <tui-data-list>
                <button
                  tuiOption
                  [value]="'SickLeave'"
                  [disabled]="sickLeaveAlreadyExists"
                >
                  Больничный {{ sickLeaveAlreadyExists ? '(уже есть)' : '' }}
                </button>
                <button tuiOption [value]="'RemoteWork'">
                  Удалённая работа
                </button>
              </tui-data-list>
            </ng-template>
          </tui-select>
        </label>

        <div class="form-content__row">
          <label tuiLabel label="Начиная с" class="form-content__row-el">
            <tui-input-date
              formControlName="dateStart"
              tuiTextfieldSize="m"
              [min]="minDate"
              [max]="formGroup.value.dateEnd || maxDate"
              [tuiTextfieldExampleText]="minDate"
              [tuiTextfieldLabelOutside]="true"
              [tuiTextfieldCleaner]="true"
            >
              {{ minDate }}
            </tui-input-date>
          </label>

          <label tuiLabel label="По" class="form-content__row-el">
            <tui-input-date
              formControlName="dateEnd"
              tuiTextfieldSize="m"
              [min]="formGroup.value.dateStart || minDate"
              [max]="maxDate"
              [tuiTextfieldLabelOutside]="true"
              [tuiTextfieldCleaner]="true"
            ></tui-input-date>
          </label>
        </div>

        <label tuiLabel label="Комментарий">
          <tui-text-area
            formControlName="comment"
            tuiTextfieldSize="m"
            [tuiTextfieldLabelOutside]="true"
          >
            Укажите контакты замещающего, либо предпочитаемые способы связи во
            время отсутствия
          </tui-text-area>
        </label>
      </div>

      <div class="status-form__actions">
        <button
          tuiIconButton
          type="button"
          icon="tuiIconCheckCircleLarge"
          size="s"
          appearance="positive"
          (click)="saveForm(id, formGroup.getRawValue(), formGroup.dirty)"
        ></button>
        <button
          tuiIconButton
          type="button"
          icon="tuiIconCloseCircleLarge"
          size="s"
          appearance="negative"
          (click)="closeForm(id)"
        ></button>
        <button
          *ngIf="id !== -1"
          tuiIconButton
          type="button"
          icon="tuiIconTrashLarge"
          size="s"
          appearance="negative"
          (click)="removeStatus(id)"
        ></button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #statusView let-status>
  <div class="status">
    <div class="status__content status-content">
      <div class="status-content__title">{{ status.humanizedTitle }}</div>
      <div class="status-content__dates">{{ status.humanizedInterval }}</div>
      <div *ngIf="status.comment" class="status-content__desc">
        {{ status.comment }}
      </div>
    </div>

    <div class="status__actions">
      <button
        *ngIf="
          status.workingStatus.code === 'SickLeave' ||
          status.workingStatus.code === 'RemoteWork'
        "
        tuiIconButton
        icon="tuiIconSettingsLarge"
        size="s"
        appearance="subaction"
        (click)="showForm(status)"
      ></button>
    </div>
  </div>
</ng-template>
