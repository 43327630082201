import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UserPhotoModule} from '@app/user-photo';

import {PikUserComponent} from './user.component';

@NgModule({
  imports: [CommonModule, UserPhotoModule],
  exports: [PikUserComponent],
  declarations: [PikUserComponent],
})
export class PikUserModule {}
