import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ServerResponse} from '@app/core/interfaces/server-response.interface';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {EmployeeLearningModule} from '../learning.module';

@Injectable({
  providedIn: EmployeeLearningModule,
})
export class LearningWidgetService {
  protected serviceUrl = environment.learnApi + '/api/v1';

  constructor(private readonly http: HttpClient) {}

  getExamPassStatus(): Observable<any> {
    return this.http.get<ServerResponse<any>>(`${this.serviceUrl}/moodle/examPass`);
  }
}
