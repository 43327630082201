import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {TuiButtonModule, TuiSvgModule} from '@pik-taiga-ui/core';

import {EmployeeDevicesComponent} from './devices.component';

@NgModule({
  imports: [CommonModule, TuiLetModule, TuiSvgModule, TuiButtonModule],
  exports: [EmployeeDevicesComponent],
  declarations: [EmployeeDevicesComponent],
  providers: [],
})
export class EmployeeDevicesModule {}
