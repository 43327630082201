import {InjectionToken} from '@angular/core';
import {Observable, of} from 'rxjs';

/**
 * Кадровикам доступны две дополнительные заявки и просмотр всех существующих заявок
 */
export const USER_IS_HR = new InjectionToken<Observable<boolean>>(
  'Стрим значения является ли текущий пользователь работником кадровой службы',
  {
    factory: () => of(false),
  },
);
