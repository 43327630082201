import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {SERVICE_DESK_API_URL} from '@app/libs/servicedesk-api/tokens';

import {ApiResponse} from '../../interfaces';
import {AvailableDaysDto} from '../../interfaces/planned-vacation';

@Injectable({providedIn: 'root'})
export class AvailableDaysService {
  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Получение количества дней в году, свободных для планирования отпусков текущим пользователем
   */

  byYear(year: number) {
    return this.http.get<ApiResponse<AvailableDaysDto>>(
      `${this.apiUrl}/v1/PlannedVacation/availableDays/${year}`,
    );
  }
}
