import {Attribute} from '@angular/compiler';
import {IndividualInfoDto} from '@app/home-api';

export function parseAttributeValue(attribute: IndividualInfoDto) {
  switch (attribute.attributeCode) {
    case 'skills':
    case 'hobbies':
      try {
        const parsedValue = JSON.parse(attribute.value);

        return Array.isArray(parsedValue) && parsedValue.length ? parsedValue : null;
      } catch (e) {
        return attribute.value;
      }

    default:
      return attribute.value;
  }
}
export function stringifyAttributeValue(
  attributeName: string,
  attributeValue: any,
): string {
  switch (attributeName) {
    case 'skills':
    case 'hobbies':
      return attributeValue ? JSON.stringify(attributeValue) : '';

    default:
      return attributeValue ? attributeValue.toString() : '';
  }
}
