import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {
  PikAnalyticsModule,
  PikAuthInterceptor,
  PikAuthModule,
  PikAuthService,
  PikCommonModule,
  PikEnvironment,
} from 'pik-header';
import {environment} from 'src/environments/environment';

import {HOME_API_URL} from '../home-api';
import {PHOTO_API_URL} from '../photo-api';
import {ResolveApiInterceptor} from './interceptors/resolve-api.interceptor';
import {CoreServicesModule} from './services/core-services.module';
import {HOME_URL} from './tokens';
import {SERVICE_DESK_API_URL} from '@app/libs/servicedesk-api';

function getHomeUrl() {
  return environment.pikEnvironment === PikEnvironment.test
    ? 'https://testhome.pik.ru'
    : 'https://home.pik.ru';
}

function getHomeApiUrl() {
  return environment.pikEnvironment === PikEnvironment.test
    ? `${(<any>window)?.env?.api || 'https://testhome-api.pik.ru'}/api`
    : 'https://home-api.pik.ru/api';
}

function getPhotoApiUrl() {
  return environment.pikEnvironment === PikEnvironment.test
    ? 'https://api.test.photo.pik-digital.ru/api'
    : 'https://photo-api.pik.ru/api';
}

function getSDApiUrl() {
  return environment.pikEnvironment === PikEnvironment.test
    ? 'https://testservice-api.pik.ru/api'
    : 'https://service-api.pik.ru/api';
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PikCommonModule.forRoot({
      environment: environment.pikEnvironment,
    }),
    PikAuthModule.forRoot({
      clientId: 'home_lk_spa',
      scope:
        'openid profile email home_api mds_api wiki_api navigation_api servicedesk_api photo_api learning_api',
    }),
    PikAnalyticsModule.forRoot(environment.googleAnalytics),
    CoreServicesModule,
  ],
  providers: [
    PikAuthService,
    {provide: HOME_URL, useFactory: getHomeUrl},
    {provide: HOME_API_URL, useFactory: getHomeApiUrl},
    {provide: PHOTO_API_URL, useFactory: getPhotoApiUrl},
    {provide: SERVICE_DESK_API_URL, useFactory: getSDApiUrl},
    {provide: HTTP_INTERCEPTORS, useClass: ResolveApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: PikAuthInterceptor, multi: true},
  ],
})
export class CoreModule {}
