import {DOCUMENT} from '@angular/common';
import {Provider} from '@angular/core';
import {HOLIDAYS_AND_WEEKENDS} from '@app/tokens';
import {from} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

export const CREATE_REQUESTS_PROVIDERS: Provider[] = [
  {provide: HOLIDAYS_AND_WEEKENDS, useFactory: holydaysFactory, deps: [DOCUMENT]},
];

function holydaysFactory(document: Document) {
  const gapiUrl = 'https://apis.google.com/js/api.js';

  return from(
    new Promise((resolve, reject) => {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = gapiUrl;
      script.async = true;

      script.onerror = function (err) {
        reject(err);
      };

      script.onload = function () {
        resolve(null);
      };

      const otherScript = document.getElementsByTagName('script')[0];

      otherScript.parentElement?.insertBefore(script, otherScript);
    }),
  ).pipe(
    switchMap(_ =>
      from(
        new Promise<void>(resolve => {
          gapi.load('client', () => {
            resolve(
              gapi.client.init({
                apiKey: 'AIzaSyBlCo79Wnk5G4B7QTVWCwK1VL25cvU_s2w',
                discoveryDocs: [
                  'https://sheets.googleapis.com/$discovery/rest?version=v4',
                ],
              }),
            );
          });
        }),
      ),
    ),
    switchMap(_ =>
      gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: '11jf-TDKcbemqL98msnyb5ipSVP6-aK8-Iimyy8ulV4M',
        range: 'Sheet1!A2:M9',
      }),
    ),
    map(({result}) => {
      /**
       * Массив массивов из 13 элементов, [год, ...12 месяцев].
       * Каждый месяц это строка где через запятую даты, которые являются праздничными или выходными
       */

      if (result.values) {
        const lines: string[][] = result.values;

        const yearsToMonthes: {[year: number]: string[]} = {};

        lines.forEach(([year, ...monthes]) => {
          yearsToMonthes[parseInt(year, 10)] = monthes;
        });

        return yearsToMonthes;
      }

      return null;
    }),
  );
}
