import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, throwError} from 'rxjs';
import {shareReplay, switchMap, tap} from 'rxjs/operators';

import {ApiListResponse, EmployeeDto, ShortEmplopyeeDto} from '../interfaces';
import {HOME_API_URL} from '../tokens';

/** @todo дописать оставшиеся методы */
@Injectable({providedIn: 'root'})
export class EmployeeService {
  public current: EmployeeDto;

  readonly current$ = this.getCurrent().pipe(
    tap(user => (this.current = {...user})),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
  );

  readonly currentEmployees$ = this.current$.pipe(
    switchMap(({individualGuid1C, name, id}) => {
      return individualGuid1C
        ? this.getByIndividualGuid(individualGuid1C)
        : throwError(
            () =>
              new Error(`Для сотрудника ${name} с id: ${id} не указан individualGuid1C`),
          );
    }),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
  );

  readonly isPikDigitalEmployee$ = new BehaviorSubject(false);

  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getCurrent() {
    return this.http.get<EmployeeDto>(`${this.apiUrl}/v1/employee/current`).pipe(
      tap(currentUser => {
        const isPikDigitalEmployee = currentUser.companyId === 4770;
        this.isPikDigitalEmployee$.next(isPikDigitalEmployee);
      }),
    );
  }

  /** Все пользователи с фильтром по ФИО */
  getAll(params?: {fullName?: string; limit?: number; offset?: number}) {
    return this.http.get<EmployeeDto>(`${this.apiUrl}/v1/employee/all`, {
      params: {
        limit: 20,
        ...params,
      },
    });
  }

  /** Все пользователи подразделения */
  getAllByUnitId(unitId: number, params?: {limit?: number; offset?: number}) {
    return this.http.get<EmployeeDto[]>(`${this.apiUrl}/v1/employee/all/${unitId}`, {
      params: {
        limit: 20,
        ...params,
      },
    });
  }

  /** Получение сотрудника по ИД */
  getById(id: number) {
    return this.http.get<EmployeeDto>(`${this.apiUrl}/v1/employee/${id}`);
  }

  /** Получение сотрудника по guid1C */
  getByGuid(guid: string) {
    return this.http.get<EmployeeDto>(`${this.apiUrl}/v1/employee/byGuid`, {
      params: {guid},
    });
  }

  /** Получение сотрудника по логину(формат: `main\user_login`) или UPN */
  getByLogin(login: string) {
    return this.http.get<EmployeeDto[]>(`${this.apiUrl}/v1/employee/byLogin`, {
      params: {login},
    });
  }

  /**
   * Получение данных о сотрудниках по списку e-mail
   *
   * `response.meta` - всегда `null`
   * @todo узнать почему meta пустая
   */
  getByEmails(emails: string[]) {
    return this.http.get<ApiListResponse<readonly ShortEmplopyeeDto[]>>(
      `${this.apiUrl}/v1/employee/byEmails`,
      {params: {emails}},
    );
  }

  /**
   * Получение данных о сотрудниках по списку telegram-ников
   *
   * @deprecated Не фурычит (500-ая)
   * @todo Узнать почему не фурычит
   */
  getByTelegrams(telegramLogins: string[]) {
    return this.http.get<never>(`${this.apiUrl}/v1/employee/byTelegrams`, {
      params: {telegrams: telegramLogins},
    });
  }

  /**
   * Получить список сотрудников, привязанных к индивидуалу
   */
  getByIndividualGuid(individualGuid1C: string) {
    return this.http.get<readonly EmployeeDto[]>(
      `${this.apiUrl}/v1/employee/individualEmployees/${individualGuid1C}`,
    );
  }
}

// GET
// /v1/Employee/birthdays
// Дни рождения сотрудиков

// GET
// /v1/Employee/settings
// Настройки дней рождений по логину пользователя. Если не указан - используется логин текущего пользователя.

// PUT
// /v1/Employee/settings
// Сохранение настроек дней рождений пользователя

// GET
// /v1/Employee/vacations
// Настройки отпусков по логину пользователя. Если не указан - используется логин текущего пользователя.
