import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PikAuthGuard} from 'pik-header';

import {AuthCallbackComponent} from './auth-callback';
import {NotFoundComponent} from './not-found/not-found.componen';
import {IsManagerGuard} from './core/guards/is-manager.guard';

const routes: Routes = [
  {
    path: 'auth-callback',
    // TODO: Сделать красивую страницу колбэка на случаю прямого в неё захода.
    // Если авторизован выкидывать на главную, если нет – кидать в авторизацию
    component: AuthCallbackComponent,
  },
  {
    path: '',
    canActivate: [PikAuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          import('./employees/employees.module').then(m => m.EmployeesModule),
      },
      {
        path: 'requests',
        loadChildren: async () =>
          import('./views/requests/requests.module').then(m => m.RequestsModule),
      },
      {
        path: 'staffing',
        canActivate: [PikAuthGuard],
        loadChildren: async () =>
          import('./staffing/staffing.module').then(m => m.StaffingModule),
      },
      {
        path: 'structure',
        canActivate: [IsManagerGuard],
        loadChildren: async () =>
          import('./sctructure/sctructure.module').then(m => m.StructureModule),
      },
      {
        path: 'vacations',
        canActivate: [PikAuthGuard],
        loadChildren: async () =>
          import('./vacations/vacations.module').then(m => m.VacationsModule),
      },
      {
        path: 'searchHobbies',
        canActivate: [PikAuthGuard],
        loadChildren: async () =>
          import('./hobbies-search/hobbies-search.module').then(
            m => m.HobbiesSearchModule,
          ),
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
