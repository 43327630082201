import {ChangeDetectionStrategy, Component, Inject, Input, Optional} from '@angular/core';

import {ApproverGroup} from '../../interfaces';
import {EMPLOYEE_PAGE_URL} from '../../tokens';

@Component({
  selector: 'sd-request-approver-group',
  templateUrl: './request-approver-group.template.html',
  styleUrls: ['./request-approver-group.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicedeskRequestApproverGroupComponent {
  @Input() approverGroup: ApproverGroup;
  @Input() index?: string;

  constructor(@Optional() @Inject(EMPLOYEE_PAGE_URL) readonly employeePageUrl: string) {}
}
