<div *ngFor="let field of fields" class="field">
  <ng-container [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="'link'">
      <a target="_blank" [href]="field.value">
        {{ field.name }}
      </a>
    </ng-container>

    <ng-container *ngSwitchCase="'fileDto'">
      <div *ngIf="jsonField(field.value) as fileDto" class="field__label">
        <a target="_blank" [href]="fileDto">
          {{ field.name }}
        </a>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="field__label">{{ field.name }}</div>
      <div class="field__value">{{ field.value || '–' }}</div>
    </ng-container>
  </ng-container>
</div>
