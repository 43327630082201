<div class="alert"></div>
<div class="header">
  <div class="header__title">Моё оборудование</div>
  <div class="header__actions">
    <a
      tuiButton
      icon="tuiIconExternal"
      size="xs"
      appearance="flat"
      href="https://jira.pik.ru/servicedesk/customer/portal/1/create/23"
      target="_blank"
    >
      Запросить устройство
    </a>
  </div>
</div>
<div *tuiLet="devicesRes$ | async as devicesRes" class="content">
  <ng-container *ngIf="devicesRes">
    <div *ngIf="devicesRes.data && devicesRes.data.length > 0" class="devices">
      <div *ngFor="let device of devicesRes.data" class="devices__entry device">
        <div class="device__avatar">
          <tui-svg src="tuiIconDesktopLarge"></tui-svg>
        </div>
        <div class="device__about">
          <div class="device__name">{{ device.type }}</div>
          <div class="device__desc">{{ device.name }}</div>
        </div>
        <div class="device__actions">
          <a
            tuiIconButton
            icon="tuiIconSettingsLarge"
            appearance="flat"
            size="m"
            href="https://jira.pik.ru/servicedesk/customer/portal/1/create/23"
          ></a>
        </div>
      </div>
    </div>

    <div
      *ngIf="!devicesRes.data || devicesRes.data.length === 0"
      class="no-content"
    >
      У вас нет ни одного устройства
    </div>
  </ng-container>

  <div *ngIf="devicesRes === null" class="skeleton">
    <div class="skeleton-device">
      <div
        class="tui-skeleton skeleton-device__avatar tui-skeleton_rounded"
      ></div>
      <div class="skeleton-device__text">
        <div class="tui-skeleton tui-skeleton_text tui-skeleton_short"></div>
        <div class="tui-skeleton tui-skeleton_text"></div>
      </div>
    </div>
    <div class="skeleton-device">
      <div
        class="tui-skeleton skeleton-device__avatar tui-skeleton_rounded"
      ></div>
      <div class="skeleton-device__text">
        <div class="tui-skeleton tui-skeleton_text tui-skeleton_short"></div>
        <div class="tui-skeleton tui-skeleton_text"></div>
      </div>
    </div>
  </div>
</div>
<div class="footer"></div>
