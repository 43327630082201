import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable, share} from 'rxjs';
import {pluck} from 'rxjs/operators';

import {
  ApiGETListRequestParams,
  ApiListResponse,
  ApiResponse,
  ManagerDto,
  SubstitutionDto,
} from '../interfaces';
import {HOME_API_URL} from '../tokens';

interface CreateSubstituionData {
  substitutiveUsersId: number[];
  dateStart: string;
  dateEnd?: string;
}

@Injectable({providedIn: 'root'})
export class ManagerService {
  private readonly managerData$ = this.getManager().pipe(share());
  readonly hasPermission$: Observable<boolean> = this.getIsMngHasPermission();

  readonly managerGuid$ = this.managerData$.pipe(
    map(res => (res.data === null ? null : res.data.managerIndividualGuid1C)),
  );

  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Получить информацию является ли текущий пользователь менеджером
   * или замещающим
   */
  getManager() {
    return this.http.get<ApiResponse<ManagerDto | null>>(`${this.apiUrl}/v1/manager`);
  }

  /**
   * Получить замещающих текущего пользователя
   */
  getSubstitutions(params?: ApiGETListRequestParams) {
    return this.http.get<ApiListResponse<readonly SubstitutionDto[] | null>>(
      `${this.apiUrl}/v1/manager/substitution`,
      {params: {...params}},
    );
  }

  /**
   * Создать новые замещения. Создаются сразу пачкой для нескольких userId.
   * @return массив созданных замещений
   */
  createSubstitutions(data: CreateSubstituionData) {
    return this.http.post<ApiListResponse<readonly SubstitutionDto[] | null>>(
      `${this.apiUrl}/v1/manager/substitution`,
      data,
    );
  }

  /**
   * Удалить замещение
   * @return в ответе id удалённой записи
   */
  deleteSubstitutions(id: number) {
    return this.http.delete<ApiResponse<number>>(
      `${this.apiUrl}/v1/manager/substitution/${id}`,
    );
  }

  /**
   * Восстановить замещение
   * @return в ответе всегда `null`. Ошибки следует отслеживать по http-коду
   */
  resetSubstitutions(id: number) {
    return this.http.put<ApiResponse<null>>(
      `${this.apiUrl}/v1/manager/substitution/${id}`,
      {},
    );
  }

  /**
   * Проверка, есть ли у текущего пользователя доступ к управляющей структуре
   */
  getIsMngHasPermission() {
    return this.http
      .get<ApiResponse<boolean>>(`${this.apiUrl}/v1/MngUnit/hasPermission`)
      .pipe(pluck('data'));
  }
}
