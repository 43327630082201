import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CustomListModule} from '@app/custom-list';
import {AttributeService, HOME_API_URL, IndividualInfoService} from '@app/home-api';
import {AboutService} from '@app/home-api/services/about.service';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiFormatPhonePipeModule,
  TuiHintControllerModule,
  TuiLabelModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@pik-taiga-ui/core';
import {
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiFieldErrorModule,
  TuiInputModule,
  TuiInputPhoneModule,
  TuiMultiSelectModule,
  TuiSelectModule,
  TuiSelectOptionModule,
  TuiTagModule,
  TuiTextAreaModule,
} from '@pik-taiga-ui/kit';

import {HobbiesComponent} from './hobbies.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiFormatPhonePipeModule,
    TuiLetModule,
    TuiSvgModule,
    TuiButtonModule,
    TuiLinkModule,
    TuiLoaderModule,
    TuiInputModule,
    TuiInputPhoneModule,
    TuiMultiSelectModule,
    TuiTextAreaModule,
    TuiLabelModule,
    TuiFieldErrorModule,
    TuiHintControllerModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    TuiSelectOptionModule,
    TuiDataListWrapperModule,
    CustomListModule,
    TuiTagModule,
  ],
  exports: [HobbiesComponent],
  declarations: [HobbiesComponent],
  providers: [AboutService, AttributeService, IndividualInfoService],
})
export class HobbiesModule {}
