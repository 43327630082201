import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ServicedeskRequestFieldsComponent} from './request-fields.component';

@NgModule({
  imports: [CommonModule],
  exports: [ServicedeskRequestFieldsComponent],
  declarations: [ServicedeskRequestFieldsComponent],
})
export class ServicedeskRequestFieldsModule {}
