<!-- Сначала выводим шаги согласования, которые в "активном" статусе -->
<ng-container *ngIf="steps[0]">
  <div
    *ngIf="steps[0].length === 0"
    class="no-steps-alert"
  >
    Процесс согласования скоро начнётся. Если этого не&nbsp;происходит
    продолжительное время&nbsp;&mdash; отправьте нам запрос на&nbsp;0911@pik.ru
  </div>

  <ng-container *ngFor="let step of steps[0]">
    <sd-request-approver-group
      *ngFor="let group of step.groups; let groupIndex = index"
      class="approver-group"
      [approverGroup]="group"
      [index]="
        step.groups.length > 1
          ? step.stepIndex + '.' + (groupIndex + 1)
          : step.stepIndex
      "
    ></sd-request-approver-group>
  </ng-container>
</ng-container>

<!-- Затем выводим шаги согласования, которые ещё недсотупны -->
<ng-container *ngIf="steps[1]">
  <div
    class="cut-toggler"
    [ngClass]="{'cut-toggler--opened': showAll}"
    (click)="showAll = !showAll"
  >
    <div class="cut-toggler__title">
      {{
        steps[0].length > 0
          ? 'Для перехода заявки на&nbsp;следующие этапы необходимо дождаться согласования предыдущих'
          : 'Процесс согласования ещё не запущен'
      }}
    </div>
    <tui-svg
      src="tuiIconChevronDown"
      class="cut-toggler__icon"
    ></tui-svg>
  </div>

  <tui-expand [expanded]="showAll">
    <ng-template tuiExpandContent>
      <ng-container *ngFor="let step of steps[1]">
        <sd-request-approver-group
          *ngFor="let group of step.groups; let groupIndex = index"
          class="approver-group approver-group--inactive"
          [approverGroup]="group"
          [index]="
            step.groups.length > 1
              ? step.stepIndex + '.' + (groupIndex + 1)
              : step.stepIndex
          "
        ></sd-request-approver-group>
      </ng-container>
    </ng-template>
  </tui-expand>
</ng-container>
