import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {
  ApiListRequestQueryParams,
  ApiListResponse,
  ApiResponseWithEmptyMeta,
  DynamicRequestDto,
  HttpClientParams,
} from '../interfaces';
import {SDFullRequest} from '../interfaces/full-request.model';
import {SERVICE_DESK_API_URL} from '../tokens';

/**
 * @todo Почему-то при запросе динамической заявки по айдюку автор
 * бэк возвращает битую модель с пустым автором
 */
type DynamicRequestDtoMISSED = DynamicRequestDto & {author: null};

@Injectable({providedIn: 'root'})
export class DynamicRequestService {
  lastCreated$: Subject<SDFullRequest> = new Subject();
  lastWithdrawn$: Subject<SDFullRequest> = new Subject();

  constructor(
    @Inject(SERVICE_DESK_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /** Получение динамической заявки по ИД */
  getById(id: number): Observable<ApiResponseWithEmptyMeta<DynamicRequestDtoMISSED>> {
    return this.http.get<ApiResponseWithEmptyMeta<DynamicRequestDtoMISSED>>(
      `${this.apiUrl}/v1/DynamicRequest/${id}`,
    );
  }

  /** Получение списка динамических заявок */
  getAll(
    params?: HttpClientParams<
      ApiListRequestQueryParams<
        Partial<{
          typeId: number;
          /** Поиск по идентификатору заявки/ФИО Author/ФИО Employee */
          text: string;
          /** Наименование типа заявки */
          typeName: string;
          /** Статус заявки */
          statusCode: string;
          /** Наименование поля */
          fieldCode: string;
          /** Значение поля */
          fieldValue: string;
          /** Источник заявки (sd - ServiceDesk, lk - лк руководителя, common - общая для sd и lk) */
          source: string[];
          /**
           * Параметры сортировки.
           * @example "-last_modified,+email"
           */
          sort: string;
          /** Вернуть записи с учётом истории заявки */
          withHistory: boolean;
        }>
      >
    >,
  ): Observable<ApiListResponse<DynamicRequestDto>> {
    return this.http.get<ApiListResponse<DynamicRequestDto>>(
      `${this.apiUrl}/v1/DynamicRequest`,
      {params},
    );
  }

  /** Создание новой динамической заявки */
  create(body: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/DynamicRequest`, body);
  }

  /** Создание новой динамической заявки */
  createV2(body: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/v2/DynamicRequest`, body);
  }

  /** Получение списка динамических заявок, где автор - текущий пользователь */
  getAllForCurrentUser(params?): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/DynamicRequest/author/currentUser`, {params});
  }

  /** Отмена динамической заявки по ИД */
  cancel(body: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/v1/DynamicRequest/cancel/{requestId}`, body);
  }

  /** Удаление динамической заявки по ИД */
  remove(): Observable<any> {
    return this.http.delete(`${this.apiUrl}/v1/DynamicRequest/{requestId}`);
  }
}
