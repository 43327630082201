import {InjectionToken, isDevMode} from '@angular/core';
// 'https://1758.api.test.home.pik-digital.ru'
export const SERVICE_DESK_API_URL = new InjectionToken<string>(
  'URL для REST API приложения ServiceDesk',
  {
    factory: () =>
      isDevMode()
        ? (window['env']['api'] || 'https://364.api.test.sd.pik-digital.ru') + '/api'
        : 'https://service-api.pik.ru/api',
  },
);
