import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {ServicedeskRequestListFilterComponent} from './request-list-filter.component';
import {TuiLetModule, TuiFilterPipeModule} from '@pik-taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiHintModule,
  TuiDataListModule,
  TuiPrimitiveTextfieldModule,
  TuiTextfieldControllerModule,
} from '@pik-taiga-ui/core';
import {
  TuiInputModule,
  TuiSelectModule,
  TuiBadgeModule,
  TuiMultiSelectModule,
  TuiMultiSelectOptionModule,
} from '@pik-taiga-ui/kit';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiInputModule,
    TuiButtonModule,
    TuiHintModule,
    TuiSelectModule,
    TuiLetModule,
    TuiBadgeModule,
    TuiDataListModule,
    TuiMultiSelectModule,
    TuiMultiSelectOptionModule,
    TuiFilterPipeModule,
    TuiPrimitiveTextfieldModule,
    TuiTextfieldControllerModule,
  ],
  exports: [ServicedeskRequestListFilterComponent],
  declarations: [ServicedeskRequestListFilterComponent],
})
export class ServicedeskRequestListFilterModule {}
