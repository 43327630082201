import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiSvgModule,
} from '@pik-taiga-ui/core';
import {TuiRadioLabeledModule, TuiRadioListModule} from '@pik-taiga-ui/kit';

import {ExtraVaccineComponent} from './extra-vaccine.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiButtonModule,
    TuiSvgModule,
    TuiLoaderModule,
    TuiLetModule,
    TuiRadioLabeledModule,
    TuiRadioListModule,
    TuiLinkModule,
  ],
  exports: [ExtraVaccineComponent],
  declarations: [ExtraVaccineComponent],
  providers: [],
})
export class EmployeeExtraVaccineModule {}
