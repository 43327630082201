import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ServerResponse} from '@core/interfaces/server-response.interface';
import {Unit} from '@home-api/interfaces/unit.interface';
import {merge, NEVER, Observable, pluck, Subject} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  scan,
  startWith,
  switchMap,
} from 'rxjs/operators';

import {
  ApiGETListRequestParams,
  ApiListResponse,
  HttpAnyParams,
  HttpParamAction,
  RegUnitDto,
} from '../interfaces';
import {HOME_API_URL} from '../tokens';

interface RegUnitFilterParams {
  name?: string;
  companyGuid1C?: string;
}

type RegUnitFilterAction = HttpParamAction<
  keyof RegUnitFilterParams | keyof ApiGETListRequestParams
>;

type RegUnitGETParams = ApiGETListRequestParams & RegUnitFilterParams & HttpAnyParams;

@Injectable({providedIn: 'root'})
export class RegUnitService {
  private readonly serviceUrl = '{serviceDeskApiUrl}/v1/Unit';

  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  getUnits(rawParams?: RegUnitGETParams) {
    const params = new HttpParams().appendAll(rawParams || {});

    return this.http.get<ApiListResponse<RegUnitDto[] | null>>(
      // '/mocks/home-api/reg-unit.json',
      `${this.apiUrl}/v1/RegUnit`,
      {params},
    );
  }

  getUnitsWithTree(params) {
    return this.http.get<ServerResponse<Unit[]>>(`${this.serviceUrl}/withNamesTree`, {
      params,
    });
  }

  autocomplete(
    searchSubject$: Subject<string>,
    filterSubject$?: Observable<RegUnitFilterAction>,
  ) {
    return merge(
      searchSubject$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map((value): RegUnitFilterAction => ['name', value]),
      ),
      filterSubject$ || NEVER,
    ).pipe(
      scan(
        (params, [param, value]): RegUnitGETParams => ({...params, [param]: value}),
        {},
      ),
      switchMap(params =>
        this.getUnits({...params, limit: 20}).pipe(pluck('data'), startWith(null)),
      ),
      startWith([]),
    );
  }
}
