import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {SDRequestNumberPipeModule} from '../../pipes';
import {ServicedeskRequestApproverGroupListModule} from '../request-approver-group-list';
import {ServicedeskRequestFieldsModule} from '../request-fields';
import {ResourceDetailsSecondComponent} from './request-details/resource-details-second/resource-details-second.component';
import {ServicedeskRequestViewComponent} from './request-view.component';
import {TuiLetModule} from '@pik-taiga-ui/cdk';
import {TuiButtonModule, TuiLinkModule} from '@pik-taiga-ui/core';
import {TuiTagModule} from '@pik-taiga-ui/kit';
import {EmployeeCardModule} from '@app/employee-card/employee-card.module';
import {PikUserModule} from '@app/user';

@NgModule({
  imports: [
    CommonModule,

    EmployeeCardModule,
    PikUserModule,
    SDRequestNumberPipeModule,
    ServicedeskRequestApproverGroupListModule,
    ServicedeskRequestFieldsModule,
    TuiButtonModule,
    TuiLetModule,
    TuiLinkModule,
    TuiTagModule,
  ],
  exports: [ServicedeskRequestViewComponent],
  declarations: [ServicedeskRequestViewComponent, ResourceDetailsSecondComponent],
})
export class ServicedeskRequestViewModule {}
