import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {RequestTypeDto, StatusDto} from '@app/libs/servicedesk-api';

import {Observable, Subject, combineLatest, merge, of} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {STATUS_COLOR} from '../../adapters/status-color';
import {StatusColor} from '../../interfaces';
import {AllRequestModeService} from '../../services';
import {
  EXIST_ALL_REQUEST_STATUSES,
  EXIST_REQUEST_STATUSES,
  USER_IS_ADMIN,
  USER_IS_HR,
} from '../../tokens';
import {EXIST_ALL_REQUEST_TYPES, EXIST_REQUEST_TYPES} from '../../tokens/exist-types';
import {
  FilterKey,
  FilterKeyValue,
  ServicedeskRequestListFilterService,
} from './request-list-filter.service';
import {TuiDestroyService, setNativeMouseFocused} from '@pik-taiga-ui/cdk';
import {TuiDataListComponent, isEditingKey} from '@pik-taiga-ui/core';

type StatusWithColor = StatusDto & {color: StatusColor};

@Component({
  selector: 'sd-request-list-filter',
  templateUrl: './request-list-filter.template.html',
  styleUrls: ['./request-list-filter.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class ServicedeskRequestListFilterComponent {
  private readonly _textFilterFormControl = new FormControl(null);
  private readonly _statusFilterFormControl = new FormControl(null);
  private readonly _requestTypeFilterFormControl = new FormControl(null);
  private readonly _textFilter$ = this._textFilterFormControl.valueChanges.pipe(
    debounceTime(300),
    map((value: string | null) => {
      const pureValue = value && value.length > 0 ? value.trim() : '';

      return pureValue.length > 0 ? pureValue : null;
    }),
    distinctUntilChanged(),
  );

  private readonly allExistStatusesWithColor$: Observable<
    StatusWithColor[]
  > = this.allExistStatuses$.pipe(
    map(statuses =>
      statuses.map(status => ({
        ...status,
        color: STATUS_COLOR[status.internalName] || 'neutral',
      })),
    ),
  );

  private readonly existStatusesWithColor$: Observable<
    StatusWithColor[]
  > = this.existStatuses$.pipe(
    map(statuses =>
      statuses.map(status => ({
        ...status,
        color: STATUS_COLOR[status.internalName] || 'neutral',
      })),
    ),
  );

  readonly valuesPluralizeMap: {[k: string]: string} = {
    one: '#\xa0значение',
    few: '#\xa0значения',
    many: '#\xa0значений',
    other: '#\xa0значения',
  };

  readonly filterForm = new FormGroup({
    text: this._textFilterFormControl,
    status: this._statusFilterFormControl,
    requestType: this._requestTypeFilterFormControl,
  });

  readonly hasAdminRights$ = combineLatest([this.isAdmin$, this.isHr$]).pipe(
    map(([isAdmin, isHr]) => isAdmin || isHr),
    distinctUntilChanged(),
  );

  readonly allRequestModeOn$ = this.hasAdminRights$.pipe(
    switchMap(isAdmin => {
      if (isAdmin) {
        return this.allRequestMode$;
      }

      this.allRequestMode$.next(false);

      return of(false);
    }),
    distinctUntilChanged(),
  );

  readonly adminModeIcon$ = this.allRequestModeOn$.pipe(
    map(enabled => (enabled ? 'tuiIconStarFilledLarge' : 'tuiIconStarLarge')),
  );

  readonly statuses$: Observable<StatusWithColor[]> = this.allRequestModeOn$.pipe(
    switchMap(adminModeOn => {
      return adminModeOn ? this.allExistStatusesWithColor$ : this.existStatusesWithColor$;
    }),
  );

  readonly types$ = this.allRequestModeOn$.pipe(
    switchMap(adminModeOn => {
      return adminModeOn ? this.allExistTypes$ : this.existTypes$;
    }),
  );

  typeSearcher = '';
  statusSearcher = '';

  constructor(
    @Inject(ServicedeskRequestListFilterService)
    private readonly filterService: ServicedeskRequestListFilterService,
    @Inject(TuiDestroyService) private readonly destroy$: Subject<void>,
    @Inject(USER_IS_ADMIN) private readonly isAdmin$: Observable<boolean>,
    @Inject(USER_IS_HR) private readonly isHr$: Observable<boolean>,
    @Inject(EXIST_REQUEST_STATUSES)
    private readonly existStatuses$: Observable<StatusDto[]>,
    @Inject(EXIST_REQUEST_TYPES)
    private readonly existTypes$: Observable<RequestTypeDto[]>,
    @Inject(EXIST_ALL_REQUEST_STATUSES)
    private readonly allExistStatuses$: Observable<StatusDto[]>,
    @Inject(EXIST_ALL_REQUEST_TYPES)
    private readonly allExistTypes$: Observable<RequestTypeDto[]>,
    @Inject(AllRequestModeService)
    private readonly allRequestMode$: AllRequestModeService,
  ) {
    this.initState();
  }

  readonly identityMatcherById = (a: StatusDto, b: StatusDto): boolean => a.id === b.id;

  readonly stringMatcher = (item: StatusDto, search: string): boolean =>
    item.name.toLowerCase().includes(search.toLowerCase());

  onArrowDown<T>(list: TuiDataListComponent<T>, event): void {
    list.onFocus(event.target, true);
  }

  onKeyDown(key: string, element: HTMLElement | null): void {
    if (element && isEditingKey(key)) {
      setNativeMouseFocused(element, true, true);
    }
  }

  initState() {
    merge(
      this.allRequestModeOn$.pipe(
        tap(() => {
          this._statusFilterFormControl.setValue(null, {emitEvent: false});
        }),
        map(allRequestModeOn => {
          return [
            ['all', allRequestModeOn],
            ['statusId', null],
          ];
        }),
      ),
      this._textFilter$.pipe(
        map(searchText => {
          return [['text', searchText]];
        }),
      ),
      this._statusFilterFormControl.valueChanges.pipe(
        map((statuses: StatusDto[]) => {
          return [
            [
              'statusId',
              statuses && statuses.length > 0 ? statuses.map(status => status.id) : null,
            ],
          ];
        }),
      ),
      this._requestTypeFilterFormControl.valueChanges.pipe(
        map((types: RequestTypeDto[]) => {
          return [
            ['typeId', types && types.length > 0 ? types.map(status => status.id) : null],
          ];
        }),
      ),
    )
      .pipe(
        tap((filters: Array<[FilterKey, any]>) => {
          this.filterService.setValue(...filters[0]);
          if (filters.length > 1) {
            this.filterService.setValues(
              filters.reduce((acc, filter) => {
                return {...acc, [filter[0]]: filter[1]};
              }, <FilterKeyValue>{}),
            );
          } else {
            this.filterService.setValue(...filters[0]);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.filterService.clearState();
  }

  toggleAdminMode() {
    this.allRequestMode$.toggle();
  }

  uncheckAll(formControlName) {
    this.filterForm.controls[formControlName].reset();
  }

  checkAll(formControlName, allValues) {
    this.filterForm.controls[formControlName].setValue([...allValues]);
  }
}
