import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ServicedeskRequestApproverGroupComponent} from './request-approver-group.component';
import {TuiLinkModule} from '@pik-taiga-ui/core';
import {TuiTagModule, TuiBadgeModule} from '@pik-taiga-ui/kit';

@NgModule({
  imports: [CommonModule, TuiTagModule, TuiBadgeModule, TuiLinkModule],
  exports: [ServicedeskRequestApproverGroupComponent],
  declarations: [ServicedeskRequestApproverGroupComponent],
})
export class ServicedeskRequestApproverGroupModule {}
