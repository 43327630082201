import {HttpErrorResponse} from '@angular/common/http';
import {TuiNotification, TuiNotificationsService} from '@pik-taiga-ui/core';
import {MonoTypeOperatorFunction, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

export function errorNotification<T>(
  notificationsService: TuiNotificationsService,
): MonoTypeOperatorFunction<T> {
  return catchError((err: unknown) => {
    const httpError = err as HttpErrorResponse;
    const errorMessage = httpError.error.error
      ? (httpError.error.error as string).substring(
          httpError.error.error.indexOf(':') + 2,
          httpError.error.error.indexOf('at'),
        )
      : 'Произошла непредвиденная ошибка при выполнении запроса';

    notificationsService
      .show(errorMessage, {
        status: TuiNotification.Error,
        label: `Произошла ошибка [${httpError.status} ${httpError.statusText}]`,
        autoClose: 5000,
      })
      .subscribe();

    return throwError(() => new Error(errorMessage));
  });
}
