import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {EMPTY} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';
import {UserAssetsService, UserService} from 'src/app/home-api';

@Component({
  selector: 'employee-devices',
  templateUrl: './devices.template.html',
  styleUrls: ['./devices.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeDevicesComponent {
  readonly devicesRes$ = this.userService.currentUser$.pipe(
    switchMap(user => {
      return user && user.id
        ? this.userAssetsService.getByUserId(user.id).pipe(startWith(null))
        : EMPTY;
    }),
  );

  constructor(
    @Inject(UserAssetsService) private readonly userAssetsService: UserAssetsService,
    @Inject(UserService) private readonly userService: UserService,
  ) {}
}
